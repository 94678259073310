import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexFW");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

const zip = require("@zip.js/zip.js/dist/zip-full");

interface IVEXosFile {
  name: string;
  data: Uint8Array;
}

enum VEXosType {
  PUBLIC = 0,
  BETA = 1,
  LOCAL = 2,
  PRIVATE = 3,
  CUSTOM = 4    // may not even be vexos
}

interface IVEXosVersions {
  type: VEXosType;
  name: string;
  major: number;
  minor: number;
  build: number;
  beta: number;
}

interface IPathData {
  public?: string;
  beta?: string;
  private?: string;
  local?: string;
}

abstract class VexFW {
  static DONGLE: any = undefined;

  // TODO: use correct path for each platfrom
  protected abstract _urlPublic: string;
  protected abstract _urlBeta: string;
  protected abstract _urlPrivate: string;
  protected _urlLocal = 'resources/vexos/';
  protected _catalog = 'catalog.txt';

  protected _versions: IVEXosVersions[] = [];
  protected _vexos: IVEXosFile[] = [];
  protected _vexosblob: Blob;
  protected _os_version: IVEXosVersions = null;
  protected _os_loaded: boolean = false;
  protected _url_custom: string = undefined;

  constructor() {
    this._os_loaded = false;
  }

  get catalog(): string {
    return this._catalog;
  }

  set catalog(value: string) {
    this._catalog = value;
  }

  setPath(data: IPathData) {
    if (data?.public) {
      this._urlPublic = data.public;
    }
    if (data?.beta) {
      this._urlBeta = data.beta;
    }
    if (data?.private) {
      this._urlPrivate = data.private;
    }
    if (data?.local) {
      this._urlLocal = data.local;
    }

  }

  setDongle(value: string) {
    VexFW.DONGLE = value;
  }

  isLoaded(): boolean {
    return this._os_loaded;
  }

  initVersions() {
    this._os_loaded = false;
    this._versions = [];
  }

  setCustomUrl(url: string) {
    this._url_custom = url;
  }

  // manually add a version, perhaps from external cache
  addVersion(v: IVEXosVersions) {
    if (v !== undefined)
      this._versions.push(v);
  }

  checkVersion(version: number[], type?: VEXosType): boolean {
    // version is of the form [maj, min. bld, beta ];

    type = (type === undefined) ? VEXosType.PUBLIC : type;

    // return match if we don't have any info
    if (version === undefined || version.length !== 4) {
      return (true);
    }

    let os_version = undefined;

    // pick last version of indicated type
    for (let i = 0; i < this._versions.length; i++) {
      if (this._versions[i].type === type) {
        os_version = this._versions[i];
      }
    }

    if (os_version !== undefined) {
      // see if versions match
      if (os_version.major > version[0])
        return (false);
      if (os_version.major < version[0])
        return (true);

      // major must match
      if (os_version.minor > version[1])
        return (false);
      if (os_version.minor < version[1])
        return (true);

      // minor must match
      if (os_version.build > version[2])
        return (false);
      if (os_version.build < version[2])
        return (true);

      // so major, minor and build all match
      // now deal with beta

      // beta 0 is a release version
      // we ignore upgrade from beta 0 to say beta 1
      if (os_version.beta > version[3] && (version[3] != 0)) // ignore beta if currently 0
        return (false);
      // this upgraded from say beta 22 to beta 0
      if (os_version.beta != version[3] && (os_version.beta === 0)) // always use if beta is set to 0
        return (false);
    }

    // they either match or we don't have anything to compare
    return (true);
  }

  getLatest(type?: VEXosType): IVEXosVersions {
    type = (type === undefined) ? VEXosType.PUBLIC : type;

    let os_version = undefined;

    // pick last version of indicated type
    for (let i = 0; i < this._versions.length; i++) {
      if (this._versions[i].type === type) {
        os_version = this._versions[i];
      }
    }

    return (os_version);
  }

  /**
   * Look for file with given name in the unzipped file object
   * @param name 
   */
  getFile(name: string): IVEXosFile {
    for (let i = 0; i < this._vexos.length; i++) {
      if (this._vexos[i].name.toLowerCase() === name.toLowerCase()) {
        return this._vexos[i];
      }
    }

    return undefined;
  }

  getBlob() {
    return this._vexosblob;
  }

  /**
   * Get a text file from the VEX server (tends to be catalog files)
   */
  private getTextFileFromServer(
    url: string,
    user?: string,
    pass?: string,
    progress?: (percentComplete: number) => void
  ): Promise<string> {
    return this.getFileFromServer(url, "text", user, pass, progress) as Promise<string>;
  }

  /**
   * Get a Blob file from the VEX server (tends to be vexos files)
   */
   private getBlobFileFromServer(
    url: string,
    user?: string,
    pass?: string,
    progress?: (percentComplete: number) => void
  ): Promise<Blob> {
    return this.getFileFromServer(url, "blob", user, pass, progress) as Promise<Blob>;
  }

  /**
   * Get a file from the VEX server
   * type should be 'text' for catalog files and 'blob' for vexos files
   */
  private getFileFromServer(
    url: string,
    responseType: "blob" | "text",
    user?: string,
    pass?: string,
    progress?: (percentComplete: number) => void
  ): Promise<Blob | string> {
    return new Promise<Blob | string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      log.debug("request", responseType, "from", url);
      xhr.open("GET", url, true);
      // xhr.setRequestHeader('Cache-Control', 'no-cache,max-age=0');
      xhr.responseType = responseType;

      // yea, this is bad, but for now we hard code some stuff
      if (user !== undefined && pass !== undefined) {
        xhr.withCredentials = true;
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa(user + ':' + pass));
      } else if (user !== undefined) {
        xhr.withCredentials = true;
        xhr.setRequestHeader('Authorization', 'Basic ' + user);
      }

      xhr.onload = function () {
        if (xhr.status == 200) {
          resolve(xhr.response);
        }
        if (xhr.status == 401) {
          // auth error
          resolve(undefined);
        } else {
          reject(Error("failed to get file from server; error code: " + xhr.statusText));
        }
      };

      xhr.onerror = function () {
        reject(Error('There was a network error.'));
      };

      if (progress !== undefined) {
        // progress on transfers from the server to the client (downloads)
        xhr.onprogress = function (oEvent) {
          if (oEvent.lengthComputable) {
            var percentComplete = oEvent.loaded / oEvent.total;
            if (progress != undefined) {
              progress(percentComplete);
            }
          } else {
            // Unable to compute progress information since the total size is unknown
          }
        }
      }

      xhr.send();
    });
  }

  private blobToArrayBuffer(b: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      let r = new FileReader;

      r.readAsArrayBuffer(b);
      // Load the file data
      r.onload = (event) => {
        // check file read succesful
        if (r.readyState === r.DONE) {
          resolve(<ArrayBuffer>r.result);
        }
        else
          reject(undefined);
      }
      r.onerror = (err) => {
        reject(undefined);
      }
    })
  }

  downloadFirmware(
    name?: string,
    fwChannel?: VEXosType,
    progressCallback?: (percentComplete: number) => void,
  ) {
    const fwType = fwChannel === undefined ? VEXosType.PUBLIC : fwChannel;

    return new Promise((resolve, reject) => {
      // Process vexos file
      var processVexosFile = (blob: Blob) => {
        return new Promise((resolve, reject) => {
          if (blob !== undefined) {
            log.info('vexos: process file ' + this._os_version.name);
            this.openVexosFile(blob)
              .then((num) => {
                log.info('vexos: found ' + num + ' files');
                resolve(num);
              })
              .catch((num) => {
                log.error('vexos: read error');
                reject(0);
              })
          }
        });
      }

      for (let i = 0; i < this._versions.length; i++) {
        if (name !== undefined) {
          if (this._versions[i].name === name && fwType === this._versions[i].type) {
            this._os_version = this._versions[i];
            break;
          }
        }
      }

      let remote_file: string;
      let pass: string = undefined;
      // strip any existing extension
      const basename = this._os_version.name.replace('.vexos', '');
      if (fwType == VEXosType.PUBLIC)
        remote_file = this._urlPublic + basename + '.vexos';
      if (fwType == VEXosType.BETA)
        remote_file = this._urlBeta + basename + '.vexos';
      if (fwType == VEXosType.PRIVATE) {
        remote_file = this._urlPrivate + basename + '.vexos';
        pass = VexFW.DONGLE;
      }
      if (fwType == VEXosType.LOCAL)
        remote_file = this._urlLocal + basename + '.vexos';
      if (fwType == VEXosType.CUSTOM)
        remote_file = this._url_custom + basename;

      this._vexosblob = undefined;

      // TODO: add caching?
      this.getBlobFileFromServer(remote_file, pass, undefined, progressCallback)
        .then((blob) => {
          this._vexosblob = blob;
          return processVexosFile(blob);
        })
        .then((num) => {
          if (num == 0)
            this._vexosblob = undefined;
          resolve(num);
        })
        .catch((reason) => {
          reject(0);
        });
    });
  }

  // TODO: may need to add this back to get caching working
  // openLocalFile(file) {
  //   return new Promise((resolve, reject) => {
  //     if (file !== undefined) {
  //       log.info('vexos: process file ' + file.name);
  //       this.openVexosFile(file)
  //         .then((num) => {
  //           log.info('vexos: found ' + num + ' files');
  //           resolve(num);
  //         })
  //         .catch((num) => {
  //           log.error('vexos: read error');
  //           reject(0);
  //         })
  //     }
  //   });
  // }

  checkAndUpdateFirmware(name?: string, type?: VEXosType) {
    type = (type === undefined) ? VEXosType.PUBLIC : type;

    return new Promise((resolve, reject) => {
      // Process vexos file
      var processVexosFile = (blob: Blob) => {
        return new Promise((resolve, reject) => {
          if (blob !== undefined) {
            log.info('vexos: process file ' + this._os_version.name);
            this.openVexosFile(blob)
              .then((num) => {
                log.info('vexos: found ' + num + ' files');
                resolve(num);
              })
              .catch((num) => {
                log.error('vexos: read error');
                reject(0);
              })
          }
        });
      }

      // Get catalog from serrver and load latest Public vexos
      this.getVexosVersions()
        .then((status) => {
          if (status === null || status === undefined) {
            throw 0;
          }
          // status should only be true
          for (let i = 0; i < this._versions.length; i++) {
            if (name !== undefined) {
              if (this._versions[i].name === name) {
                this._os_version = this._versions[i];
                break;
              }
            }
            else
              // Just pick First public (should be only) version
              if (this._versions[i].type === VEXosType.PUBLIC) {
                this._os_version = this._versions[i];
                break;
              }
          }

          let remote_file: string;
          let pass: string = undefined;
          if (type == VEXosType.PUBLIC)
            remote_file = this._urlPublic + this._os_version.name + '.vexos';
          if (type == VEXosType.BETA)
            remote_file = this._urlBeta + this._os_version.name + '.vexos';
          if (type == VEXosType.PRIVATE) {
            remote_file = this._urlPrivate + this._os_version.name + '.vexos';
            pass = VexFW.DONGLE;
          }
          if (type == VEXosType.LOCAL)
            remote_file = this._urlLocal + this._os_version.name + '.vexos';
          if (type == VEXosType.CUSTOM)
            remote_file = this._url_custom + this._os_version.name + '.zip';

          return this.getBlobFileFromServer(remote_file, pass)
        })
        .then((blob: Blob) => {
          return processVexosFile(blob);
        })
        .then((num) => {
          resolve(0);
        })
        .catch((reason) => {
          reject(0);
        });
    });
  }

  /**
   * Get all known versions from the server
   * Public and beta
   */
  getVexosVersions(bLocalCatalog?: boolean, bPrivateCatalog?: boolean) {
    return new Promise((resolve, reject) => {

      //var version = null;
      this.initVersions();

      var addVersions = (retval: string, type: VEXosType) => {
        if (retval == null || retval == undefined || retval.length === undefined || retval.length === 0)
          return;

        let str = retval.split('\n');
        for (let i = 0; i < str.length; i++) {
          let parts = str[i].split('_');
          let len = parts.length;

          if (len > 1) {
            let v: IVEXosVersions = {
              type: type,
              name: str[i].replace(/[\n\r]/g, ''),
              major: (len > 4 && parts[len - 4] !== undefined) ? parseInt(parts[len - 4]) : 0,
              minor: (len > 4 && parts[len - 3] !== undefined) ? parseInt(parts[len - 3]) : 0,
              build: (len > 4 && parts[len - 2] !== undefined) ? parseInt(parts[len - 2]) : 0,
              beta: (len > 4 && parts[len - 1] !== undefined) ? parseInt(parts[len - 1]) : 0
            }
            this._versions.push(v);
          }
        }
      }

      // In this initial version we are going to just pick everything
      // for simplicity.  Later we need to add some authentication dialogs.
      //
      log.debug('vexos: get public version');
      this.getTextFileFromServer(this._urlPublic + this._catalog)
        .then((retval: string) => {
          addVersions(retval, VEXosType.PUBLIC);
        })
        .catch(() => {
          log.error('vexos: error(1)');
        })
        .then(() => {
          log.debug('vexos: get beta version');
          return this.getTextFileFromServer(this._urlBeta + this._catalog)
        })
        .then((retval: string) => {
          addVersions(retval, VEXosType.BETA);
        })
        .catch(() => {
          log.error('vexos: error(2)');
        })
        .then(() => {
          if (bPrivateCatalog !== undefined && bPrivateCatalog === true) {
            log.debug('vexos: get private beta version');
            return this.getTextFileFromServer(this._urlPrivate + this._catalog, VexFW.DONGLE)
          }
        })
        .then((retval: string) => {
          if (bPrivateCatalog !== undefined && bPrivateCatalog === true) {
            addVersions(retval, VEXosType.PRIVATE);
          }
        })
        .catch(() => {
          log.error('vexos: error(3)');
        })
        .then(() => {
          if (this._url_custom !== undefined) {
            log.debug('vexos: get custom version');
            return this.getTextFileFromServer(this._url_custom + this._catalog)
          }
        })
        .then((retval) => {
          if (this._url_custom !== undefined) {
            addVersions(retval, VEXosType.CUSTOM);
          }
        })
        .catch(() => {
          log.error('vexos: error(4)');
        })
        .then(() => {
          if (bLocalCatalog !== undefined && bLocalCatalog === true) {
            log.debug('vexos: get local version');
            return this.getTextFileFromServer(this._urlLocal + this._catalog)
          }
        })
        .then((retval) => {
          if (bLocalCatalog !== undefined && bLocalCatalog === true) {
            addVersions(retval, VEXosType.LOCAL);
          }
        })
        .catch(() => {
          log.error('vexos: error(5)');
        })
        .then(() => {
          if (this._versions === undefined || this._versions.length === 0)
            reject(0)
          else {
            this._os_loaded = true;
            resolve(true);
          }
        })
        .catch((reason) => {
          log.error('vexos: error(99)');
          log.error('vexos: ' + reason);
          reject(reason)
        });
    })
  }

  /**
   * parse out the contents of a vexos zip file so that we can acces the bin files inside
   * @param file the blob of the vexos zip file to process
   * @returns the number of FW files found in the vexos file
   */
  async openVexosFile(file: Blob): Promise<number> {
    try {
      const zipReader = new zip.ZipReader(new zip.BlobReader(file));
      log.debug("reading zip")
      const entries = await zipReader.getEntries();
      log.debug("done reading zip")

      if (entries.length) {
        log.debug("found", entries.length, "entries in the zip file");

        for (const entry of entries) {
          const nameParts = entry.filename.split('/');
          const name = nameParts[nameParts.length - 1];
          const data = (await entry.getData(new zip.Uint8ArrayWriter())) as Uint8Array;
          if (data.length > 0) {
            log.debug("processing content of", name);
            const osf: IVEXosFile = { name, data };
            this._vexos.push(osf);
          }
        }
      }

      zipReader.close();
      return this._vexos.length;

    } catch (err) {
      log.error('vexos: unzip error');
      log.error(err);
      throw 0;
    }
  }
}

class VexFWIQ extends VexFW {
  protected _urlPublic: string  = 'https://content.vexrobotics.com/vexos/public/IQ/';
  protected _urlBeta: string = 'https://content.vexrobotics.com/vexos/public_beta/IQ/';
  protected _urlPrivate: string = 'https://content.vexrobotics.com/vexos/private/IQ/';
  _catalog: string = "catalog4.txt";
}

class VexFWIQ2 extends VexFW {
  protected _urlPublic: string  = 'https://content.vexrobotics.com/vexos/public/IQ2/';
  protected _urlBeta: string = 'https://content.vexrobotics.com/vexos/public_beta/IQ2/';
  protected _urlPrivate: string = 'https://content.vexrobotics.com/vexos/private/IQ2/';
}

class VexFWEXP extends VexFW {
  protected _urlPublic: string  = 'https://content.vexrobotics.com/vexos/public/EXP/';
  protected _urlBeta: string = 'https://content.vexrobotics.com/vexos/public_beta/EXP/';
  protected _urlPrivate: string = 'https://content.vexrobotics.com/vexos/private/EXP/';
}

class VexFWV5 extends VexFW {
  protected _urlPublic: string  = 'https://content.vexrobotics.com/vexos/public/V5/';
  protected _urlBeta: string = 'https://content.vexrobotics.com/vexos/public_beta/V5/';
  protected _urlPrivate: string = 'https://content.vexrobotics.com/vexos/private/V5/';
}

export {
  VexFW,
  VEXosType,

  VexFWIQ,
  VexFWIQ2,
  VexFWEXP,
  VexFWV5,
}
