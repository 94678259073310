export class WebUSBUnsupportedError extends Error {
  constructor(...params: any) {
    super(...params); // (1)
    this.name = "WebUSBUnsupportedError"; // (2)
  }
}

export class DFUNonVexDeviceError extends Error {
  constructor(...params: any) {
    super(...params); // (1)
    this.name = "DFUNonVexDeviceError"; // (2)
  }
}

export class DFUNoDeviceError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "DFUNoDeviceError";
  }
}

