/**
 * Concatenate two Array buffers and return a new one
 * @param {ArrayBuffer} buffer1 the first ArrayBuffer
 * @param {ArrayBuffer} buffer2 the first ArrayBuffer
 * @return {ArrayBuffer}
 */
export function appendArrayBuffer( buffer1: ArrayBuffer, buffer2: ArrayBuffer ): ArrayBuffer {
  var length1 = buffer1 ? buffer1.byteLength : 0;
  var length2 = buffer2 ? buffer2.byteLength : 0;
  
  var tmp = new Uint8Array( length1 + length2 );
  if( tmp.length === 0 ) return null;
  
  if(buffer1)
    tmp.set( new Uint8Array( buffer1 ), 0 );
  if(buffer2)
    tmp.set( new Uint8Array( buffer2 ), length1 );
  
  return tmp.buffer;
}

/**
 * Convert an ArrayBuffer to a string for display
 * @param buf the ArrayBuffer or UInt8Array
 * @return the converted string
 */
export function convertBufferToHexString(buf: ArrayBuffer) {
  if (!buf) {
    return 'error';
  }

  let str = '';
  const uint8Array = new Uint8Array(buf);
  for (let i = 0; i < uint8Array.length; i++) {
    str = str + ('00' + uint8Array[i].toString(16)).substr(-2, 2) + ' ';
  }

  return str.toUpperCase();
}
