import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexDFU");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

// log.setHistoryLogger("WebSerial");

import { VexDFUDevice } from "./VexDFUDevice";
import { DFUNoDeviceError, WebUSBUnsupportedError } from "./errors";
import type { IFunctionalDescriptor, IBasicInterface } from "./VexDFUDevice";

enum DFUTargetDevice {
  IQ2Controller,
  EXPController,

  None,
}

class VexDFU {
  readonly isSupported: boolean = navigator && navigator.usb ? true : false;

  private _device?: VexDFUDevice = null;
  private transferSize: number = 1024;
  private manifestationTolerant: boolean = true;

  private _filters: USBDeviceFilter[] = [];

  constructor(target: DFUTargetDevice) {
    if (!this.isSupported) {
      log.error("Web USB is not supported!")
    } else {
      log.info("Web USB is supported");
    }

    // {vendorId: 10376, productId: 543}, // IQ controller
    // {vendorId: 10376, productId: 1567}, // EXP controller

    if (target === DFUTargetDevice.EXPController) {
      this._filters = [
        {vendorId: 10376, productId: 1567}, // EXP controller
      ]
    } else if (target === DFUTargetDevice.IQ2Controller) {
      this._filters = [
        {vendorId: 10376, productId: 543}, // IQ controller
      ]
    }
  }

  get device(): VexDFUDevice {
    return this._device;
  }

  /**
   * Prompts the user to select a DFU device to connect to.
   *
   * If there is an active connection already, this will close that connection
   * @throws WebUSBUnsupportedError if the connection is not supported in the browser
   */
  async openConnection() {
    // make sure that this is supported
    if (!this.isSupported) {
      throw new WebUSBUnsupportedError();
    }

    // make sure we close any open connections
    if (this._device) {
      this.closeConnection();
    }

    // get devices
    try {
      const selectedDevice = await navigator.usb.requestDevice({ filters: this._filters });
      log.debug("selectedDevice:", selectedDevice);
      const interfaces = VexDFUDevice.findDeviceDfuInterfaces(selectedDevice)
      log.debug("interfaces:", interfaces);
      if (interfaces.length === 0) {
        log.info("selected device has no DFU interfaces")
      } else if (interfaces.length === 1) {
        log.info("selected device has one DFU interface");
        await this.fixInterfaceNames(selectedDevice, interfaces);
        this._device = await this.connectToDevice(new VexDFUDevice(selectedDevice, interfaces[0]));
        log.debug("device:", this._device);
      } else {
        log.warn("selected device has multiple DFU interfaces???")
        // TODO: handle multpile interfaces?
      }
    } catch (err) {
      log.error(err);
    }
  }

  /**
   * close an open connection.
   */
  async closeConnection() {
    if (this._device) {
      this._device.close();
      this._device = null;
    }
  }

  /**
   * downloads the provided firmware bin to the connected device
   * @param bin the bin to load onto the connected device
   * @param progress a callback with progress updates
   * @throws WebUSBUnsupportedError if the connection is not supported in the browser
   * @throws DFUNoDeviceError if there is no device connected
   */
  async updateDevice(bin: ArrayBuffer, progress?: (progress: number) => void) {
    // make sure that this is supported
    if (!this.isSupported) {
      throw new WebUSBUnsupportedError();
    }

    // make sure we are actually connected to a device
    if (!this._device) {
      throw new DFUNoDeviceError();
    }

    // TODO: make sure we can actually download to a device

    try {
      const status = await this._device.getState();
      if (status === VexDFUDevice.dfuERROR) {
        await this._device.clearStatus();
      }
    } catch (err) {
      log.warn("Failed to clear status");
    }

    await this._device.do_download(this.transferSize, bin, this.manifestationTolerant, progress);
  }

  /**
   * try to connect to the selected device
   */
  private async connectToDevice(device: VexDFUDevice) {
    try {
      await device.open();
    } catch (err) {
      log.error(err);
      throw err;
    }

    // Attempt to parse the DFU functional descriptor
    let desc: any = {};
    try {
      desc = await this.getDFUDescriptorProperties(device);
    } catch (error) {
      log.error(error);
      throw error;
    }
    log.debug("desc:", desc);
    if (desc && Object.keys(desc).length > 0) {
        this.transferSize = desc.TransferSize;
        if (desc.CanDnload) {
            this.manifestationTolerant = desc.ManifestationTolerant;
        }
        log.debug("this.transferSize:", this.transferSize);
        log.debug("this.manifestationTolerant:", this.manifestationTolerant);
    }

    return device;
  }

  private async fixInterfaceNames(device_: USBDevice, interfaces: IBasicInterface[]) {
    // Check if any interface names were not read correctly
    if (interfaces.some(intf => (intf.name == null))) {
        // Manually retrieve the interface name string descriptors
        let tempDevice = new VexDFUDevice(device_, interfaces[0]);
        await tempDevice.device_.open();
        await tempDevice.device_.selectConfiguration(1);
        let mapping = await tempDevice.readInterfaceNames();
        await tempDevice.close();

        for (let intf of interfaces) {
            if (intf.name === null) {
                let configIndex = intf.configuration.configurationValue;
                let intfNumber = intf["interface"].interfaceNumber;
                let alt = intf.alternate.alternateSetting;
                intf.name = mapping[configIndex][intfNumber][alt];
            }
        }
    }
  }

  private getDFUDescriptorProperties(device: VexDFUDevice) {
    // Attempt to read the DFU functional descriptor
    // TODO: read the selected configuration's descriptor
    return device.readConfigurationDescriptor(0)
      .then(
        data => {
          let configDesc = VexDFUDevice.parseConfigurationDescriptor(data);
          let funcDesc: IFunctionalDescriptor = null;
          let configValue = device.settings.configuration.configurationValue;
          if (configDesc.bConfigurationValue == configValue) {
            for (let desc of configDesc.descriptors) {
              if (desc.bDescriptorType == 0x21 && desc.hasOwnProperty("bcdDFUVersion")) {
                funcDesc = desc as IFunctionalDescriptor;
                break;
              }
            }
          }

          if (funcDesc) {
            return {
              WillDetach:            ((funcDesc.bmAttributes & 0x08) != 0),
              ManifestationTolerant: ((funcDesc.bmAttributes & 0x04) != 0),
              CanUpload:             ((funcDesc.bmAttributes & 0x02) != 0),
              CanDnload:             ((funcDesc.bmAttributes & 0x01) != 0),
              TransferSize:          funcDesc.wTransferSize,
              DetachTimeOut:         funcDesc.wDetachTimeOut,
              DFUVersion:            funcDesc.bcdDFUVersion
            };
          } else {
              return {};
          }
        },
        error => {}
    );
  }

}

export {
  VexDFU,
  DFUTargetDevice,
}
