import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexV5WebSerial");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

import {
  VEXBrainUpdateStates,
  VexDeviceWebSerial
} from "./VexDeviceWebSerial";
import { VexFirmwareVersion } from "./VexFirmwareVersion";
import { VexFWV5 } from "./firmware/VexFW";

import type {
  IControllerConfigInfo,
  IProjectInformation,
  IPythonProgramLinkInfo,
  IPythonVMDownloadInfo,
  ProgressCallbackDownload,
  BrainUpdateProgressCallback,
  ConnectPromptCallback,
} from "./VexDeviceWebSerial";
import type { VEXWebSerialDeviceType } from "./DeviceType";
import { ErrorUpdatingBrainAssets, ErrorUpdatingBrainBoot, ErrorUpdatingBrainGolden, OperationNotSupportedError } from "./errors";
import { DFUTargetDevice } from "./dfu/VexDFU";
import { UpdateNeededOptions } from "./enums";

interface IV5ControllerConfigInfo extends IControllerConfigInfo {
  ButtonL1: string;
  ButtonL2: string;
  ButtonR1: string;
  ButtonR2: string;

  ButtonUp: string;
  ButtonDown: string;
  ButtonLeft: string;
  ButtonRight: string;

  ButtonA: string;
  ButtonB: string;
  ButtonX: string;
  ButtonY: string;
}

interface IV5ProjectInformation extends IProjectInformation {
  controller1: IV5ControllerConfigInfo;
  controller2: IV5ControllerConfigInfo;
}

class VexV5WebSerial extends VexDeviceWebSerial {

  constructor() {
    super();

    this.serialConnection.portFilters = [
      {usbVendorId: 0x2888, usbProductId: 0x0501}, // brain
      // {usbVendorId: 0x2888, usbProductId: 0x0503}, // controller
    ];

    log.info("constructed V5")
  }

  //#region connection information
  get deviceType(): VEXWebSerialDeviceType {
    return "V5";
  }

  protected processPortInformation(portInfo: SerialPortInfo) {
    // TODO: add V5 controller support here
  }
  //#endregion connection information

  //#region connection check helpers
  //#endregion connection check helpers

  //#region brain information
  //#endregion brain information

  //#region controller information
  async fetchControllerInfo() {
    throw new OperationNotSupportedError("unable to pull date from V5 controller");
  }
  //#endregion controller information

  //#region firmware
  async getCurrentFirmwareVersion(): Promise<VexFirmwareVersion> {
    const catalogURL = "https://content.vexrobotics.com/vexos/public/V5/catalog.txt";
    log.debug("catalogURL:", catalogURL);
    try {
      const catalogResponse = await fetch(catalogURL);
      const catalog = await catalogResponse.text();
      log.debug("catalog content:", catalog);
      const versionStr = catalog.replace(/VEXOS_V5_/, "");
      return VexFirmwareVersion.fromCatalogString(versionStr);
    } catch (err) {
      log.warn(err);
      return null;
    }
  }

  async updateFirmware(progressCB: BrainUpdateProgressCallback, connectPrompt: ConnectPromptCallback): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    this.checkRequiredBrainConnection();
    
    const brainVersionStart = this.brainVersionSystem ? this.brainVersionSystem.toInternalString() : "unknown";

    let lastProgress = {
      state: VEXBrainUpdateStates.done,
      prog: -1, 
      msg: "",
    };
    let totalSteps = VEXBrainUpdateStates.AssetUpdate - 1;
    let goldenOffset: number = 2;
    const sendProgress: BrainUpdateProgressCallback = (state, prog, msg) => {
      const roundedProgress = Math.round(prog * 100);
      if (lastProgress.state === state && lastProgress.msg === msg && lastProgress.prog === roundedProgress) {
        // no point sending this update as it is the same as the previous update
        return;
      }
      lastProgress = {state, prog: roundedProgress, msg};

      const offset = state > VEXBrainUpdateStates.GoldenReboot ? state - goldenOffset : state - 1;
      const totalProgress = (offset + prog) / totalSteps;

      // TODO: calculate total progress
      log.debug("brain firmware progress:", VEXBrainUpdateStates[state], prog, totalProgress, msg);
      if (progressCB) {
        progressCB(state, totalProgress, msg);
      }
    }

    // based on function updateFirmware_v5 from device manager
    
    try {
      // 1. get FW file
      const fw = await this.loadFirmware((prog) => {
        sendProgress(VEXBrainUpdateStates.LoadingFirmwareFile, prog, "Loading Firmware File");
      });


      // 2. update golden image if needed
      if ((this.primaryBootSource === true) && (this.checkGoldenImage() === false)) {
        log.info("updating Golden...");
        const goldenFW = fw.getFile("boot.bin");
        const goldenRes = await this.updateBrain(goldenFW.data, 0xB2, (percent, total) => {
          sendProgress(VEXBrainUpdateStates.GoldenUpdate, percent, "Updating Golden");
        });

        if (!goldenRes)  {
          throw new ErrorUpdatingBrainGolden();
        }
      } else {
        goldenOffset = 3;
        totalSteps = VEXBrainUpdateStates.AssetUpdate - 2;
      }

      // 3. update boot.bin
      log.info("updating firmware");
      sendProgress(VEXBrainUpdateStates.FirmwareUpdate, 0, "Updating Firmware");
      const bootFW = fw.getFile("boot.bin");
      const bootRes = await this.updateBrain(bootFW.data, 1, (percent, total) => {
        sendProgress(VEXBrainUpdateStates.FirmwareUpdate, percent, "Updating Firmware");
      });

      if (!bootRes) {
        throw new ErrorUpdatingBrainBoot();
      }

      // 4. update assets
      log.info("updating assets");
      sendProgress(VEXBrainUpdateStates.AssetUpdate, 0, "Updating Assets");
      const assetFW = fw.getFile("assets.bin");
      const assetRes = await this.updateBrain(assetFW.data, 0, (percent, total) => {
        sendProgress(VEXBrainUpdateStates.AssetUpdate, percent, "Updating Assets");
      });

      if (!assetRes) {
        throw new ErrorUpdatingBrainAssets();
      }

      this.onDisconnect();

      // // 5. reboot and reconnect
      // log.info("rebooting... waiting for connection loss");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0, "Waiting For Poweroff");
      // while (this.isConnected) {
      //   await this.delay(500);
      // }
      
      // log.info("rebooting... waiting for reboot");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0.3, "Waiting For Reboot");
      // await this.delay(3000);

      // log.info("rebooting... waiting for new connection");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0.5, "Waiting For Connection");
      // let reconnected = false;
      // while (!reconnected) {
      //   try {
      //     await this.reconnect();
      //     reconnected = true;
      //   } catch(err) {
      //     if (err instanceof DOMException) {
      //       if (err.name === "SecurityError") {
      //         log.warn("failed to open connect prompt due to security permissions");
      //         await connectPrompt();  
      //       } else if (err.name === "NotFoundError") {
      //         log.warn("user hit cancel")
      //         await connectPrompt();
      //       }
      //     } else {
      //       log.error("error name:", err.name, err);
      //       throw err;
      //     }
      //   }
      // }

      this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;

      log.info("update complete")
      sendProgress(VEXBrainUpdateStates.done, 1, "Update Complete");

      // setTimeout(async () => {
      //   await this.fetchBrainInfo();
      //   await this.checkUpdateNeededBrain();
      //   this.fireEvent("deviceInfoUpdated", this.getBrainInfo());
      // }, 200);

      // this.isUpdatingFirmware = false;
    } catch (err) {
      this.isUpdatingFirmware = false;
      this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;
      log.debug("brain update error:", err);
      log.debug("brain version before update start:", brainVersionStart);
      throw err;
    }
    return true;
  }
  //#endregion firmware

  //#region firmware file data
  protected createVexFWInstance() {
    return new VexFWV5();
  }
  //#endregion firmware file data

  //#region user data
  //#endregion user data

  //#region project controls
  //#endregion project controls

  //#region downloads
  async downloadProgram(data: ArrayBuffer, info: IV5ProjectInformation, progress: ProgressCallbackDownload): Promise<boolean> {
    return super.downloadProgram(data, info, progress);
  }
  //#endregion downloads

  //#region brain files
  //#endregion brain files

  //#region file metadata helpers
  //#endregion file metadata helpers

  //#region brain info meta data helpers
  //#endregion brain info meta data helpers

  //#region Python vm helpers
  protected getVMLinkInfo(): IPythonProgramLinkInfo {
    return {
      exttype: 0x61,
      loadaddr: 0x07000000,
      linkfile: "python_vm.bin",
      linkfilevid: VexV5WebSerial.VID.VEXVM,
    };
  }

  protected getVmMeta() {
    return {
      crc: 0x00DB182E,
      version: 0x0100010E,
    };
  }
  protected getPythonVMResourcePath(): string {
    return "resources/v5/vm/python_vm.bin";
  }

  protected getPythonVmDownloadInfo(): IPythonVMDownloadInfo {
    const meta = this.getVmMeta();
    return {
      address: 0,
      target: 0,
      vid: VexV5WebSerial.VID.VEXVM,
      version: meta.version,
    };
  }

  protected postVMDownloadCleanup() {
  }
  //#endregion

  //#region controller firmware
  protected get radioChipId() {
    return 0;
  }

  protected get dfuTarget() {
    return DFUTargetDevice.None;
  }
  //#endregion controller firmware

  //#region controller comms
  //#endregion controller comms

  //#region low level comms
  //#endregion low level comms

  //#region event handlers
  //#endregion event handlers
}

export {
  VexV5WebSerial
}

export type {
  IV5ControllerConfigInfo,
  IV5ProjectInformation,
}
