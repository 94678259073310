import * as logger from "@rm-vca/logger";
const log = logger.getLogger("WebSerialDemo");
log.setLevel(log.levels.WARN);
// for dev only
log.enableAll();

import * as React from "react";
import * as ReactDOM from "react-dom";

import {
  VexV5WebSerial,
  IV5ProjectInformation,
  VEXcodeIcons,

  VexEXPWebSerial,
  IEXPProjectInformation,

  VexIQWebSerial,
  IIQProjectInformation,
} from "../../src";

import { VexCDC } from "../../src/VexCDC";

import { VexDFU } from "../../src/dfu/VexDFU";
import * as VexFW from "../../src/firmware/VexFW";
import { VEXBrainUpdateStates, VEXControllerUpdateStates, VexWebSerialConnectionStates } from "../../src/VexDeviceWebSerial";

//#region window events
function onWindowLoad(ev: Event) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/load_event
}
window.addEventListener("load", onWindowLoad);

function onWindowResize(ev: Event) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/resize_event
}
window.addEventListener("resize", onWindowResize);

function onWindowBeforeUnload(ev: Event) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
}
window.addEventListener("beforeunload", onWindowBeforeUnload);

function onWindowUnload(ev: Event) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
}
window.addEventListener("unload", onWindowUnload);
//#endregion

function str2ab(str: string): ArrayBuffer {
  var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i=0, strLen=str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

//#region DemoWrapper
interface DemoWrapperProps {

}

interface DemoWrapperState {
  adminconnected: boolean;
  canConnectUser: boolean;
  userConnected: boolean;
}

const v5: VexV5WebSerial | null = null;//new VexV5WebSerial();
const exp: VexEXPWebSerial | null = null;//new VexEXPWebSerial();
const iq: VexIQWebSerial | null = new VexIQWebSerial();

const hwif = v5 || exp || iq;

let fileInput: HTMLInputElement;

function getFileInput(): HTMLInputElement {
  return fileInput;
}

function progressLoggerBrain(state: VEXBrainUpdateStates, progress: number, msg: string) {
  log.info(progress * 100, "% -", VEXBrainUpdateStates[state], msg);
}

function progressLoggerController(state: VEXControllerUpdateStates, progress: number, msg: string) {
  log.info(progress * 100, "% -", VEXControllerUpdateStates[state], msg);
}

class DemoWrapper extends React.Component<DemoWrapperProps, DemoWrapperState> {
  public fileInputRef: React.RefObject<HTMLInputElement>
  public dfuFileInputRef: React.RefObject<HTMLInputElement>
  public reconnectButtonRef: React.RefObject<HTMLButtonElement>

  constructor(props: DemoWrapperProps) {
    super(props);
    this.state = {
      adminconnected: false,
      canConnectUser: false,
      userConnected: false,
    };

    this.onConnect = this.onConnect.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onGetName = this.onGetName.bind(this);
    this.onGetTeam = this.onGetTeam.bind(this);

    this.onGetFile = this.onGetFile.bind(this);
    this.fileInputRef = React.createRef();
    this.dfuFileInputRef = React.createRef();
    this.reconnectButtonRef = React.createRef();

    this.onDownload = this.onDownload.bind(this);


    this.onBrainUpdate = this.onBrainUpdate.bind(this);

    this.onControllerUpdate = this.onControllerUpdate.bind(this);
    this.onControllerUpdateForce = this.onControllerUpdateForce.bind(this);
    this.onControllerUpdateDFU = this.onControllerUpdateDFU.bind(this);
    this.onControllerUpdateAtmel = this.onControllerUpdateAtmel.bind(this);
    this.onControllerUpdateRadio = this.onControllerUpdateRadio.bind(this);

    this.connectPrompt = this.connectPrompt.bind(this);
    this.onReconnectClick = this.onReconnectClick.bind(this);

    this.onAdminStateChange = this.onAdminStateChange.bind(this);
    this.onUserStateChange = this.onUserStateChange.bind(this);

    hwif.on("connectionStateChange", this.onAdminStateChange);
    hwif.on("connectionStateChangeUserPort", this.onUserStateChange);
  }

  componentDidMount() {
    this.reconnectButtonRef.current.disabled = true;
  }

  componentWillUnmount() {
  }

  onAdminStateChange(newState: VexWebSerialConnectionStates) {
    this.setState({
      adminconnected: newState === VexWebSerialConnectionStates.Connected,
      canConnectUser: hwif.canConnectUserPort,
      userConnected: hwif.isConnectedUserPort,
    });
  }

  onUserStateChange(newState: VexWebSerialConnectionStates) {
    this.setState({
      canConnectUser: hwif.canConnectUserPort,
      userConnected: hwif.isConnectedUserPort,
    });
  }

  onConnect() {
    log.info("onConnect");
    hwif.openConnection();
  }

  onDisconnect() {
    log.info("onDisconnect");
    hwif.closeConnection();
  }

  onConnectUserPort() {
    log.info("onConnectUserPort");
    hwif.openConnectionUserPort();
  }

  onDisconnectUserPort() {
    log.info("onDisconnectUserPort");
    hwif.closeConnectionUserPort();
  }

  onPlay() {
    log.info("onPlay");
    hwif.play(1);
  }

  onStop() {
    log.info("onStop");
    hwif.stop();
  }

  onGetFile() {
    fileInput = this.fileInputRef.current;
    log.debug("files:", fileInput.files);
  }

  onDownload() {
    if (!this.fileInputRef.current) {
      log.warn("no file input????");
      return;
    }
    const files = this.fileInputRef.current.files;
    if (!files || files.length === 0) {
      log.warn("no file selected");
      return;
    }

    const fr = new FileReader();
    fr.onload = async () => {
      const res = fr.result as ArrayBuffer;
      log.debug("res:", res);
      
      const infov5: IV5ProjectInformation = {
        slot: 2,
        name: "web test",
        description: "testing web serial download",
        icon: VEXcodeIcons.VEXcodePro,
        ide: "web pro...",
        ports: [],
        triports: [],
        controller1: null,
        controller2: null,
        language: "cpp",
      }
      const infoexp: IEXPProjectInformation = {
        slot: 2,
        name: "web test",
        description: "testing web serial download",
        icon: VEXcodeIcons.VEXcodePro,
        ide: "web pro...",
        ports: [],
        triports: [],
        controller1: null,
        language: "cpp",
      }
      const infoiq: IIQProjectInformation = {
        slot: 2,
        name: "web test",
        description: "testing web serial download",
        icon: VEXcodeIcons.VEXcodePro,
        ide: "web pro...",
        language: "cpp",
        controller1: undefined,
        ports: [],
        triports: []
      }
      const info: any = infoexp;
      await hwif.downloadProgram(res, info, (...args: any[]) => {
        log.debug("progress:", ...args);
      })
    }
    fr.readAsArrayBuffer(files[0]);
  }

  onDownloadPython() {
    const expPython = [
      `#region VEXcode Generated Robot Configuration`,
      `from vex import *`,
      `import urandom`,
      ``,
      `# Brain should be defined by default`,
      `brain = Brain()`,
      ``,
      `# Robot configuration code`,
      `brain_inertial = Inertial()`,
      ``,
      ``,
      ``,
      `#endregion VEXcode Generated Robot Configuration`,
      ``,
      `# ------------------------------------------`,
      `# `,
      `# 	Project:      VEXcode Project`,
      `#	Author:       VEX`,
      `#	Created:`,
      `#	Description:  VEXcode EXP Python Project`,
      `# `,
      `# ------------------------------------------`,
      ``,
      `# Library imports`,
      `from vex import *`,
      ``,
      `# Begin project code`,
      ``,
    ].join("\n");

    const bin = str2ab(expPython);
    log.debug("bin:", bin);

    const infoexp: IEXPProjectInformation = {
      slot: 2,
      name: "web test",
      description: "testing web serial download",
      icon: VEXcodeIcons.VEXcodePro,
      ide: "web pro...",
      ports: [],
      triports: [],
      controller1: null,
      language: "python",
    }
    const info: any = infoexp;
    hwif.downloadProgram(bin, info, (...args: any[]) => {
      log.debug("progress:", ...args);
    })
  }

  async onGetName() {
    log.info("onGetName");
    const name = hwif.getBrainName();
    log.info("brain name:", await name);
  }

  async onGetTeam() {
    log.info("onGetTeam");
    const name = hwif.getBrainTeamNumber();
    log.info("brain team:", await name);
  }

  private reconnectCallback: () => void = null;
  setReconnectCallback(callback: () => void) {
    this.reconnectCallback = callback;
    this.reconnectButtonRef.current.disabled = false;
  }
  onReconnectClick() {
    if (this.reconnectCallback) {
      this.reconnectCallback();
      this.reconnectCallback = null;
      this.reconnectButtonRef.current.disabled = true;
    }
  }

  connectPrompt(isFirstConnect: boolean, isDfu: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      alert("Please click the 'reconnect' button");
      log.warn("Please click the 'reconnect' button");
      this.setReconnectCallback(() => {
        resolve(true);
      })
    })
  }
  
  async onBrainUpdate() {
    hwif.updateFirmware(progressLoggerBrain, this.connectPrompt);
  }

  async onControllerUpdate() {
    hwif.controllerUpdate(progressLoggerController, this.connectPrompt, false, false);
  }

  async onControllerUpdateForce() {
    hwif.controllerUpdate(progressLoggerController, this.connectPrompt, false, true);
  }

  async onControllerUpdateDFU() {
    hwif.controllerUpdate(progressLoggerController, this.connectPrompt, true, false);
  }

  async onControllerUpdateAtmel() {
    const fwData = await hwif.getControllerFirmwareData(progressLoggerController);
    hwif.controllerUpdateAtmel(progressLoggerController, this.connectPrompt, fwData.atmel.bin);
  }

  async onControllerUpdateRadio() {
    const fwData = await hwif.getControllerFirmwareData(progressLoggerController);
    hwif.controllerUpdateRadio(progressLoggerController, fwData.radio.bin);
  }

  render() {
    const { adminconnected, userConnected, canConnectUser } = this.state;

    return (
      <div
      >
        <div>
          Connection controls: 
          <button onClick={this.onConnect} disabled={adminconnected}>Connect</button>
          <button onClick={this.onDisconnect} disabled={!adminconnected}>Disconnect</button>
          <button onClick={this.onReconnectClick} ref={this.reconnectButtonRef}>reconnect</button>
          <button onClick={this.onConnectUserPort} disabled={!(canConnectUser && !userConnected)}>Connect User</button>
          <button onClick={this.onDisconnectUserPort} disabled={!canConnectUser || !userConnected}>Disconnect User</button>
        </div>
        <div>
          Program Controll:
          <button onClick={this.onPlay}>Play</button>
          <button onClick={this.onStop}>Stop</button>
        </div>
        <div>
          Brian Info:
          <button onClick={this.onGetName}>get name</button>
          <button onClick={this.onGetTeam}>get team</button>
        </div>
        <br />
        <div>
          <input type="file" name="inputfile" id="inputfile" ref={this.fileInputRef} />
          <br />
          <button onClick={this.onGetFile}>get file input ref</button>
          <button onClick={this.onDownload}>download selected C++</button>
          <button onClick={this.onDownloadPython}>download Python</button>
        </div>
        <br />
        {/* DFU:
        <br />
        <div>
          <button>Connect DFU</button>
          <button>disconnect DFU</button>
        </div>
        <div>
          <input type="file" name="dfuinputfile" id="dfuinputfile" ref={this.dfuFileInputRef} />
          <br />
          <button onClick={this.onGetFile}>get file input ref</button>
          <button onClick={this.onDownload}>download</button>
        </div> */}
        <div>
          Brain FW:
          <button onClick={this.onBrainUpdate}>Update</button>
        </div>
        <div>
          Controller FW:
          <button onClick={this.onControllerUpdate}>Update</button>
          <button onClick={this.onControllerUpdateForce}>Force Update</button>
          <button onClick={this.onControllerUpdateDFU}>Recovery Update</button>
          <button onClick={this.onControllerUpdateAtmel}>Update Atmel</button>
          <button onClick={this.onControllerUpdateRadio}>Update Radio</button>
        </div>
      </div>
    );
  }
}
//#endregion

ReactDOM.render(
  <DemoWrapper />,
  document.getElementById("app") as HTMLElement,
);

export {
  logger,

  v5,
  VexCDC,

  iq,
  exp,

  hwif,

  getFileInput,

  VexDFU,
  VexFW,
};
