import {
  VexWebSerialConnectionStates,

  VEXBrainUpdateStates,
  VEXControllerUpdateStates,
} from "./VexDeviceWebSerial";

import {
  VexIQWebSerial,
} from "./VexIQWebSerial";
import type {
  IIQControllerConfigInfo,
  IIQProjectInformation,
} from "./VexIQWebSerial";

import {
  VexEXPWebSerial,
} from "./VexEXPWebSerial";
import type {
  IEXPControllerConfigInfo,
  IEXPProjectInformation,
} from "./VexEXPWebSerial";

import {
  VexV5WebSerial,
} from "./VexV5WebSerial";
import type {
  IV5ControllerConfigInfo,
  IV5ProjectInformation,
} from "./VexV5WebSerial";

import * as logger from "@rm-vca/logger";
const historyLogger = logger.getHistoryLoggerByName("WebSerial");
historyLogger.setMaxLines(10000);
historyLogger.setLevel("DEBUG");


import {
  VexINI,
  VEXcodeIcons,
} from "./VexINI";
import type {
  VEXIconString,
} from "./VexINI";

import * as WebSerialErrors from "./errors";


export {
  VexWebSerialConnectionStates,
  VEXBrainUpdateStates,
  VEXControllerUpdateStates,

  VexIQWebSerial,
  VexEXPWebSerial,
  VexV5WebSerial,

  VexINI,
  VEXcodeIcons,

  WebSerialErrors,
}

export type {
  IIQControllerConfigInfo,
  IIQProjectInformation,
  IEXPControllerConfigInfo,
  IEXPProjectInformation,
  IV5ControllerConfigInfo,
  IV5ProjectInformation,
  VEXIconString,
}
