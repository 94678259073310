import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexEXPWebSerial");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

import {
  VEXBrainUpdateStates,
  VexDeviceWebSerial,
} from "./VexDeviceWebSerial";
import { VexFirmwareVersion } from "./VexFirmwareVersion";
import { VexFWEXP } from "./firmware/VexFW";

import type {
  BrainUpdateProgressCallback,
  IControllerConfigInfo,
  IProjectInformation,
  IPythonProgramLinkInfo,
  IPythonVMDownloadInfo,
  ProgressCallbackDownload,
  ConnectPromptCallback,
} from "./VexDeviceWebSerial";
import type { VEXWebSerialDeviceType } from "./DeviceType";
import { cc264xdfu } from "./radio/cc264x";
import { UpdateNeededOptions } from "./enums";
import { DFUTargetDevice } from "./dfu/VexDFU";
import { ErrorUpdatingBrainAssets, ErrorUpdatingBrainBoot, ErrorUpdatingBrainGolden } from "./errors";

interface IEXPControllerConfigInfo extends IControllerConfigInfo {
  ButtonL1: string;
  ButtonL2: string;
  ButtonR1: string;
  ButtonR2: string;

  ButtonL3: string;
  ButtonR3: string;

  ButtonUp: string;
  ButtonDown: string;

  ButtonA: string;
  ButtonB: string;
}

interface IEXPProjectInformation extends IProjectInformation {
  controller1: IEXPControllerConfigInfo;
}

interface programInfo {
  startAddress:   number;
  blocks:         number;
  version:        number;
  type:           number;
  name:           string;
  size:           number;

  exec:           string;
  slot:           number;
  requestedSlot:  number; // not used
  time:           string; // not used
}

class VexEXPWebSerial extends VexDeviceWebSerial {
  static STATUS_GOOD:        number = 0x76;
  static STATUS_WACK:        number = 0xFE;
  static STATUS_FAIL:        number = 0xFF;
  static STATUS_TIMEOUT:     number = 0x1FF;
  static STATUS_DISCONNECT:  number = 0x2FF;
  static STATUS_CONNECT_ERR: number = 0x3FF;

  classIdent:       string     = 'Vex EXP serial';
  versionBoot:      number[]   = [0,0];
  versionBrain:     number[]   = [0,0];
  versionJoystick:  number[]   = [0,0];
  versionSystemStr: string     = '';
  versionHardware:  number     = 0;
  uniqueId:         number     = 0;
  deviceName:       string     = '';
  deviceTeamNumber: string     = '0000'; // not used 
  catalogAddresses: number[]   = [];
  programInfo:      programInfo[] = [];

  constructor() {
    super();

    this.deviceType = null;
    this.serialConnection.portFilters = [
      {usbVendorId: 0x2888, usbProductId: 0x0600}, // brain
      {usbVendorId: 0x2888, usbProductId: 0x0610}, // controller
    ];

    log.info("constructed EXP")
  }

  //#region connection information
  private _deviceType: VEXWebSerialDeviceType = null;
  get deviceType(): VEXWebSerialDeviceType {
    return this._deviceType;
  }
  set deviceType(newType: VEXWebSerialDeviceType) {
    this._deviceType = newType;
  }

  protected processPortInformation(portInfo: SerialPortInfo) {
    const isBrain = portInfo && portInfo.usbProductId === 0x0600;
    const isController = portInfo && portInfo.usbProductId === 0x0610;

    if (isBrain) {
      this.deviceType = "EXP";
    } else if (isController) {
      this.deviceType = "EXPController";
    } else {
      log.warn("unknown product ID", portInfo);
      this.deviceType = null;
    }
  }
  //#endregion connection information

  //#region connection check helpers
  //#endregion connection check helpers

  //#region brain information
  async brainGetSystemVersion(): Promise<ArrayBuffer> {
    log.info("brainGetSystemVersion");
    this.checkSupported();
    this.checkRequiredConnection();
    try {
      const reply = await this.writeDataAsync(this.cdc.systemVersion());
      this.decodeSystemVersion(reply);
      return reply
    } catch (err) {
      return undefined;
    }
  }
  //#endregion brain information

  //#region controller information
  //#endregion controller information

  //#region firmware
  async getCurrentFirmwareVersion(): Promise<VexFirmwareVersion> {
    const catalogURL = "https://content.vexrobotics.com/vexos/public/EXP/catalog.txt";
    log.debug("catalogURL:", catalogURL);
    try {
      const catalogResponse = await fetch(catalogURL);
      const catalog = await catalogResponse.text();
      log.debug("catalog content:", catalog);
      const versionStr = catalog.replace(/VEXOS_EXP_/, "");
      return VexFirmwareVersion.fromCatalogString(versionStr);
    } catch (err) {
      log.warn(err);
      return null;
    }
  }

  async updateFirmware(progressCB: BrainUpdateProgressCallback, connectPrompt: ConnectPromptCallback): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    this.checkRequiredBrainConnection();
    
    const brainVersionStart = this.brainVersionSystem ? this.brainVersionSystem.toInternalString() : "unknown";

    let lastProgress = {
      state: VEXBrainUpdateStates.done,
      prog: -1, 
      msg: "",
    };
    let totalSteps = VEXBrainUpdateStates.AssetUpdate - 1;
    let goldenOffset: number = 2;
    const sendProgress: BrainUpdateProgressCallback = (state, prog, msg) => {
      const roundedProgress = Math.round(prog * 100);
      if (lastProgress.state === state && lastProgress.msg === msg && lastProgress.prog === roundedProgress) {
        // no point sending this update as it is the same as the previous update
        return;
      }
      lastProgress = {state, prog: roundedProgress, msg};

      const offset = state > VEXBrainUpdateStates.GoldenReboot ? state - goldenOffset : state - 1;
      const totalProgress = (offset + prog) / totalSteps;

      // TODO: calculate total progress
      log.debug("brain firmware progress:", VEXBrainUpdateStates[state], state, prog, totalProgress, msg);
      if (progressCB) {
        progressCB(state, totalProgress, msg);
      }
    }

    // based on function updateFirmware_exp from device manager
    
    try {
      // 1. get FW file
      const fw = await this.loadFirmware((prog) => {
        sendProgress(VEXBrainUpdateStates.LoadingFirmwareFile, prog, "Loading Firmware File");
      });

      this.isUpdatingFirmware = true;

      // 2. update golden image if needed
      if ((this.primaryBootSource === true || this._ramBootloader === true) && (this.checkGoldenImage() === false)) {
        log.info("updating Golden...");
        const goldenFW = fw.getFile("EXP_boot_rom.bin");
        const goldenRes = await this.updateBrain(goldenFW.data, 0xB2, (percent, total) => {
          sendProgress(VEXBrainUpdateStates.GoldenUpdate, percent, "Updating Golden");
        });

        if (!goldenRes)  {
          throw new ErrorUpdatingBrainGolden();
        }

        // 2b. reboot brain and reconnect?
        log.info("waiting for reboot");
        sendProgress(VEXBrainUpdateStates.GoldenReboot, 0, "Rebooting Post Golden Update");
        await this.delay(3000);
        // TODO: do we need to reconnect?
      } else {
        goldenOffset = 3;
        totalSteps = VEXBrainUpdateStates.AssetUpdate - 2;
      }
      this.isUpdatingFirmware = false;

      // 3. update boot.bin
      log.info("updating firmware");
      sendProgress(VEXBrainUpdateStates.FirmwareUpdate, 0, "Updating Firmware");
      const bootFW = fw.getFile("boot.bin");
      const bootRes = await this.updateBrain(bootFW.data, 1, (percent, total) => {
        sendProgress(VEXBrainUpdateStates.FirmwareUpdate, percent, "Updating Firmware");
      });

      if (!bootRes) {
        throw new ErrorUpdatingBrainBoot();
      }

      // 4. update assets
      log.info("updating assets");
      sendProgress(VEXBrainUpdateStates.AssetUpdate, 0, "Updating Assets");
      const assetFW = fw.getFile("assets.bin");
      const assetRes = await this.updateBrain(assetFW.data, 0, (percent, total) => {
        sendProgress(VEXBrainUpdateStates.AssetUpdate, percent, "Updating Assets");
      });

      if (!assetRes) {
        throw new ErrorUpdatingBrainAssets();
      }

      this.onDisconnect();

      // // 5. reboot and reconnect
      // log.info("rebooting... waiting for connection loss");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0, "Waiting For Poweroff");
      // while (this.isConnected) {
      //   await this.delay(500);
      // }
      
      // log.info("rebooting... waiting for reboot");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0.3, "Waiting For Reboot");
      // await this.delay(10000);

      // log.info("rebooting... waiting for new connection");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0.5, "Waiting For Connection");
      // let reconnected = false;
      // while (!reconnected) {
      //   try {
      //     await this.reconnect();
      //     reconnected = true;
      //   } catch(err) {
      //     if (err instanceof DOMException) {
      //       if (err.name === "SecurityError") {
      //         log.info("failed to open connect prompt due to security permissions");
      //         await connectPrompt();  
      //       } else if (err.name === "NotFoundError") {
      //         log.info("user hit cancel")
      //         await connectPrompt();
      //       }
      //     } else {
      //       log.warn("error name:", err.name, err);
      //       throw err;
      //     }
      //   }
      // }

      this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;

      log.info("update complete")
      sendProgress(VEXBrainUpdateStates.done, 1, "Update Complete");

      // setTimeout(async () => {
      //   await this.fetchBrainInfo();
      //   await this.checkUpdateNeededBrain();
      //   this.fireEvent("deviceInfoUpdated", this.getBrainInfo());
      // }, 200);

      // this.isUpdatingFirmware = false;
    } catch (err) {
      this.isUpdatingFirmware = false;
      this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;
      log.debug("brain update error:", err);
      log.debug("brain version before update start:", brainVersionStart);
      throw err;
    }
    return true;
  }
  //#endregion firmware

  //#region firmware file data
  protected createVexFWInstance() {
    return new VexFWEXP();
  }
  //#endregion firmware file data

  //#region user data
  //#endregion user data

  //#region project controls
  //#endregion project controls

  //#region downloads
  async downloadProgram(data: ArrayBuffer, info: IEXPProjectInformation, progress: ProgressCallbackDownload): Promise<boolean> {
    return super.downloadProgram(data, info, progress);
  }
  //#endregion downloads

  //#region brain files
  //#endregion brain files

  //#region file metadata helpers
  //#endregion file metadata helpers

  //#region brain info meta data helpers
  //#endregion brain info meta data helpers

  //#region Python vm helpers
  protected getVMLinkInfo(): IPythonProgramLinkInfo {
    return {
      exttype: 0x61,
      loadaddr: 0x30700000,
      linkfile: "python_vm.bin",
      linkfilevid: VexDeviceWebSerial.VID.VEXVM,
    };
  }

  protected getVmMeta() {
    return {
      crc: 0x242DEB97,
      version: 0x01000008,
    };
  }
  protected getPythonVMResourcePath(): string {
    return "resources/exp/vm/python_vm.bin";
  }

  protected getPythonVmDownloadInfo(): IPythonVMDownloadInfo {
    return {
      address: 0x30700000,
      target: VexDeviceWebSerial.FILE_TARGET_FLASH,
      vid: 0xFF,
    };
  }

  protected postVMDownloadCleanup() {
    this.downloadTargetSet(VexDeviceWebSerial.FILE_TARGET_QSPI);
    this.downloadAddressSet(VexDeviceWebSerial.USR_ADDRESS);
  }
  //#endregion Python vm helpers

  //#region controller firmware
  protected get radioChipId() {
    return cc264xdfu.CC2642_CHIP_ID
  }

  protected get dfuTarget() {
    return DFUTargetDevice.EXPController;
  }
  //#endregion controller firmware

  //#region controller comms
  //#endregion controller comms

  //#region low level comms
  private decodeSystemVersion(msg: Uint8Array | ArrayBuffer): void {
    /*
      byte 0 = 0xAA
      byte 1 = 0x55
      byte 2 = 0xA4 // CDC_GET_SYS_VERSION
      byte 3 = 8 // size of the data ... at least for now
      bytes 4-11 = the version structure
        byte 0 = major
        byte 1 = minor
        byte 2 = build
        byte 3 = hardware version
        byte 4 = beta version
        byte 5 = product id // not used?
        byte 6 = product flags
          bit0 = ?
          bit1 = battery low
          bit2 = ?
          bit3 = ?
          bit4 = ?
          bit5 = ?
          bit6 = ?
          bit7 = ?
        byte 7 = reserved
    */
    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array( msg ) : msg;

    if (this.cdc.cdcValidateIQMessage(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength(buf);
      if (length > 128) {
        extcmd = 5;
      }

      this.versionSystem[0] = dvb.getUint8(extcmd + 0);
      this.versionSystem[1] = dvb.getUint8(extcmd + 1);
      this.versionSystem[2] = dvb.getUint8(extcmd + 2);
      this.versionSystem[3] = dvb.getUint8(extcmd + 4);

      this.updateSystemVersionString();
    }
  }
  //#endregion low level comms

  //#region event handlers
  //#endregion event handlers
}

export {
  VexEXPWebSerial
}

export type {
  IEXPControllerConfigInfo,
  IEXPProjectInformation,
}
