import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexWebSerial");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

import { WebSerialUnsupportedError } from "./errors";

type EventNames = "connected" | "disconnected";

/**
 * Convert an Uint8Array to a string for display
 * @param uint8Array the Uint8Array
 * @return the converted string
 */
function convertUint8ArrayToHexString(uint8Array: Uint8Array) {
  if (!uint8Array) {
    return 'error';
  }

  let str = '';
  for (let i = 0; i < uint8Array.length; i++) {
    str = str + ('00' + uint8Array[i].toString(16)).substr(-2, 2);
    if (i % 4 === 3) {
      str = str + ' ';
    }
  }

  return str.toUpperCase();
}


export class VexWebSerial {

  readonly isSupported: boolean = navigator && navigator.serial ? true : false;
  private _portFilters: SerialPortFilter[] = [{ usbVendorId: 0x2888 }];

  private serialPort: SerialPort = null;
  private serialReader: ReadableStreamDefaultReader<Uint8Array> = null;
  private serialWriter: WritableStreamDefaultWriter<Uint8Array> = null;

  public onRXData: (data: ArrayBuffer) => void = null;

  constructor() {
    if (!this.isSupported) {
      log.error("Web Serial is not supported!")
    } else {
      log.info("Web serial is supported");
    }

    // this.onConnect = this.onConnect.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);

    // navigator.serial.addEventListener("connect", this.onConnect);
    navigator.serial.addEventListener("disconnect", this.onDisconnect);  
  }


  get portFilters(): SerialPortFilter[] {
    return this._portFilters;
  }

  set portFilters(filters: SerialPortFilter[]) {
    this._portFilters = filters;
  }

  /**
   * will try to open a connection to a serial port
   * @returns 
   */
  async openConnection() {
    if (!this.isSupported) {
      log.error("Web Serial is not supported!");
      throw new WebSerialUnsupportedError();
    }
    if (this.serialPort) {
      log.warn("already connected to a serial port");
      return;
    }

    try {
      await this.reconnect();
    } catch(error) {
      log.error(error);
      this.serialPort = null;
      this.fireEvent("disconnected");
      throw error;
    }
  }

  public async reconnect() {
    const requestOptions: SerialPortRequestOptions = {filters: this._portFilters};
    const port = await navigator.serial.requestPort(requestOptions);

    if (port) {
      log.debug("user selected a serial port")
      this.serialPort = port;

      // - Wait for the port to open.
      log.debug("attempting to open connection to the selected serial port");
      await port.open({ baudRate: 115200 }); // chrome 87 changed API
      log.debug("opened connection to the selected serial port");

      this.serialReader = port.readable.getReader();
      this.serialWriter = port.writable.getWriter();

      this.fireEvent("connected");

      log.debug("starting read loop");
      this.serialReadLoop(this.serialReader).then(() => {
        log.debug("read loop ended");
      });
    } else {
      log.debug("user did not select a serial port");
      this.fireEvent("disconnected");
    }
  }

  async closeConnection() {
    if (this.serialPort) {
      log.debug("need to close port");
      if (this.serialReader) {
        log.debug("cancel reader");
        await this.serialReader.cancel();
        log.debug("reader canceled");
      }

      if (this.serialWriter) {
        log.debug("release writer");
        await this.serialWriter.releaseLock();
        log.debug("writer released");
      }

      if (this.serialPort) {
        log.debug("closing port");
        await this.serialPort.close();
        this.serialPort = null;
        log.debug("port closed");
        
        this.fireEvent("disconnected");
      }
    }
  }

  writeToSerial(data: Uint8Array) {
    if (!this.isSupported) {
      log.error("Web Serial is not supported!");
      throw new WebSerialUnsupportedError();
    }
    if (!this.serialPort) {
      log.warn("not connected to a serial port");
      return;
    }

    log.debug("writing:", convertUint8ArrayToHexString(data));
    this.serialWriter.write(data);
  }

  async serialReadLoop(reader: ReadableStreamDefaultReader<Uint8Array>) {
    while (true) {
      try {
        const { value, done } = await reader.read();
        log.debug("readloop done:", done, "value:", convertUint8ArrayToHexString(value));

        if (this.onRXData) {
          this.onRXData(value);
        }

        if (done) {
          reader.releaseLock();
          return;
        }
      } catch (error) {
        console.log(error);
        reader.releaseLock();
        return;
      }
    }
  }

  getPortInfo() {
    return this.serialPort?.getInfo();
  }

  //#region event handlers
  private eventCallbacks: {
    "connected": Array<() => void>;
    "disconnected": Array<() => void>;
  } = {
    "connected": [],
    "disconnected": [],
  };

  on(eventname: "connected", callback: () => void): void;
  on(eventname: "disconnected", callback: () => void): void;

  on(eventName: EventNames, callback: any) {
    if (this.eventCallbacks[eventName].indexOf(callback) >= 0) {
      return;
    }
    this.eventCallbacks[eventName].push(callback);
  }

  off(eventname: "connected", callback: () => void): void;
  off(eventname: "disconnected", callback: () => void): void;

  off(eventName: EventNames, callback: any) {
    const i = this.eventCallbacks[eventName].indexOf(callback);
    if (i < 0) {
      log.warn("Unknown callback.");
      return;
    }
    this.eventCallbacks[eventName].splice(i, 1);
  }

  private fireEvent(eventname: "connected"): void;
  private fireEvent(eventname: "disconnected"): void;

  private fireEvent(eventName: EventNames, ...args: any[]) {
    if (this.eventCallbacks[eventName]) {
      this.eventCallbacks[eventName].forEach((callback: any) => {
        callback(...args);
      });
    }
  }

  listEventListeners(eventName: EventNames) {
    log.debug(this.eventCallbacks[eventName]);
  }

  // private onConnect(event: Event) {
  //   log.debug("serial port connected", this);
  //   this.fireEvent("connected");
  // }

  private onDisconnect(event: Event) {
    log.debug("serial port disconnected", this);
    this.serialPort = null;
    this.fireEvent("disconnected");
  }
  //#endregion event handlers

}
