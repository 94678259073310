export class WebSerialUnsupportedError extends Error {
  constructor(...params: any) {
    super(...params); // (1)
    this.name = "WebSerialUnsupportedError"; // (2)
  }
}

export class NoBrainConnectedError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "NoBrainConnectedError";
  }
}

export class NoControllerConnectedError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "NoControllerConnectedError";
  }
}

export class NoAdminPortConnectedError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "NoAdminPortConnectedError";
  }
}

export class NoUserPortConnectedError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "NoUserPortConnectedError";
  }
}

export class OperationNotSupportedError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "OperationNotSupportedError";
  }
}

export class NoPythonVMError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "NoPythonVMError";
  }
}

export class UpdateCanceled extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "UpdateCanceled";
  }
}

export class ErrorUpdatingBrainGolden extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "ErrorUpdatingBrainGolden";
  }
}

export class ErrorUpdatingBrainBoot extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "ErrorUpdatingBrainBoot";
  }
}

export class ErrorUpdatingBrainAssets extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "ErrorUpdatingBrainAssets";
  }
}

export class ErrorWriteResponseTimeout extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = "ErrorWriteResponseTimeout";
  }
}
