import * as logger from "@rm-vca/logger";
import { DFUNonVexDeviceError } from "./errors";
const log = logger.getLogger("VexDFUDevice");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

interface IDescriptor { 
  bLength: number;
  bDescriptorType: number;
}

interface IDeviceDescriptor extends IDescriptor {
  bcdUSB: number;
  bDeviceClass: number;
  bDeviceSubClass: number;
  bDeviceProtocol: number;
  bMaxPacketSize: number;
  idVendor: number;
  idProduct: number;
  bcdDevice: number;
  iManufacturer: number;
  iProduct: number;
  iSerialNumber: number;
  bNumConfigurations: number;
}

interface IInterfaceDescriptor extends IDescriptor {
  bInterfaceNumber: number;
  bAlternateSetting: number;
  bNumEndpoints: number;
  bInterfaceClass:number;
  bInterfaceSubClass: number;
  bInterfaceProtocol: number;
  iInterface: number;
  descriptors: SubDescriptor[];
}

interface IFunctionalDescriptor extends IDescriptor {
  bmAttributes: number;
  wDetachTimeOut: number;
  wTransferSize: number;
  bcdDFUVersion: number;
}

interface IOtherSubDescriptor extends IDescriptor {
  data: DataView;
}

type SubDescriptor = IInterfaceDescriptor | IFunctionalDescriptor | IOtherSubDescriptor;

interface IConfigurationDescriptor extends IDescriptor {
  bLength: number;
  bDescriptorType: number;
  wTotalLength: number;
  bNumInterfaces: number;
  bConfigurationValue: number;
  iConfiguration: number;
  bmAttributes: number;
  bMaxPower: number;
  descriptors: SubDescriptor[]
}

interface IBasicInterface {
  configuration: USBConfiguration;
  interface: USBInterface;
  alternate: USBAlternateInterface;
  name: string;
}


class VexDFUDevice {
  //#region static constants
  public static readonly DETACH = 0x00;
  public static readonly DNLOAD = 0x01;
  public static readonly UPLOAD = 0x02;
  public static readonly GETSTATUS = 0x03;
  public static readonly CLRSTATUS = 0x04;
  public static readonly GETSTATE = 0x05;
  public static readonly ABORT = 6;

  public static readonly appIDLE = 0;
  public static readonly appDETACH = 1;
  public static readonly dfuIDLE = 2;
  public static readonly dfuDNLOAD_SYNC = 3;
  public static readonly dfuDNBUSY = 4;
  public static readonly dfuDNLOAD_IDLE = 5;
  public static readonly dfuMANIFEST_SYNC = 6;
  public static readonly dfuMANIFEST = 7;
  public static readonly dfuMANIFEST_WAIT_RESET = 8;
  public static readonly dfuUPLOAD_IDLE = 9;
  public static readonly dfuERROR = 10;

  public static readonly STATUS_OK = 0x0;
  //#endregion static constants

  //#region static functions
  static findDeviceDfuInterfaces(device: USBDevice): IBasicInterface[] {
    let interfaces = [];
    for (let conf of device.configurations) {
      for (let intf of conf.interfaces) {
        for (let alt of intf.alternates) {
          if (alt.interfaceClass == 0xFE &&
            alt.interfaceSubclass == 0x01 &&
            (alt.interfaceProtocol == 0x01 || alt.interfaceProtocol == 0x02)) {
            let settings = {
              "configuration": conf,
              "interface": intf,
              "alternate": alt,
              "name": alt.interfaceName
            };
            interfaces.push(settings);
          }
        }
      }
    }

    return interfaces;
  }

  static async findAllDfuInterfaces() {
    const devices = await navigator.usb.getDevices();
    let matches = [];
    for (let device of devices) {
      let interfaces = VexDFUDevice.findDeviceDfuInterfaces(device);
      for (let interface_ of interfaces) {
        matches.push(new VexDFUDevice(device, interface_))
      }
    }
    return matches;
  };

  static parseDeviceDescriptor = function (data: DataView) {
    return {
      bLength: data.getUint8(0),
      bDescriptorType: data.getUint8(1),
      bcdUSB: data.getUint16(2, true),
      bDeviceClass: data.getUint8(4),
      bDeviceSubClass: data.getUint8(5),
      bDeviceProtocol: data.getUint8(6),
      bMaxPacketSize: data.getUint8(7),
      idVendor: data.getUint16(8, true),
      idProduct: data.getUint16(10, true),
      bcdDevice: data.getUint16(12, true),
      iManufacturer: data.getUint8(14),
      iProduct: data.getUint8(15),
      iSerialNumber: data.getUint8(16),
      bNumConfigurations: data.getUint8(17),
    };
  };

  static parseConfigurationDescriptor(data: DataView): IConfigurationDescriptor {
    let descriptorData = new DataView(data.buffer.slice(9));
    let descriptors = VexDFUDevice.parseSubDescriptors(descriptorData);
    return {
      bLength: data.getUint8(0),
      bDescriptorType: data.getUint8(1),
      wTotalLength: data.getUint16(2, true),
      bNumInterfaces: data.getUint8(4),
      bConfigurationValue: data.getUint8(5),
      iConfiguration: data.getUint8(6),
      bmAttributes: data.getUint8(7),
      bMaxPower: data.getUint8(8),
      descriptors: descriptors
    };
  };

  static parseInterfaceDescriptor(data: DataView): IInterfaceDescriptor {
    return {
      bLength: data.getUint8(0),
      bDescriptorType: data.getUint8(1),
      bInterfaceNumber: data.getUint8(2),
      bAlternateSetting: data.getUint8(3),
      bNumEndpoints: data.getUint8(4),
      bInterfaceClass: data.getUint8(5),
      bInterfaceSubClass: data.getUint8(6),
      bInterfaceProtocol: data.getUint8(7),
      iInterface: data.getUint8(8),
      descriptors: [] as any
    };
  };

  static parseFunctionalDescriptor(data: DataView): IFunctionalDescriptor {
    return {
      bLength: data.getUint8(0),
      bDescriptorType: data.getUint8(1),
      bmAttributes: data.getUint8(2),
      wDetachTimeOut: data.getUint16(3, true),
      wTransferSize: data.getUint16(5, true),
      bcdDFUVersion: data.getUint16(7, true)
    };
  };

  static parseSubDescriptors(descriptorData: DataView): SubDescriptor[] {
    const DT_INTERFACE = 4;
    const DT_ENDPOINT = 5;
    const DT_DFU_FUNCTIONAL = 0x21;
    const USB_CLASS_APP_SPECIFIC = 0xFE;
    const USB_SUBCLASS_DFU = 0x01;
    let remainingData = descriptorData;
    let descriptors = [];
    let currIntf: IInterfaceDescriptor;
    let inDfuIntf = false;
    while (remainingData.byteLength > 2) {
      let bLength = remainingData.getUint8(0);
      let bDescriptorType = remainingData.getUint8(1);
      let descData = new DataView(remainingData.buffer.slice(0, bLength));
      if (bDescriptorType == DT_INTERFACE) {
        currIntf = VexDFUDevice.parseInterfaceDescriptor(descData);
        if (currIntf.bInterfaceClass == USB_CLASS_APP_SPECIFIC &&
          currIntf.bInterfaceSubClass == USB_SUBCLASS_DFU) {
          inDfuIntf = true;
        } else {
          inDfuIntf = false;
        }
        descriptors.push(currIntf);
      } else if (inDfuIntf && bDescriptorType == DT_DFU_FUNCTIONAL) {
        let funcDesc = VexDFUDevice.parseFunctionalDescriptor(descData)
        descriptors.push(funcDesc);
        currIntf.descriptors.push(funcDesc);
      } else {
        let desc = {
          bLength: bLength,
          bDescriptorType: bDescriptorType,
          data: descData
        };
        descriptors.push(desc);
        if (currIntf) {
          currIntf.descriptors.push(desc);
        }
      }
      remainingData = new DataView(remainingData.buffer.slice(bLength));
    }

    return descriptors;
  };
  //#endregion static functions

  public device_: USBDevice;
  public settings: any;
  public interfaceNumber: number;

  public disconnected: boolean;

  constructor(device: USBDevice, settings: any) {
    if (device.manufacturerName !== "VEX Robotics Inc.") {
      log.error("selected device is not a VEX device");
      throw new DFUNonVexDeviceError();
    }
    this.device_ = device;
    this.settings = settings;
    this.interfaceNumber = settings["interface"].interfaceNumber;
  }

  logProgress(done: any, total?: any) {
    // if (typeof total === 'undefined') {
    //   log.debug(done)
    // } else {
    //   log.debug(done + '/' + total);
    // }
  }

  async open() {
    await this.device_.open();
    const confValue = this.settings.configuration.configurationValue;
    if (this.device_.configuration === null ||
      this.device_.configuration.configurationValue != confValue) {
      await this.device_.selectConfiguration(confValue);
    }

    const intfNumber = this.settings["interface"].interfaceNumber;
    if (!this.device_.configuration.interfaces[intfNumber].claimed) {
      await this.device_.claimInterface(intfNumber);
    }

    const altSetting = this.settings.alternate.alternateSetting;
    let intf = this.device_.configuration.interfaces[intfNumber];
    if (intf.alternate === null ||
      intf.alternate.alternateSetting != altSetting ||
      intf.alternates.length > 1) {
      try {
        await this.device_.selectAlternateInterface(intfNumber, altSetting);
      } catch (error) {
        if (intf.alternate.alternateSetting == altSetting &&
          error.endsWith("Unable to set device interface.")
        ) {
          log.warn(`Redundant SET_INTERFACE request to select altSetting ${altSetting} failed`);
        } else {
          throw error;
        }
      }
    }
  }

  async close() {
    try {
      await this.device_.close();
    } catch (error) {
      log.warn(error);
    }
  }

  readDeviceDescriptor() {
    const GET_DESCRIPTOR = 0x06;
    const DT_DEVICE = 0x01;
    const wValue = (DT_DEVICE << 8);

    return this.device_.controlTransferIn({
      "requestType": "standard",
      "recipient": "device",
      "request": GET_DESCRIPTOR,
      "value": wValue,
      "index": 0
    }, 18).then(
      result => {
        if (result.status == "ok") {
          return Promise.resolve(result.data);
        } else {
          return Promise.reject(result.status);
        }
      }
    );
  }

  async readStringDescriptor(index: number, langID: number): Promise<string | number[]> {
    if (typeof langID === 'undefined') {
      langID = 0;
    }

    const GET_DESCRIPTOR = 0x06;
    const DT_STRING = 0x03;
    const wValue = (DT_STRING << 8) | index;

    const request_setup: USBControlTransferParameters = {
      "requestType": "standard",
      "recipient": "device",
      "request": GET_DESCRIPTOR,
      "value": wValue,
      "index": langID
    }

    // Read enough for bLength
    var result = await this.device_.controlTransferIn(request_setup, 1);

    if (result.status == "ok") {
      // Retrieve the full descriptor
      const bLength = result.data.getUint8(0);
      result = await this.device_.controlTransferIn(request_setup, bLength);
      if (result.status == "ok") {
        const len = (bLength - 2) / 2;
        let u16_words = [];
        for (let i = 0; i < len; i++) {
          u16_words.push(result.data.getUint16(2 + i * 2, true));
        }
        if (langID == 0) {
          // Return the langID array
          return u16_words;
        } else {
          // Decode from UCS-2 into a string
          return String.fromCharCode.apply(String, u16_words);
        }
      }
    }

    throw `Failed to read string descriptor ${index}: ${result.status}`;
  }

  async readInterfaceNames() {
    const DT_INTERFACE = 4;

    let configs: any = {};
    let allStringIndices = new Set<number>();
    for (let configIndex = 0; configIndex < this.device_.configurations.length; configIndex++) {
      const rawConfig = await this.readConfigurationDescriptor(configIndex);
      let configDesc = VexDFUDevice.parseConfigurationDescriptor(rawConfig);
      let configValue = configDesc.bConfigurationValue;
      configs[configValue] = {};

      // Retrieve string indices for interface names
      for (let desc of configDesc.descriptors) {
        if (desc.bDescriptorType == DT_INTERFACE) {
          const idesc = desc as IInterfaceDescriptor;
          if (!(idesc.bInterfaceNumber in configs[configValue])) {
            configs[configValue][idesc.bInterfaceNumber] = {};
          }
          configs[configValue][idesc.bInterfaceNumber][idesc.bAlternateSetting] = idesc.iInterface;
          if (idesc.iInterface > 0) {
            allStringIndices.add(idesc.iInterface);
          }
        }
      }
    }

    let strings: { [key: number]: string | number[] } = {};
    // Retrieve interface name strings
    for (let index of allStringIndices) {
      try {
        strings[index] = await this.readStringDescriptor(index, 0x0409);
      } catch (error) {
        console.log(error);
        strings[index] = null;
      }
    }

    for (let configValue in configs) {
      for (let intfNumber in configs[configValue]) {
        for (let alt in configs[configValue][intfNumber]) {
          const iIndex = configs[configValue][intfNumber][alt];
          configs[configValue][intfNumber][alt] = strings[iIndex];
        }
      }
    }

    return configs;
  }

  readConfigurationDescriptor(index: number) {
    const GET_DESCRIPTOR = 0x06;
    const DT_CONFIGURATION = 0x02;
    const wValue = ((DT_CONFIGURATION << 8) | index);

    return this.device_.controlTransferIn({
      "requestType": "standard",
      "recipient": "device",
      "request": GET_DESCRIPTOR,
      "value": wValue,
      "index": 0
    }, 4).then(
      result => {
        if (result.status == "ok") {
          // Read out length of the configuration descriptor
          let wLength = result.data.getUint16(2, true);
          return this.device_.controlTransferIn({
            "requestType": "standard",
            "recipient": "device",
            "request": GET_DESCRIPTOR,
            "value": wValue,
            "index": 0
          }, wLength);
        } else {
          return Promise.reject(result.status);
        }
      }
    ).then(
      result => {
        if (result.status == "ok") {
          return Promise.resolve(result.data);
        } else {
          return Promise.reject(result.status);
        }
      }
    );
  }

  requestOut(bRequest: number, data?: BufferSource, wValue = 0) {
    return this.device_.controlTransferOut({
      "requestType": "class",
      "recipient": "interface",
      "request": bRequest,
      "value": wValue,
      "index": this.interfaceNumber
    }, data).then(
      result => {
        if (result.status == "ok") {
          return Promise.resolve(result.bytesWritten);
        } else {
          return Promise.reject(result.status);
        }
      },
      error => {
        return Promise.reject("ControlTransferOut failed: " + error);
      }
    );
  };

  requestIn(bRequest: number, wLength: number, wValue = 0) {
    return this.device_.controlTransferIn({
      "requestType": "class",
      "recipient": "interface",
      "request": bRequest,
      "value": wValue,
      "index": this.interfaceNumber
    }, wLength).then(
      result => {
        if (result.status == "ok") {
          return Promise.resolve(result.data);
        } else {
          return Promise.reject(result.status);
        }
      },
      error => {
        return Promise.reject("ControlTransferIn failed: " + error);
      }
    );
  };

  detach() {
    return this.requestOut(VexDFUDevice.DETACH, undefined, 1000);
  }

  async waitDisconnected(timeout: number) {
    let device = this;
    let usbDevice = this.device_;
    return new Promise(function (resolve, reject) {
      let timeoutID: ReturnType<typeof setTimeout>;
      if (timeout > 0) {
        function onTimeout() {
          navigator.usb.removeEventListener("disconnect", onDisconnect);
          if (device.disconnected !== true) {
            reject("Disconnect timeout expired");
          }
        }
        timeoutID = setTimeout(reject, timeout);
      }

      function onDisconnect(event: USBConnectionEvent) {
        if (event.device === usbDevice) {
          if (timeout > 0) {
            clearTimeout(timeoutID);
          }
          device.disconnected = true;
          navigator.usb.removeEventListener("disconnect", onDisconnect);
          event.stopPropagation();
          resolve(device);
        }
      }

      navigator.usb.addEventListener("disconnect", onDisconnect);
    });
  };

  download(data: BufferSource, blockNum: number) {
    return this.requestOut(VexDFUDevice.DNLOAD, data, blockNum);
  };

  dnload = this.download;

  upload(length: number, blockNum: number) {
    return this.requestIn(VexDFUDevice.UPLOAD, length, blockNum)
  };

  clearStatus() {
    return this.requestOut(VexDFUDevice.CLRSTATUS);
  };

  clrStatus = this.clearStatus;

  getStatus() {
    return this.requestIn(VexDFUDevice.GETSTATUS, 6).then(
      data =>
        Promise.resolve({
          "status": data.getUint8(0),
          "pollTimeout": data.getUint32(1, true) & 0xFFFFFF,
          "state": data.getUint8(4)
        }),
      error =>
        Promise.reject("DFU GETSTATUS failed: " + error)
    );
  };

  getState() {
    return this.requestIn(VexDFUDevice.GETSTATE, 1)
      .then(
        data => Promise.resolve(data.getUint8(0)),
        error => Promise.reject("DFU GETSTATE failed: " + error)
      );
  };

  abort() {
    return this.requestOut(VexDFUDevice.ABORT);
  };

  async abortToIdle() {
    await this.abort();
    let state = await this.getState();
    if (state == VexDFUDevice.dfuERROR) {
      await this.clearStatus();
      state = await this.getState();
    }
    if (state != VexDFUDevice.dfuIDLE) {
      throw "Failed to return to idle state after abort: state " + state;
    }
  };

  async do_upload(xfer_size: number, max_size = Infinity, first_block = 0) {
    let transaction = first_block;
    let blocks = [];
    let bytes_read = 0;

    log.info("Copying data from DFU device to browser");
    // Initialize progress to 0
    this.logProgress(0);

    let result;
    let bytes_to_read;
    do {
      bytes_to_read = Math.min(xfer_size, max_size - bytes_read);
      result = await this.upload(bytes_to_read, transaction++);
      log.debug("Read " + result.byteLength + " bytes");
      if (result.byteLength > 0) {
        blocks.push(result);
        bytes_read += result.byteLength;
      }
      if (Number.isFinite(max_size)) {
        this.logProgress(bytes_read, max_size);
      } else {
        this.logProgress(bytes_read);
      }
    } while ((bytes_read < max_size) && (result.byteLength == bytes_to_read));

    if (bytes_read == max_size) {
      await this.abortToIdle();
    }

    log.info("Read", bytes_read, "bytes");

    return new Blob(blocks, { type: "application/octet-stream" });
  };

  async poll_until(state_predicate: (state: number) => boolean) {
    let dfu_status = await this.getStatus();

    let device = this;
    function async_sleep(duration_ms: number) {
      return new Promise(function (resolve, reject) {
        log.debug("Sleeping for", duration_ms, "ms");
        setTimeout(resolve, duration_ms);
      });
    }

    while (!state_predicate(dfu_status.state) && dfu_status.state != VexDFUDevice.dfuERROR) {
      await async_sleep(dfu_status.pollTimeout);
      dfu_status = await this.getStatus();
    }

    return dfu_status;
  };

  poll_until_idle(idle_state: number) {
    return this.poll_until(state => (state == idle_state));
  };

  async do_download(xfer_size: number, data: ArrayBuffer, manifestationTolerant: boolean, progress?: (progress: number) => void) {
    let bytes_sent = 0;
    let expected_size = data.byteLength;
    let transaction = 0;

    log.info("Copying data from browser to DFU device");

    // Initialize progress to 0
    this.logProgress(bytes_sent, expected_size);

    while (bytes_sent < expected_size) {
      const bytes_left = expected_size - bytes_sent;
      const chunk_size = Math.min(bytes_left, xfer_size);

      let bytes_written = 0;
      let dfu_status;
      try {
        bytes_written = await this.download(data.slice(bytes_sent, bytes_sent + chunk_size), transaction++);
        // log.debug("Sent", bytes_written, "bytes");
        dfu_status = await this.poll_until_idle(VexDFUDevice.dfuDNLOAD_IDLE);
      } catch (error) {
        throw "Error during DFU download: " + error;
      }

      if (dfu_status.status != VexDFUDevice.STATUS_OK) {
        throw `DFU DOWNLOAD failed state=${dfu_status.state}, status=${dfu_status.status}`;
      }

      log.debug("Wrote", bytes_written, "bytes");
      bytes_sent += bytes_written;

      if (progress) {
        progress(bytes_sent / expected_size);
      }
      this.logProgress(bytes_sent, expected_size);
    }

    log.debug("Sending empty block");
    try {
      await this.download(new ArrayBuffer(0), transaction++);
    } catch (error) {
      throw "Error during final DFU download: " + error;
    }

    log.info("Wrote", bytes_sent, "bytes");
    log.info("Manifesting new firmware");

    if (manifestationTolerant) {
      // Transition to MANIFEST_SYNC state
      let dfu_status;
      try {
        // Wait until it returns to idle.
        // If it's not really manifestation tolerant, it might transition to MANIFEST_WAIT_RESET
        dfu_status = await this.poll_until(state => (state == VexDFUDevice.dfuIDLE || state == VexDFUDevice.dfuMANIFEST_WAIT_RESET));
        if (dfu_status.state == VexDFUDevice.dfuMANIFEST_WAIT_RESET) {
          log.debug("Device transitioned to MANIFEST_WAIT_RESET even though it is manifestation tolerant");
        }
        if (dfu_status.status != VexDFUDevice.STATUS_OK) {
          throw `DFU MANIFEST failed state=${dfu_status.state}, status=${dfu_status.status}`;
        }
      } catch (error) {
        if (
          error.endsWith("ControlTransferIn failed: NotFoundError: Device unavailable.") ||
          error.endsWith("The device was disconnected.") ||
          error.endsWith("A transfer error has occurred.") ||
          error.toString().endsWith("Unable to reset the device.")
        ) {
          log.warn("Unable to poll final manifestation status");
        } else {
          throw "Error during DFU manifest: " + error;
        }
      }
    } else {
      // Try polling once to initiate manifestation
      try {
        let final_status = await this.getStatus();
        log.debug(`Final DFU status: state=${final_status.state}, status=${final_status.status}`);
      } catch (error) {
        log.debug("Manifest GET_STATUS poll error: " + error);
      }
    }

    // Reset to exit MANIFEST_WAIT_RESET
    // try {
    //   await this.device_.reset();
    // } catch (error) {
    //   if (
    //     error.toString().endsWith("Unable to reset the device.") ||
    //     error.toString().endsWith("Device unavailable.") ||
    //     error.toString().endsWith("The device was disconnected.")
    //   ) {
    //     log.debug("Ignored reset error");
    //   } else {
    //     throw "Error during reset for manifestation: " + error;
    //   }
    // }

    return;
  };



}

export {
  VexDFUDevice,
}

export type {
  IDeviceDescriptor,
  IInterfaceDescriptor,
  IFunctionalDescriptor,
  IConfigurationDescriptor,
  SubDescriptor,
  IBasicInterface,
}
