import * as logger from "@rm-vca/logger";
const log = logger.getLogger("cc264x");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

// log.setHistoryLogger("WebSerial");

import * as crcgen from "../VexCRC";

type WriteDataFunc = (data: Uint8Array, callback?: (reply: ArrayBuffer) => void) => void;

// yep, more weird looking javascript as it's ported from C code.
// support for 264x radio bootload
//
export class cc264xdfu {
  static cc2640_cmds = {
    kBootCmdPing: 0x20,
    kBootCmdDownload: 0x21,
    kBootCmdStatus: 0x23,
    kBootCmdSendData: 0x24,
    kBootCmdReset: 0x25,
    kBootCmdSectorErase: 0x26,
    kBootCmdCrc32: 0x27,
    kBootCmdGetChipId: 0x28,
    kBootCmdMemoryRead: 0x2A,
    kBootCmdMemoryWrite: 0x2B,
    kBootCmdBankErase: 0x2C,
    kBootCmdSetCCFG: 0x2D,
  }

  static cc2640_status = {
    kBootStatusSuccess: 0x40,
    kBootStatusUnknownCmd: 0x41,
    kBootStatusInvalidCmd: 0x42,
    kBootStatusInvalidAddr: 0x43,
    kBootStatusFlashFail: 0x44
  };

  static cc2640_access = {
    kBootReadAccess8b: 0,
    kBootReadAccess32b: 1
  };

  static cc2640_errors = {
    COMMS_ERROR: -1,
    ACK_ERROR: -2,
    RESPONSE_ERROR: -3,
    FLASH_ERASE_ERROR: -4,
    FLASH_WRITE_ERROR: -5,
    FLASH_ADDRESS_ERROR: -6,
    FLASH_FIRMWARE_ERROR: -7,
    FLASH_CHECKSUM_ERROR: -8
  }

  static CC2640_FLASH_SECTOR: number = 4096
  static CC2640_FLASH_SIZE_CFG: number = 0x4003002C
  static CC2640_RAM_SIZE_CFG: number = 0x40082250
  static CC2640_CHUNK_SIZE: number = 128
  static CC2640_PROG_SECTORS: number = 29
  static CC2640_PROG_SECTORS_F: number = 32
  static CC2640_DEBUG_ID: string = 'cc2640:';
  static CC2640_CHIP_ID: number = 11;

  static CC2642_FLASH_SECTOR: number = 8192
  static CC2642_PROG_SECTORS: number = 32
  static CC2642_CODE_IDENT: number = 0x20013c00
  static CC2642_PROG_SECTORS_F: number = 44
  static CC2642_DEBUG_ID: string = 'cc2640:';
  static CC2642_CHIP_ID: number = 3;

  // callback to send data over the web serial port
  private writeData: WriteDataFunc = undefined;

  private commsState: number = 0;

  private currentAddress: number = 0;
  private downloadAddress: number = 0;
  private downloadSize: number;
  private radioCrc: number;
  private calculatedCrc: number;

  private bFullRadioProgram: boolean = false;
  private downloadFirmwareData: Uint8Array = undefined;

  private bStartUpdate: boolean;
  private bAckReceived: boolean;
  private bNakReceived: boolean;
  private bResponseReceived: boolean;
  private bEraseOnly: boolean;

  private flashSize: number;
  private ramSize: number;
  private flashPageSize: number;
  private flashProgramPageCount: number;
  private chipId: number;
  private codeId: number;

  // fields for a command
  private cmd: number;
  private address: number;
  private size: number;
  private pData: Uint8Array;
  private dataLen: number;
  private bResponse: boolean;

  // response data
  private replyData: Uint8Array = new Uint8Array(130);
  private replyBytes: number = 0;;

  private replyTimeout: ReturnType<typeof setTimeout>;

  constructor(handler: WriteDataFunc, fimwware: Uint8Array, chip_id?: number) {
    this.downloadAddress = 0;
    this.downloadSize = cc264xdfu.CC2640_FLASH_SECTOR * cc264xdfu.CC2640_PROG_SECTORS;

    // These may get changed when we know the chip id
    this.flashPageSize = cc264xdfu.CC2640_FLASH_SECTOR;
    this.flashProgramPageCount = cc264xdfu.CC2640_PROG_SECTORS;

    // program all radio, inc config data
    if (this.bFullRadioProgram && chip_id) {
      if (chip_id == cc264xdfu.CC2640_CHIP_ID) {
        // change program size to complete radio flash
        this.flashProgramPageCount = cc264xdfu.CC2640_PROG_SECTORS_F;
        this.downloadSize = cc264xdfu.CC2640_FLASH_SECTOR * cc264xdfu.CC2640_PROG_SECTORS_F;
      }
      if (chip_id == cc264xdfu.CC2642_CHIP_ID) {
        // change program size to complete radio flash
        this.flashProgramPageCount = cc264xdfu.CC2642_PROG_SECTORS_F;
        this.downloadSize = cc264xdfu.CC2642_FLASH_SECTOR * cc264xdfu.CC2642_PROG_SECTORS_F;
      }
    }

    if (fimwware)
      this.downloadFirmwareData = fimwware;

    // transmit handler
    this.writeData = handler;
  }

  setFirmware(fw: Uint8Array) {
    this.downloadFirmwareData = fw;
  }

  // TODO: move to a helper as this is use in several places
  /**
   * Utility function to create a hex string from the given number
   * @param  (number} value the number to be formatted into a string with %02X format
   * @return {string}
   */
  private hex2(value: number): string {
    var str = ('00' + value.toString(16)).substr(-2, 2);
    return (str.toUpperCase());
  }

  // TODO: move to a helper as this is use in several places
  /**
   * Utility function to create a hex string from the given number
   * @param  (number} value the number to be formatted into a string with %08X format
   * @return {string}
   */
  private hex8(value: number): string {
    var str = ('00000000' + value.toString(16)).substr(-8, 8);
    return (str.toUpperCase());
  }

  private delay(ms: number, payload?: any): Promise<any> {
    return new Promise(function (resolve) {
      setTimeout(() => { resolve(payload); }, ms);
    });
  }

  /*----------------------------------------------------------------------------*/
  // comms interface code
  // 
  private stopTimeout() {
    if (this.replyTimeout) {
      clearTimeout(this.replyTimeout);
      this.replyTimeout = undefined;
    }
  }

  private async sendCommand(data: Uint8Array, timeout?: boolean) {
    timeout = (timeout === undefined) ? true : timeout;

    this.writeData(data, (data) => { this.receiveData(data); })

    return new Promise((resolve, reject) => {
      let polltimer = setInterval(() => {
        if (this.bResponse) {
          if (this.bResponseReceived) {
            clearInterval(polltimer);
            this.stopTimeout()
            resolve(undefined);
          }
        }
        else {
          if (this.bAckReceived || this.bNakReceived) {
            clearInterval(polltimer);
            this.stopTimeout()
            resolve(undefined);
          }
        }
      }, 2);

      if (timeout && timeout === true) {
        this.replyTimeout = setTimeout(() => {
          this.replyTimeout = undefined;
          log.debug("timeout");
          clearInterval(polltimer);
          reject();
        }, 1000);
      }
    });
  }

  private receiveData(data: ArrayBuffer) {
    let udata = new Uint8Array(data);

    for (let i = 0; i < udata.byteLength; i++) {
      this.radioBootloaderCommsReply(udata[i]);
    }
  }

  /*----------------------------------------------------------------------------*/

  private async radioSendBootloadCommand() {
    let cmd = new Uint8Array(256);
    let index = 2;

    if (this.cmd != 0) {
      cmd[index++] = this.cmd;
    }
    if (this.address != 0xFFFFFFFF) {
      cmd[index++] = (this.address >> 24) & 0xFF;
      cmd[index++] = (this.address >> 16) & 0xFF;
      cmd[index++] = (this.address >> 8) & 0xFF;
      cmd[index++] = (this.address) & 0xFF;
    }
    if (this.size != 0xFFFFFFFF) {
      cmd[index++] = (this.size >> 24) & 0xFF;
      cmd[index++] = (this.size >> 16) & 0xFF;
      cmd[index++] = (this.size >> 8) & 0xFF;
      cmd[index++] = (this.size) & 0xFF;
    }
    if (this.pData != undefined && this.dataLen > 0) {
      for (let i = 0; i < this.dataLen && index < 256; i++) {
        cmd[index++] = this.pData[i];
      }
    }

    let cs = 0;
    for (let i = 2; i < index; i++) {
      cs += cmd[i];
    }

    cmd[1] = cs & 0xFF;
    cmd[0] = index;

    this.commsState = 0;
    this.bAckReceived = false;
    this.bNakReceived = false;

    await this.sendCommand(new Uint8Array(cmd.subarray(0, index)));
  }


  private radioBootloadSendAck() {
    let cmd: Uint8Array = new Uint8Array([0x00, 0xCC]);
    // No reply to ACK, just send
    this.writeData(cmd, undefined);
  }

  private radioBootloadSendNak() {
    let cmd: Uint8Array = new Uint8Array([0x00, 0x33]);
    // No reply to NAK, just send
    this.writeData(cmd, undefined);
  }

  private radioBootloadValidateChecksum() {
    let cs = 0;
    for (let i = 2; i < this.replyData[0]; i++) {
      cs += this.replyData[i];
    }

    if (cs == this.replyData[1])
      return true;
    else
      return false;
  }

  private radioBootloadClearCommand() {
    this.cmd = 0;
    this.address = 0xFFFFFFFF;
    this.size = 0xFFFFFFFF;
    this.pData = undefined;
    this.dataLen = 0;

    this.bResponse = false;
    this.bResponseReceived = false;
  }

  /*----------------------------------------------------------------------------*/

  private async radioBootloadSendHello() {
    let cmd: Uint8Array = new Uint8Array([0x55, 0x55]);
    await this.sendCommand(cmd);
  }

  private async radioSendBootloaderPing() {
    this.radioBootloadClearCommand();
    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdPing;
    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderGetStatus() {
    this.radioBootloadClearCommand();
    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdStatus;
    this.bResponse = true;
    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderGetChipId() {
    this.radioBootloadClearCommand();
    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdGetChipId;
    this.bResponse = true;
    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderGetChecksum(address: number, length: number) {
    let data: Uint8Array = new Uint8Array([0, 0, 0, 0]);

    this.radioBootloadClearCommand();
    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdCrc32;

    this.address = address;
    this.size = length;
    this.pData = data;
    this.dataLen = data.byteLength;

    this.bResponse = true;
    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderReadMemory32(address: number, words: number) {
    let data: Uint8Array = new Uint8Array([cc264xdfu.cc2640_access.kBootReadAccess32b, words]);

    this.radioBootloadClearCommand();
    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdMemoryRead;
    this.address = address;
    this.pData = data;
    this.dataLen = data.byteLength;

    this.bResponse = true;
    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderEraseBlock(address: number) {
    this.radioBootloadClearCommand();

    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdSectorErase;
    this.address = address;

    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderDownload(address: number, length: number) {
    this.radioBootloadClearCommand();

    // length must be mulitple of 4
    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdDownload;
    this.address = address;
    this.size = length;

    await this.radioSendBootloadCommand();
  }

  private async radioSendBootloaderWriteData(pData: Uint8Array, dataLen: number) {
    this.radioBootloadClearCommand();

    this.cmd = cc264xdfu.cc2640_cmds.kBootCmdSendData;
    this.pData = pData;
    this.dataLen = dataLen;

    await this.radioSendBootloadCommand();
  }

  /*----------------------------------------------------------------------------*/

  private radioCrc32Gen(pData: Uint8Array, length: number) {
    // No hardware CRC available
    // we needed reversed input
    // reversed and complemented output
    let crc = crcgen.crc32reflectedInput(pData.subarray(0, length), 0xFFFFFFFF);
    crc = (crcgen.crc32Reflect32(crc) ^ 0xFFFFFFFF) >>> 0;

    return crc;
  }

  private radioBootloaderCommsReply(data: number) {
    switch (this.commsState) {
      case 0:
        // first byte of reply should be 0x00
        if (data == 0x00)
          this.commsState = 1;
        break;
      case 1:
        // second byte of reply should be 0xCC (ACK) or 0x33(NAK)
        if (data == 0xCC) {
          this.bAckReceived = true;
          if (this.bResponse)
            this.commsState = 2;
          else
            this.commsState = 0;
        }
        if (data == 0x33) {
          this.bNakReceived = true;
          this.commsState = 0;
        }
        break;

      case 2:
        // first byte of response/status data
        // skip any 0x00
        if (data != 0) {
          this.replyData[0] = data;
          this.replyBytes = 1;
          // enough space ?
          if (data < this.replyData.byteLength)
            this.commsState++;
          else
            this.commsState = 0;
        }
        break;

      case 3:
        // more bytes of response/status data
        if (this.replyBytes < this.replyData[0]) {
          this.replyData[this.replyBytes++] = data;

          // final byte ?
          if (this.replyBytes == this.replyData[0]) {
            // all response received
            if (this.radioBootloadValidateChecksum()) {
              this.radioBootloadSendAck();
            }
            else {
              this.radioBootloadSendNak();
            }
            this.bResponseReceived = true;
            this.commsState = 0;
          }
        }
        else {
          this.commsState = 0;
        }
        break;

      default:
        this.commsState = 0;
        break;
    }
  }

  private radioResponseToWordBE(pData: Uint8Array, offset: number) {
    let dvb = new DataView(pData.buffer, pData.byteOffset + offset);
    return dvb.getUint32(0, false);
  }
  private radioResponseToWordLE(pData: Uint8Array, offset: number) {
    let dvb = new DataView(pData.buffer, pData.byteOffset + offset);
    return dvb.getUint32(0, true);
  }

  /*----------------------------------------------------------------------------*/
  // High level interface
  //
  async radioBootloaderSendhello() {
    try {
      await this.radioBootloadSendHello();
    }
    catch (err) {
      log.error(err);
      throw (cc264xdfu.cc2640_errors.COMMS_ERROR);
    }

    return (0)
  }

  async radioBootloaderGetChipId() {
    try {
      await this.radioSendBootloaderGetChipId();
      if (this.bResponseReceived) {
        this.chipId = this.radioResponseToWordBE(this.replyData, 2) >>> 28;
        log.debug('Chip ID', this.hex8(this.radioResponseToWordBE(this.replyData, 2)), this.chipId);

        if (this.chipId == cc264xdfu.CC2642_CHIP_ID) {
          this.flashPageSize = cc264xdfu.CC2642_FLASH_SECTOR;
          this.flashProgramPageCount = cc264xdfu.CC2642_PROG_SECTORS;
          this.downloadSize = cc264xdfu.CC2642_FLASH_SECTOR * cc264xdfu.CC2642_PROG_SECTORS;
          log.debug('found exp controller');
        }
      }
      else {
        throw (cc264xdfu.cc2640_errors.RESPONSE_ERROR);
      }
    }
    catch (err) {
      log.error(err);
      throw (cc264xdfu.cc2640_errors.COMMS_ERROR);
    }

    return (this.chipId);
  }

  async radioBootloaderGetChecksum() {
    try {
      await this.radioSendBootloaderGetChecksum(this.downloadAddress, this.downloadSize)
      if (this.bResponseReceived) {
        this.radioCrc = this.radioResponseToWordBE(this.replyData, 2);
        this.calculatedCrc = this.radioCrc32Gen(this.downloadFirmwareData, this.downloadSize);

        log.debug("Radio CRC", this.hex8(this.radioCrc));
        log.debug("Cal   CRC", this.hex8(this.calculatedCrc));

        return (this.radioCrc == this.calculatedCrc);
      }
      else {
        throw (cc264xdfu.cc2640_errors.RESPONSE_ERROR);
      }

    }
    catch (err) {
      log.error(err);
      throw (cc264xdfu.cc2640_errors.COMMS_ERROR);
    }
  }

  async radioBootloaderGetChipMemorySizes() {
    try {
      this.flashSize = 0;

      // get flash size
      await this.radioSendBootloaderReadMemory32(cc264xdfu.CC2640_FLASH_SIZE_CFG, 1)
      if (this.bResponseReceived) {
        this.flashSize = (this.radioResponseToWordLE(this.replyData, 2) & 0xff) * this.flashPageSize;
        log.debug("Flash Size", this.hex8(this.flashSize));
      }

      // get ram size
      await this.radioSendBootloaderReadMemory32(cc264xdfu.CC2640_RAM_SIZE_CFG, 1);
      if (this.bResponseReceived) {
        let ramid = this.radioResponseToWordLE(this.replyData, 2) & 0x03;
        switch (ramid) {
          default: this.ramSize = 0x1000; break;
          case 1: this.ramSize = 0x2800; break;
          case 2: this.ramSize = 0x4000; break;
          case 3: this.ramSize = 0x5000; break;
        }

        log.debug("Ram Size", this.hex8(this.ramSize));
      }

      // see if chip is configured
      await this.radioSendBootloaderReadMemory32(this.flashSize - 0x58, 1);
      if (this.bResponseReceived) {
        let sig = this.radioResponseToWordLE(this.replyData, 2);
        log.debug("Flash Sig", this.hex8(sig));
        if (sig == 0xFFFFFFFF) {
          log.debug("needs full update");
        }
      }

      return (this.flashSize);
    }
    catch (err) {
      log.error(err);
      throw (cc264xdfu.cc2640_errors.COMMS_ERROR);
    }
  }

  // Erase block (one sector)
  async radioBootloaderEraseBlock() {
    // double check address
    if (this.currentAddress > (this.flashPageSize * this.flashProgramPageCount)) {
      throw (cc264xdfu.cc2640_errors.FLASH_ADDRESS_ERROR);
    }

    log.debug("flash erase", this.hex8(this.currentAddress));

    try {
      await this.radioSendBootloaderEraseBlock(this.currentAddress)
      if (this.bAckReceived) {
        await this.radioSendBootloaderGetStatus();
      }
      else {
        throw (cc264xdfu.cc2640_errors.ACK_ERROR);
      }

      if (this.bResponseReceived) {
        if (this.replyData[2] == cc264xdfu.cc2640_status.kBootStatusSuccess) {
          return (this.currentAddress)
        }
      }
      else {
        throw (cc264xdfu.cc2640_errors.RESPONSE_ERROR);
      }
    }
    catch (err) {
      throw (cc264xdfu.cc2640_errors.COMMS_ERROR);
    }
  }

  async radioBootloaderEraseFlash() {
    log.debug("flash erase");

    this.currentAddress = 0;

    while (this.currentAddress < (this.flashPageSize * this.flashProgramPageCount)) {
      try {
        await this.radioBootloaderEraseBlock();
        this.currentAddress += this.flashPageSize;
      }
      catch (err) {
        log.debug('flash erase error');
        throw (cc264xdfu.cc2640_errors.FLASH_ERASE_ERROR);
      }
    }
  }

  async radioBootloaderWriteFlash(progress?: (progress: number) => void) {
    log.debug("flash write", this.hex8(this.downloadAddress), this.hex8(this.downloadSize));

    this.currentAddress = this.downloadAddress;

    try {
      await this.radioSendBootloaderDownload(this.downloadAddress, this.downloadSize);

      if (this.bAckReceived) {
        await this.radioSendBootloaderGetStatus();
      }
      else {
        throw (cc264xdfu.cc2640_errors.ACK_ERROR);
      }

      let firmwareOffset = 0;

      while (this.currentAddress < (this.flashPageSize * this.flashProgramPageCount)) {
        let chunk = this.downloadFirmwareData.subarray(firmwareOffset, firmwareOffset + cc264xdfu.CC2640_CHUNK_SIZE);
        log.debug('addr:', this.hex8(this.currentAddress), this.hex2(chunk[0]), this.hex2(chunk[1]));
        await this.radioSendBootloaderWriteData(chunk, cc264xdfu.CC2640_CHUNK_SIZE);
        if (this.bAckReceived) {
          await this.radioSendBootloaderGetStatus();
        }
        else {
          throw (cc264xdfu.cc2640_errors.ACK_ERROR);
        }

        if (!this.bResponseReceived || (this.replyData[2] != cc264xdfu.cc2640_status.kBootStatusSuccess)) {
          throw (cc264xdfu.cc2640_errors.RESPONSE_ERROR);
        }
        this.currentAddress += cc264xdfu.CC2640_CHUNK_SIZE;
        firmwareOffset += cc264xdfu.CC2640_CHUNK_SIZE;

        if (progress) {
          let last_address = (this.flashPageSize * this.flashProgramPageCount)
          progress(this.currentAddress / last_address);
        }
      }

      log.debug('flash write done');
      return (0);
    }
    catch (err) {
      log.debug('flash write error');
      throw (cc264xdfu.cc2640_errors.FLASH_WRITE_ERROR);
    }
  }

  async radioUpdate(firmware: Uint8Array, progress?: (progress: number) => void) {
    try {
      this.setFirmware(firmware);

      await this.radioBootloaderSendhello();
      await this.delay(100);
      await this.radioBootloaderGetChipId();
      await this.radioBootloaderGetChipMemorySizes();
      await this.radioBootloaderEraseFlash();
      await this.radioBootloaderWriteFlash(progress);
      if (await this.radioBootloaderGetChecksum() != true) {
        throw (cc264xdfu.cc2640_errors.FLASH_CHECKSUM_ERROR);
      }
    }
    catch (err) {
      throw (err);
    }
  }
}
