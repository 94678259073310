import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexIQWebSerial");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

import {
  VexDeviceWebSerial,
  DownloadState,
  VEXBrainUpdateStates,
} from "./VexDeviceWebSerial";
import { VexFirmwareVersion } from "./VexFirmwareVersion";
import { VexFWIQ, VexFWIQ2 } from "./firmware/VexFW";

import type {
  IControllerConfigInfo,
  IProjectInformation,
  IPythonProgramLinkInfo,
  IPythonVMDownloadInfo,
  ProgressCallbackDownload,
  BrainUpdateProgressCallback,
  ConnectPromptCallback,
} from "./VexDeviceWebSerial";
import type { IV5ProjectInformation } from "./VexV5WebSerial";
import type { VEXWebSerialDeviceType } from "./DeviceType";
import { cc264xdfu } from "./radio/cc264x";
import { DFUTargetDevice } from "./dfu/VexDFU";
import { UpdateNeededOptions } from "./enums";
import { ErrorUpdatingBrainAssets, ErrorUpdatingBrainBoot, ErrorUpdatingBrainGolden } from "./errors";

interface IIQControllerConfigInfo extends IControllerConfigInfo {
  ButtonLUp: string;
  ButtonLDown: string;
  ButtonRUp: string;
  ButtonRDown: string;

  ButtonEUp: string;
  ButtonEDown: string;
  ButtonFUp: string;
  ButtonFDown: string;

  ButtonL3?: string;
  ButtonR3?: string;
}

interface IIQProjectInformation extends IProjectInformation {
  controller1: IIQControllerConfigInfo;
}


const USER_PROG_1             = 1;
const USER_PROG_2             = 2;
const USER_PROG_3             = 3;
const USER_PROG_4             = 4;

const USER_PROG_CHUNK_SIZE     = 240;          // chunk size (RC uses 240)
const USER_FLASH_START         = 0x00020000;   // start address of IQ flash
const USER_FLASH_END           = 0x00040000;   // end address of IQ flash
const USER_FLASH_BLOCK         = 1024;         // 1k blocks

const USER_FLASH_SLOT_1        = 0x20000;
const USER_FLASH_SLOT_2        = 0x28000;
const USER_FLASH_SLOT_3        = 0x30000;
const USER_FLASH_SLOT_4        = 0x38000;
const USER_FLASH_SLOT_SIZE     = 0x08000;

const USER_FLASH_SYS_CODE_START= 0x03400000;   // start address of user code
const USER_FLASH_USR_CODE_START= 0x03800000;   // start address of user code
const USER_FLASH_MAX_FILE_SIZE = 0x200000;     // Maximum file size for qspi

const USER_FLASH_ROBOTC:number = 0x00020400;   // ROBOTC catalog address

const SUCCESS                   =  0;
const ERROR_BAD_OBJECT          = -1;
const ERROR_NOT_CONNECTED       = -2;
const ERROR_BAD_CATALOG         = -3;
const ERROR_UNSUPPORTED_DEVICE  = -4;
const ERROR_DOWNLOAD            = -5;
const ERROR_DELETE              = -6;
const ERROR_AUTOPLAY            = -7;

interface programInfo {
  startAddress:   number;
  blocks:         number;
  version:        number;
  type:           number;
  name:           string;
  size:           number;

  exec:           string;
  slot:           number;
  requestedSlot:  number; // not used
  time:           string; // not used
}

enum IQDeviceID {
  IQ_EMPTY    = 0,
  IQ_OLD_MOT,
  IQ_MOTOR,
  IQ_LED,
  IQ_RGB,
  IQ_BUMPER,
  IQ_GYRO,
  IQ_SONAR,
  IQ_SMARTRADIO,
  IQ_CONTROLLER,

  // These were added during V5 development
  IQ_BRAIN      = 10,
  IQ_VISION     = 11,
  
  RADIO_2_4     = 13,
  RADIO_900     = 18,

  BL_MOTOR_ROM  = 0xA1,
  BL_MOTOR_RAM,
  BL_SONAR_ROM,
  BL_SONAR_RAM,
  BL_GYRO_ROM,
  BL_GYRO_RAM,
  BL_LED_ROM,
  BL_LED_RAM,
  BL_RGB_ROM,
  BL_RGB_RAM,

  IQ_DONTCARE   = 0xFF
};

class VexIQWebSerial extends VexDeviceWebSerial {
  static STATUS_GOOD:        number = 0x76;
  static STATUS_WACK:        number = 0xFE;
  static STATUS_FAIL:        number = 0xFF;
  static STATUS_TIMEOUT:     number = 0x1FF;
  static STATUS_DISCONNECT:  number = 0x2FF;
  static STATUS_CONNECT_ERR: number = 0x3FF;

  classIdent:       string     = 'Vex IQ serial';
  versionBoot:      number[]   = [0,0];
  versionBrain:     number[]   = [0,0];
  versionJoystick:  number[]   = [0,0];
  versionSystemStr: string     = '';
  versionHardware:  number     = 0;
  uniqueId:         number     = 0;
  deviceName:       string     = '';
  deviceTeamNumber: string     = '0000'; // not used 
  catalogAddresses: number[]   = [];
  programInfo:      programInfo[] = [];

  catalogHeader:    BinFileHeaderIQ;

  constructor() {
    super();

    this.deviceType = null;

    this.catalogHeader = new BinFileHeaderIQ;

    this.serialConnection.portFilters = [
      {usbVendorId: 0x2888, usbProductId: 0x0003}, // IQ1
      {usbVendorId: 0x2888, usbProductId: 0x0200}, // IQ2 brain
      {usbVendorId: 0x2888, usbProductId: 0x0210}, // controller
    ];

    log.info("constructed IQ")
  }

  //#region connection information
  private _deviceType: VEXWebSerialDeviceType = null;
  get deviceType(): VEXWebSerialDeviceType {
    return this._deviceType;
  }
  set deviceType(newType: VEXWebSerialDeviceType) {
    this._deviceType = newType;
  }

  protected processPortInformation(portInfo: SerialPortInfo) {
    const isGen1 = portInfo && portInfo.usbProductId === 0x0003;
    const isGen2 = portInfo && portInfo.usbProductId === 0x0200;
    const isGen2Controller = portInfo && portInfo.usbProductId === 0x0210;

    if (isGen1) {
      this.deviceType = "IQ";
    } else if (isGen2) {
      this.deviceType = "IQ2";
    } else if (isGen2Controller) {
      this.deviceType = "IQ2Controller";
    } else {
      log.warn("unknown product ID", portInfo);
      this.deviceType = null;
    }
  }
  //#endregion connection information

  //#region connection check helpers
  //#endregion connection check helpers

  //#region connection control
  //#endregion connection control

  //#region brain information
  get isGen1(): boolean {
    return this.deviceType === "IQ"
  }

  async fetchBrainInfo() {
    return this.isGen1 ? this.fetchBrainInfoIQ1() : super.fetchBrainInfo();
  }

  async fetchBrainInfoIQ1() {
    await this.getBrainNameIQ1(true);
    await this.brainGetSystemStatusIQ1(true);
    await this.brainGetSystemVersion(true);

    if (this.uniqueId || this.versionSystem[0]) {
      return this.getBrainInfo();
    } else {
      return null;
    }
  }

  //#region brain name
  getBrainName(canBeConnecting: boolean = false): Promise<string> {
    return this.isGen1 ? this.getBrainNameIQ1(canBeConnecting) : super.getBrainName(canBeConnecting);
  }

  private async getBrainNameIQ1(canBeConnecting: boolean = false): Promise<string> {
    log.info("getBrainName");
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    try {
      const reply = await this.writeDataAsync(this.cdc.brinName());
      this.decodeBrainName(reply);
    } catch (err) {
    }
    return this.deviceName;
  }

  setBrainName(name: string): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection();
    if (this.isGen1) {
      throw new Error("Method not implemented.");
    }
    return super.setBrainName(name);
  }
  //#endregion brain name

  //#region brain team
  async getBrainTeamNumber(canBeConnecting: boolean = false): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    if (this.isGen1) {
      throw new Error("Method not implemented.");
    }
    return super.getBrainTeamNumber(canBeConnecting);
  }

  setBrainTeamNumber(team: string): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection();
    if (this.isGen1) {
      throw new Error("Method not implemented.");
    }
    return super.setBrainTeamNumber(team);
  }
  //#endregion brain team

  /**
   * Get the robot system status
   */
  async brainGetSystemStatus(canBeConnecting: boolean = false) : Promise<ArrayBuffer> {
    return this.isGen1 ? this.brainGetSystemStatusIQ1(canBeConnecting) : super.brainGetSystemStatus(canBeConnecting);
  }

  private async brainGetSystemStatusIQ1(canBeConnecting: boolean = false) : Promise<ArrayBuffer> {
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    this.uniqueId = 0;
    try {
      const reply = await this.writeDataAsync(this.cdc.query1());
      this.decodeQuery1(reply);
      return reply;
    } catch (err) {
      return undefined;
    }
  }

  async brainGetSystemVersion(canBeConnecting: boolean = false): Promise<ArrayBuffer> {
    log.info("brainGetSystemVersion");
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    try {
      const reply = await this.writeDataAsync(this.cdc.systemVersion());
      this.decodeSystemVersion(reply);
      return reply
    } catch (err) {
      return undefined;
    }
  }

  getBrainFirmwareVersion(): Promise<VexFirmwareVersion> {
    this.checkSupported();
    this.checkRequiredConnection();
    throw new Error("Method not implemented.");
  }
  //#endregion brain information

  //#region controller information
  //#endregion controller information

  //#region firmware
  async getCurrentFirmwareVersion(): Promise<VexFirmwareVersion> {
    const base = "https://content.vexrobotics.com/vexos/public/"
    const catalogURL = base + (!this.isGen1 ? "IQ2/catalog.txt" : "IQ/catalog4.txt");
    log.debug("catalogURL:", catalogURL);
    try {
      const catalogResponse = await fetch(catalogURL);
      const catalog = await catalogResponse.text();
      log.debug("catalog content:", catalog);
      const versionStr = catalog.replace(/VEXOS_IQ2?_/, "");
      return VexFirmwareVersion.fromCatalogString(versionStr);
    } catch (err) {
      log.warn(err);
      return null;
    }
  }

  async updateFirmware(progressCB: BrainUpdateProgressCallback, connectPrompt: ConnectPromptCallback): Promise<boolean> {
    if (this.isGen1) {
      throw new Error("Method not implemented.");
    }

    this.checkSupported();
    this.checkRequiredConnection();
    this.checkRequiredBrainConnection();
    
    const brainVersionStart = this.brainVersionSystem ? this.brainVersionSystem.toInternalString() : "unknown";

    let lastProgress = {
      state: VEXBrainUpdateStates.done,
      prog: -1, 
      msg: "",
    };
    let totalSteps = VEXBrainUpdateStates.AssetUpdate - 1;
    let goldenOffset: number = 2;
    const sendProgress: BrainUpdateProgressCallback = (state, prog, msg) => {
      const roundedProgress = Math.round(prog * 100);
      if (lastProgress.state === state && lastProgress.msg === msg && lastProgress.prog === roundedProgress) {
        // no point sending this update as it is the same as the previous update
        return;
      }
      lastProgress = {state, prog: roundedProgress, msg};

      const offset = state > VEXBrainUpdateStates.GoldenReboot ? state - goldenOffset : state - 1;
      const totalProgress = (offset + prog) / totalSteps;

      log.debug("brain firmware progress:", VEXBrainUpdateStates[state], prog, totalProgress, msg);
      if (progressCB) {
        progressCB(state, totalProgress, msg);
      }
    }

    // based on function updateFirmware_iq2 from device manager
    
    try {
      // 1. get FW file
      const fw = await this.loadFirmware((prog) => {
        sendProgress(VEXBrainUpdateStates.LoadingFirmwareFile, prog, "Loading Firmware File");
      });


      // 2. update golden image if needed
      if ((this.primaryBootSource === true || this._ramBootloader === true) && (this.checkGoldenImage() === false)) {
        log.info("updating Golden...");
        const goldenFW = fw.getFile("EXP_boot_rom.bin");
        const goldenRes = await this.updateBrain(goldenFW.data, 0xB2, (percent, total) => {
          sendProgress(VEXBrainUpdateStates.GoldenUpdate, percent, "Updating Golden");
        });

        if (!goldenRes)  {
          throw new ErrorUpdatingBrainGolden();
        }

        // 2b. reboot brain and reconnect?
        log.info("waiting for reboot");
        sendProgress(VEXBrainUpdateStates.GoldenReboot, 0, "Rebooting Post Golden Update");
        await this.delay(3000);
        // TODO: do we need to reconnect?
      } else {
        goldenOffset = 3;
        totalSteps = VEXBrainUpdateStates.AssetUpdate - 2;
      }

      // 3. update boot.bin
      log.info("updating firmware");
      sendProgress(VEXBrainUpdateStates.FirmwareUpdate, 0, "Updating Firmware");
      const bootFW = fw.getFile("boot.bin");
      const bootRes = await this.updateBrain(bootFW.data, 1, (percent, total) => {
        sendProgress(VEXBrainUpdateStates.FirmwareUpdate, percent, "Updating Firmware");
      });

      if (!bootRes) {
        throw new ErrorUpdatingBrainBoot();
      }

      // 4. update assets
      log.info("updating assets");
      sendProgress(VEXBrainUpdateStates.AssetUpdate, 0, "Updating Assets");
      const assetFW = fw.getFile("assets.bin");
      const assetRes = await this.updateBrain(assetFW.data, 0, (percent, total) => {
        sendProgress(VEXBrainUpdateStates.AssetUpdate, percent, "Updating Assets");
      });

      if (!assetRes) {
        throw new ErrorUpdatingBrainAssets();
      }

      // // 5. reboot and reconnect
      // log.info("rebooting... waiting for connection loss");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0, "Waiting For Poweroff");
      // while (this.isConnected) {
      //   await this.delay(500);
      // }
      
      // log.info("rebooting... waiting for reboot");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0.3, "Waiting For Reboot");
      // await this.delay(10000);

      // log.info("rebooting... waiting for new connection");
      // sendProgress(VEXBrainUpdateStates.Reboot, 0.5, "Waiting For Connection");
      // let reconnected = false;
      // while (!reconnected) {
      //   try {
      //     await this.reconnect();
      //     reconnected = true;
      //   } catch(err) {
      //     if (err instanceof DOMException) {
      //       if (err.name === "SecurityError") {
      //         log.warn("failed to open connect prompt due to security permissions");
      //         await connectPrompt();  
      //       } else if (err.name === "NotFoundError") {
      //         log.warn("user hit cancel")
      //         await connectPrompt();
      //       }
      //     } else {
      //       log.error("error name:", err.name, err);
      //       throw err;
      //     }
      //   }
      // }

      this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;

      log.info("update complete")
      sendProgress(VEXBrainUpdateStates.done, 1, "Update Complete");

      // setTimeout(async () => {
      //   await this.fetchBrainInfo();
      //   await this.checkUpdateNeededBrain();
      //   this.fireEvent("deviceInfoUpdated", this.getBrainInfo());
      // }, 200);

      // this.isUpdatingFirmware = false;
    } catch (err) {
      this.isUpdatingFirmware = false;
      this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;
      log.debug("brain update error:", err);
      log.debug("brain version before update start:", brainVersionStart);
      throw err;
    }
    return true;
  }
  //#endregion firmware

  //#region firmware file data
  protected createVexFWInstance() {
    return this.isGen1 ? new VexFWIQ() : new VexFWIQ2();
  }
  //#endregion firmware file data

  //#region user data
  //#endregion user data

  //#region project controls
  play(slot: number): Promise<boolean> {
    return this.isGen1 ? this.playIQ1(slot) : super.play(slot);
  }
  
  private playIQ1(slot: number): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    return new Promise<boolean>((resolve, reject) => {
      this.writeDataAsync(this.cdc.playSlot(slot + 1))
        .then(() => {
          resolve(true);
        })
        .catch(()=> {
          reject();
        });
    });
  }
  
  stop(): Promise<boolean> {
    return this.isGen1 ? this.stopIQ1() : super.stop();
  }

  private stopIQ1(): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    return new Promise((resolve, reject) => {
      this.writeDataAsync(this.cdc.stopProgram())
        .then(() => {
          resolve(true);
        })
        .catch(()=> {
          reject();
        });
    });
  }
  //#endregion project controls

  //#region downloads
  async downloadProgram(data: ArrayBuffer, info: IIQProjectInformation, progress: ProgressCallbackDownload): Promise<boolean> {
    if (this.isGen1) {
      return this.downloadProgramIQ1(data, info, progress);
    }
    return this.downloadProgramIQ2(data, info, progress);
  }

  private async downloadProgramIQ1(data: ArrayBuffer, info: IIQProjectInformation, progress: ProgressCallbackDownload): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();

    return new Promise(async (resolve, reject) => {
      const buffer = new Uint8Array(data);

      // get the header info
      this.catalogHeader.readHeader( buffer );
      log.debug(this.catalogHeader.debugString());

      if (this.catalogHeader.validate()) {
        // Now we can send the data to the vexiq downloader
        resolve(await this.downloadDataIQ1(buffer, progress));
      } else {
        log.warn("Download error: bad catalog");
        resolve(false);
      }
    });
  }

  private async downloadProgramIQ2(data: ArrayBuffer, info: IIQProjectInformation, progress: ProgressCallbackDownload): Promise<boolean> {
    const v5info: IV5ProjectInformation = {
      ...info,
      ports: [],
      triports: [],
      controller1: null,
      controller2: null,
    }
    return super.downloadProgram(data, v5info, progress);
  }
  //#endregion downloads

  //#region brain files
  //#endregion brain files

  //#region file metadata helpers
  //#endregion file metadata helpers

  //#region brain info meta data helpers
  //#endregion brain info meta data helpers

  //#region Python vm helpers
  protected getVMLinkInfo(): IPythonProgramLinkInfo {
    return {
      exttype: 0x61,
      loadaddr: 0x10300000,
      linkfile: "python_vm.bin",
      linkfilevid: VexDeviceWebSerial.VID.VEXVM,
    };
  }

  protected getVmMeta() {
    return {
      crc: 0x42FA7060,
      version: 0x01000010,
    };
  }
  protected getPythonVMResourcePath(): string {
    return "resources/iq2/vm/python_vm.bin";
  }

  protected getPythonVmDownloadInfo(): IPythonVMDownloadInfo {
    return {
      address: 0x10300000,
      target: VexIQWebSerial.FILE_TARGET_FLASH,
      vid: 0xFF,
    };
  }

  protected postVMDownloadCleanup() {
    this.downloadTargetSet(VexIQWebSerial.FILE_TARGET_QSPI);
    this.downloadAddressSet(VexIQWebSerial.USR_ADDRESS);
  }
  //#endregion Python vm helpers

  //#region controller firmware
  protected get radioChipId() {
    return cc264xdfu.CC2640_CHIP_ID
  }

  protected get dfuTarget() {
    return DFUTargetDevice.IQ2Controller;
  }
  //#endregion controller firmware

  //#region controller comms
  //#endregion controller comms

  //#region low level comms
  /**
   * Download ArrayBuffer (Uint8Array) to the VEXIQ
   * serial link should be open and the catalog header should have been verified
   * before calling this function.
   */
  private async downloadDataIQ1(buf: Uint8Array, progressCallback: ProgressCallbackDownload): Promise<boolean> {
    // We need some data
    // and must be connected
    if (buf === undefined || !this.connected) {
      return false;
    }

    var nextAddress = this.catalogHeader.nUserAddress;
    var bigProgram: boolean = false;

    // clear status
    this.lastStatus = VexIQWebSerial.STATUS_GOOD;

    return new Promise<boolean>((resolve, reject) => {
      // and do the download......
      this.writeDataAsync(this.cdc.query1())
        .then((reply: ArrayBuffer) => {
          // update progress
          if (progressCallback)
            progressCallback({progress: 0, state: DownloadState.DownloadingProgram});
          log.debug('Get user catalog');
          return this.writeDataAsync(this.cdc.userProgramSlotsGet());
        })
        .then((reply: ArrayBuffer) => {
          this.decodeGetUserSlot(reply);
          log.debug('stop program');
          return this.writeDataAsync(this.cdc.stopProgram());
        })
        .then((reply: ArrayBuffer) => {
          // does the existing catalog address match the program ?
          if (!this.validateCatalogAddress()) {
            // No, we must erase everything
            log.debug('flash erase everything');
            return this.writeDataAsync(this.cdc.flashErase(USER_FLASH_START, (USER_FLASH_END - USER_FLASH_START) / USER_FLASH_BLOCK), { timeout: 1000 })
              .then((reply: ArrayBuffer) => {
                // does the existing catalog address match the program ?
                log.debug('download exit');
                return this.writeDataAsync(this.cdc.downloadExit());
              })
              .then((reply: ArrayBuffer) => {
                log.debug('erase catalog');
                return this.writeDataAsync(this.cdc.eraseCatalog());
              });
          }
        })
        .then((reply: ArrayBuffer) => {
          var blocks = ((buf.length + 1023) / 1024) >> 0;
          if (blocks > 32)
            bigProgram = true;
          log.debug(`flash erase addrsss ${this.cdc.hex8(nextAddress)} with ${blocks} blocks`);
          return this.writeDataAsync(this.cdc.flashErase(nextAddress, blocks), { timeout: 1000 });
        })
        .then((reply: ArrayBuffer) => {
          log.debug('set catalog slot');
          return this.writeDataAsync(this.cdc.userProgramSlotsSet(this.catalogHeader.nSLOT, nextAddress));
        })
        .then((reply: ArrayBuffer) => {
          let bufferOffset = 0;
          let bufferChunkSize = USER_PROG_CHUNK_SIZE;

          return new Promise((resolve, reject) => {
            var lastBlock = false;

            var sendBlock = () => {
              var tmpbuf: Uint8Array;
              if (buf.byteLength - bufferOffset > bufferChunkSize)
                tmpbuf = buf.subarray(bufferOffset, bufferOffset + bufferChunkSize);
              else {
                // last chunk
                tmpbuf = buf.subarray(bufferOffset, buf.byteLength);
                lastBlock = true;
              }

              log.debug(`Write addr ${this.cdc.hex8(nextAddress)} Size ${tmpbuf.length}`);

              // Write the chunk
              this.writeDataAsync(this.cdc.flashWrite(nextAddress, tmpbuf))
                .then((reply: ArrayBuffer) => {
                  // last block sent ?
                  if (lastBlock) {
                    resolve(reply);
                  }
                  else {
                    sendBlock();
                  }
                })
                .catch((reply: ArrayBuffer) => {
                  reject(reply);
                });

              // update progress
              if (progressCallback != undefined)
                progressCallback({progress: bufferOffset / buf.byteLength, state: DownloadState.DownloadingProgram});

              // next chunk
              bufferOffset += bufferChunkSize;
              nextAddress += bufferChunkSize;
            }

            // Send first block
            sendBlock();
          });
        })
        .then((reply: ArrayBuffer) => {
          log.debug('download exit');
          return this.writeDataAsync(this.cdc.downloadExit());
        })
        .then((reply: ArrayBuffer) => {
          if (bigProgram && this.catalogHeader.nSLOT < 4) {
            log.info('big program detected');

            let blocks = ((buf.length + 1023) / 1024) >> 0;
            nextAddress = this.catalogHeader.nUserAddress + (blocks * 1024);
            log.debug(`  flash erase addrsss ${this.cdc.hex8(nextAddress)} with 1 blocks`);

            return this.writeDataAsync(this.cdc.flashErase(nextAddress, 1), { timeout: 1000 })
              .then((reply: ArrayBuffer) => {
                let slot = this.catalogHeader.nSLOT + 1;
                log.debug('  set next catalog slot to ' + this.cdc.hex8(nextAddress));
                return this.writeDataAsync(this.cdc.userProgramSlotsSet(slot, nextAddress));
              })
              .then((reply: ArrayBuffer) => {
                let tmpbuf: Uint8Array = new Uint8Array(USER_PROG_CHUNK_SIZE);
                tmpbuf.fill(0xFF);

                let slot = this.catalogHeader.nSLOT + 1;
                let dum = new BinFileHeaderIQ;
                dum.createDummy(slot, nextAddress);
                dum.writeHeader(tmpbuf);

                log.debug(`  Write addr ${this.cdc.hex8(nextAddress)} Size ${tmpbuf.length}`);
                // Write the chunk
                return this.writeDataAsync(this.cdc.flashWrite(nextAddress, tmpbuf));
              })
              .then((reply: ArrayBuffer) => {
                log.debug('  download exit');
                return this.writeDataAsync(this.cdc.downloadExit());
              })
              .then((reply: ArrayBuffer) => {
                log.debug(`  flash erase addrsss ${this.cdc.hex8(nextAddress)} with 1 blocks`);
                return this.writeDataAsync(this.cdc.flashErase(nextAddress, 1), { timeout: 1000 });
              })
              .then((reply: ArrayBuffer) => {
                log.debug('  download exit');
                return this.writeDataAsync(this.cdc.downloadExit());
              });
          }
        })
        .then((reply: ArrayBuffer) => {
          log.info('download done');
          // update progress
          if (progressCallback != undefined)
            progressCallback({progress: 1, state: DownloadState.DownloadingProgram});
          resolve(true);
        })
        .catch((reply: ArrayBuffer) => {
          log.warn('download error');
          // update progress
          if (progressCallback != undefined)
            progressCallback({progress: 0, state: DownloadState.DownloadingProgram});
          resolve(false);
        });
      });
  }

  /**
   * validate catalog start addresses
   * A change of slot start address will cause a complete erase
   * RobotC being installed will cause a complete erase
   * @return {boolean} true for valid catalog (no erase)
   */
  private validateCatalogAddress(): boolean {
    let currentCatalogAddress = this.catalogAddresses[ this.catalogHeader.nSLOT ];

    // check if ROBOTC is installed
    if( this.catalogAddresses[ USER_PROG_2 ] === USER_FLASH_ROBOTC )
      return false;

    // does the new user program match the existing catalog address ?
    if( this.catalogHeader.nUserAddress === currentCatalogAddress )
      return true;
    else
      return false;
  }

  private decodeQuery1(msg: Uint8Array | ArrayBuffer): void {
    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array( msg ) : msg;

    if (this.cdc.cdcValidateIQMessage(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength(buf);
      if (length > 128) {
        extcmd = 5;
      }
      
      this.versionJoystick[0] = dvb.getUint8(extcmd + 0);
      this.versionJoystick[1] = dvb.getUint8(extcmd + 1);

      this.versionBrain[0]    = dvb.getUint8(extcmd + 2);
      this.versionBrain[1]    = dvb.getUint8(extcmd + 2);

      this.versionBoot[0]     = dvb.getUint8(extcmd + 6);
      this.versionBoot[1]     = dvb.getUint8(extcmd + 7);

      // TODO: decode status bitmap
      /*
        byte 12 = extcmd + 8 = Brain Status bits

        Bit 0     //1= Radio is detected
        Bit 1     //1= Radio is linked
        Bit 2     //1= Joystick is connected
        Bit 3     //1= a user program is running
        Bit 4     //1= a user download is in progress
        Bit 5     //1= user code has been initialized
        Bit 6     //1= Button Control is active
        Bit 7     //1= Fatal Error on Motor Port

      */

      // TODO: decode sensors need update
      /*
        byte 8 = extcmd + 4 = Total # of Sensors needing a firmware upgrade
        byte 9 = extcmd + 5 =  Sensor Type (2-7)

        Where: 2=Motor, 3=LED, 4=RGB, 5=N/A, 6=GYRO, 7=SONAR

        When byte 8 and 9 are = 0 then there are no sensors requiring a firmware update
      */
    }
  }
    
  private decodeSystemVersion(msg: Uint8Array | ArrayBuffer): void {
    /*
      byte 0 = 0xAA
      byte 1 = 0x55
      byte 2 = 0xA4 // CDC_GET_SYS_VERSION
      byte 3 = 8 // size of the data ... at least for now
      bytes 4-11 = the version structure
        byte 0 = major
        byte 1 = minor
        byte 2 = build
        byte 3 = hardware version
        byte 4 = beta version
        byte 5 = product id // not used?
        byte 6 = product flags
          bit0 = ?
          bit1 = battery low
          bit2 = ?
          bit3 = ?
          bit4 = ?
          bit5 = ?
          bit6 = ?
          bit7 = ?
        byte 7 = reserved
    */
    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array( msg ) : msg;

    if (this.cdc.cdcValidateIQMessage(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength(buf);
      if (length > 128) {
        extcmd = 5;
      }

      this.versionSystem[0] = dvb.getUint8(extcmd + 0);
      this.versionSystem[1] = dvb.getUint8(extcmd + 1);
      this.versionSystem[2] = dvb.getUint8(extcmd + 2);
      this.versionSystem[3] = dvb.getUint8(extcmd + 4);
      this.brainVersionSystem = new VexFirmwareVersion(this.versionSystem[0], this.versionSystem[1], this.versionSystem[2], this.versionSystem[3]);
      this.updateSystemVersionString();
    }
  }
  
  /**
   * Decode a received catalog slot address reply
   * @param  (Uint8Array} meg the CDC reply to decode
   */
   decodeGetUserSlot(msg: Uint8Array | ArrayBuffer) {
    const rawbuf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    const buf = new DataView(rawbuf.buffer);
    this.catalogAddresses[0] = buf.getUint32(4, false); // not used
    this.catalogAddresses[1] = buf.getUint32(8, false);
    this.catalogAddresses[2] = buf.getUint32(12, false);
    this.catalogAddresses[3] = buf.getUint32(16, false);
    this.catalogAddresses[4] = buf.getUint32(20, false);
  }

  private decodeBrainName(msg: Uint8Array | ArrayBuffer): void {
    log.info("decodeBrainName", msg);
    /*
      byte 0 = 0xAA
      byte 1 = 0x55
      byte 2 = 0x44 // CDC_GET_ROBOT_NAME
      byte 3 = length
      bytes 4-n = name // n = length + 4
    */

    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    if (this.cdc.cdcValidateIQMessage(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength(buf);
      if (length > 128) {
        extcmd = 5;
      }

      let brainName = "";
      for (let i = 0; i < length; i++) {
        const code = dvb.getUint8(extcmd + i);
        if (code === 0) {
          break;
        }
        const char = String.fromCharCode(code);
        brainName += char;
      }

      this.deviceName = brainName;
    }
  }

  //#endregion

  //#region event handlers
  //#endregion event handlers
}

class BinFileHeaderIQ {
  nStackTop:           number  = 0;
  _c_vexinit:          number  = 0;
  nUserAddress:        number  = 0;
  __TI_CINIT_Limit:    number  = 0;
  ProgramNameOffset:   number  = 0;
  ProgramName:         string  = "";
  nUserVersion:        number;
  nTYPE:               number;
  nSLOT:               number;
  nMODE:               number;
  userInitialize:      number;
  userTask:            number;
  main:                number;
  userIdle:            number;
  nDIGITAL_MASK:       number;
  nAPIVersion:         number;
  pDEviceArrayPointer: number;
  nSensorTypes:        number[];

  // endianess of header, true is littleEndian
  endianess: boolean = true;
  
  constructor() {
  }

  updateDevicePort( data: Uint8Array, port: number, id: number ) {
    let header = new DataView( data.buffer );
    let offset = port - 1;
    let devid = id;
    if( offset < 0 || offset > 11 )
      return;

    console.log("set port" + port + " to " + id )
    header.setUint8(  52 + offset, id )
  }

  readHeader( data: Uint8Array ) {
    let header = new DataView( data.buffer );
    this.nStackTop           = header.getUint32(  0, this.endianess );
    this._c_vexinit          = header.getUint32(  4, this.endianess );
    this.nUserAddress        = header.getUint32(  8, this.endianess );
    this.__TI_CINIT_Limit    = header.getUint32( 12, this.endianess );
    this.ProgramNameOffset   = header.getUint32( 16, this.endianess ); // offset to program names
    this.nUserVersion        = header.getUint8(  20 );
    this.nTYPE               = header.getUint8(  21 );
    this.nSLOT               = header.getUint8(  22 );
    this.nMODE               = header.getUint8(  23 );
    this.userInitialize      = header.getUint32( 24, this.endianess );
    this.userTask            = header.getUint32( 28, this.endianess );
    this.main                = header.getUint32( 32, this.endianess );
    this.userIdle            = header.getUint32( 36, this.endianess );
    this.nDIGITAL_MASK       = header.getUint32( 40, this.endianess );
    this.nAPIVersion         = header.getUint32( 44, this.endianess );
    this.pDEviceArrayPointer = header.getUint32( 48, this.endianess );

    // Get the sensor type array
    this.nSensorTypes = [];
    for( let offset = 0; offset < 12; offset++ ) {
      this.nSensorTypes.push( header.getUint8(  52 + offset ) );
    }

    // get the program name offset relative to file start
    var poffset = this.ProgramNameOffset - this.nUserAddress;

    // crude move CString into javascript string
    this.ProgramName = '';
    for( let offset = poffset; offset < poffset+16; offset++ ) {
      var c = header.getUint8(  offset );
      if( c == 0 )
        break;
      this.ProgramName += String.fromCharCode( c );
    }
  }

  // move header contects into a Uint8Array ready to download
  // the uInt8Array needs to be big enough (132 bytes minimum)
  writeHeader( data:Uint8Array ) {
    let header = new DataView( data.buffer );
    header.setUint32(  0, this.nStackTop,         this.endianess );
    header.setUint32(  4, this._c_vexinit,        this.endianess );
    header.setUint32(  8, this.nUserAddress ,     this.endianess );
    header.setUint32( 12, this.__TI_CINIT_Limit,  this.endianess );
    header.setUint32( 16, this.ProgramNameOffset, this.endianess ); // offset to program names
    header.setUint8(  20, this.nUserVersion );
    header.setUint8(  21, this.nTYPE );
    header.setUint8(  22, this.nSLOT   );
    header.setUint8(  23, this.nMODE  );
    header.setUint32( 24, this.userInitialize ,   this.endianess );
    header.setUint32( 28, this.userTask,          this.endianess );
    header.setUint32( 32, this.main,              this.endianess );
    header.setUint32( 36, this.userIdle ,         this.endianess );
    header.setUint32( 40, this.nDIGITAL_MASK,     this.endianess );
    header.setUint32( 44, this.nAPIVersion,       this.endianess );
    header.setUint32( 48, this.pDEviceArrayPointer, this.endianess );
    for( let offset = 0; offset < 12; offset++ ) {
      header.setUint8(  52 + offset, this.nSensorTypes[offset] );
    }

    // dummy program name
    header.setUint32( 128, 0x00434241, this.endianess );
  }

  // Create a dummy program header
  createDummy( slot: number, address: number ) {
    this.nStackTop           = 0x20008000;
    this._c_vexinit          = address;
    this.nUserAddress        = address;
    this.__TI_CINIT_Limit    = address + 1024;
    this.ProgramNameOffset   = address + 128; // offset to program names
    this.nUserVersion        = 1;
    this.nTYPE               = 0;
    this.nSLOT               = slot;
    this.nMODE               = 0x07;
    this.userInitialize      = address + 256;
    this.userTask            = address + 256;
    this.main                = address + 256;
    this.userIdle            = address + 256;
    this.nDIGITAL_MASK       = 0;
    this.nAPIVersion         = 1;
    this.pDEviceArrayPointer = address;

    this.nSensorTypes = [];
    for( let offset = 0; offset < 12; offset++ ) {
     this.nSensorTypes.push( 0xFF );
    }
    this.ProgramName = 'Dummy';
  }

  validate(): boolean {
    // check some of the properties are within expected bounds for this file

    // Check slot
    if( this.nSLOT < USER_PROG_1 || this.nSLOT > USER_PROG_4 )
      return false;

    // Check start address
    if( this.nUserAddress < USER_FLASH_START )
      return false;

    // Check end address
    if( this.__TI_CINIT_Limit >= USER_FLASH_END )
      return false;

    // Good enough !
    return true;
  }

  debugString(): string {
    let str: string = "";

    str += 'Program:                   ' + this.ProgramName + '\n';
    str += 'Stack top:                 ' + '0x' + this.hex8(this.nStackTop) + '\n';
    str += 'C Init function address:   ' + '0x' + this.hex8(this._c_vexinit) + '\n';
    str += 'User program base address: ' + '0x' + this.hex8(this.nUserAddress) + '\n';
    str += 'User program end address   ' + '0x' + this.hex8(this.__TI_CINIT_Limit) + '\n';
    str += 'User program name offset   ' + '0x' + this.hex8(this.ProgramNameOffset) + '\n';

    str += 'User version:              ' + '0x' + this.hex2(this.nUserVersion )+ '\n';
    str += 'Program type:              ' + '0x' + this.hex2(this.nTYPE) + '\n';
    str += 'Program Slot:              ' + '0x' + this.hex2(this.nSLOT) + '\n';
    str += 'Program mode:              ' + '0x' + this.hex2(this.nMODE) + '\n';

    str += 'Program Init address:      ' + '0x' + this.hex8(this.userInitialize) + '\n';
    str += 'Program Task address:      ' + '0x' + this.hex8(this.userTask) + '\n';
    str += 'Program Main address:      ' + '0x' + this.hex8(this.main) + '\n';
    str += 'Program Idle address:      ' + '0x' + this.hex8(this.userIdle) + '\n';

    str += 'Digital mask:              ' + '0x' + this.hex8(this.nDIGITAL_MASK) + '\n';
    str += 'API version:               ' + '0x' + this.hex8(this.nAPIVersion) + '\n';
    str += 'Device array ptr:          ' + '0x' + this.hex8(this.pDEviceArrayPointer) + '\n';
    
    str += 'Device Array:              ' + '[ ';
    for( let i = 0; i<this.nSensorTypes.length;i++)
      str += this.hex2( this.nSensorTypes[i] ) + ' ';
    str += ']\n';

    return (str );
  }

  /**
   * Utility function to create a hex string from the given number
   * @param  (number} value the number to be formatted into a string with %02X format
   * @return {string}
   */
  private hex2( value:number ): string {
    var str = ('00' + value.toString(16)).substr(-2,2);
    return(str.toUpperCase());
  }
          
  /**
   * Utility function to create a hex string from the given number
   * @param  (number} value the number to be formatted into a string with %08X format
   * @return {string}
   */
  private hex8( value: number ): string {
    var str = ('00000000' + value.toString(16)).substr(-8,8);
    return(str.toUpperCase());
  }
};

export {
  VexIQWebSerial,

  IQDeviceID,
}

export type {
  IIQControllerConfigInfo,
  IIQProjectInformation,
}
