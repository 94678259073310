import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexDeviceWebSerial");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

import { ErrorWriteResponseTimeout, NoAdminPortConnectedError, NoBrainConnectedError, NoControllerConnectedError, NoPythonVMError, NoUserPortConnectedError, UpdateCanceled, WebSerialUnsupportedError } from "./errors";
import { appendArrayBuffer, convertBufferToHexString } from "./helpers";
import { VexCDC, VexCDCMessage } from "./VexCDC";
import { VexFirmwareVersion } from "./VexFirmwareVersion";
import { VexINI } from "./VexINI";
import { VexWebSerial } from "./VexWebSerial";
import { VexFW, VEXosType } from "./firmware/VexFW";
import { cc264xdfu } from "./radio/cc264x";
import { DFUTargetDevice, VexDFU } from "./dfu/VexDFU";
import { buf2hex } from "./dfu/helpers";

import type { IVexCDCWriteOptions } from "./VexCDC";
import type { VEXIconString } from "./VexINI";
import type {
  IVEXWebSerialBrainInfo,
  VEXOSVersionArray,
  VEXWebSerialDeviceType,
} from "./DeviceType";
import { UpdateNeededOptions } from "./enums";

//#region constants
const USER_PROG_CHUNK_SIZE     = 4096;         // chunk size
const USER_FLASH_START         = 0x03000000;   // start address of memory
const USER_FLASH_SYS_CODE_START= 0x03400000;   // start address of user code
const USER_FLASH_USR_CODE_START= 0x03800000;   // start address of user code
const USER_FLASH_END           = 0x08000000;   // end address of memory
const USER_FLASH_MAX_FILE_SIZE = 0x200000;     // Maximum file size for qspi

const USER_FLASH_START_B       = 0x10200000    // special app flash start
const USER_FLASH_END_B         = 0x10400000    // special app flash end

const USER_FLASH_START_C       = 0x30200000    // special app flash start
const USER_FLASH_END_C         = 0x31000000    // special app flash end

const SUCCESS                   =  0;
const ERROR_BAD_OBJECT          = -1;
const ERROR_NOT_CONNECTED       = -2;
const ERROR_BAD_CATALOG         = -3;
const ERROR_UNSUPPORTED_DEVICE  = -4;
const ERROR_DOWNLOAD            = -5;
const ERROR_DELETE              = -6;
  
const ERROR_MUTEX               = -98;
const ERROR_COMMUNICATION       = -99;
//#endregion constants

//#region consts for code
const utf8decoder = new TextDecoder();
const utf8encoder = new TextEncoder();
//#endregion consts for code

//#region type definitions
type EventNames = "connectionStateChange" | "connectionStateChangeUserPort" |
  "receivedUserData" | "connectedToInvalidPort" | "deviceInfoUpdated";

enum VexWebSerialConnectionStates {
  Disconnected = 0,
  Connected = 2,
  Connecting = 1,
  Disconnecting = 10,
}

//#region donwload progress callback types
enum DownloadState {
  None,
  DownloadingProgram,
  CheckingVM,
  DownloadingVM,
}
interface DownloadProgress {
  progress: number;
  state: DownloadState
}
type ProgressCallbackDownload = (data: DownloadProgress) => void;
//#endregion donwload progress callback types

interface IPortInfo {
  port: number;
  label: string;
}

interface ITriportInfo {
  port: number;
  subport: number;
  label: string;
}

/**
 * this is a base type def for controller config info. it should be
 * extended for each platform.
 */
interface IControllerConfigInfo {
  Axis1: string;
  Axis2: string;
  Axis3: string;
  Axis4: string;

  [key: string]: string;
}

/**
 * this is a base type def for controller config button names. it should be
 * extended for each platform.
 */
type ControllerButtonName = keyof IControllerConfigInfo;

interface IProjectInformation {
  slot: number;
  name: string;
  description: string;
  icon: VEXIconString;
  ide: string;

  ports: IPortInfo[];
  triports: ITriportInfo[];
  controller1: IControllerConfigInfo;
  controller2?: IControllerConfigInfo;

  autorun?: boolean;

  language: "cpp" | "python";
}

//#region interfaces for V5/EXP/IQ2 files
interface fileHeaderReply {
  ack:         number;
  packetSize:  number;
  fileSize:    number;
  crc32:       number;    
}

interface fileExitReply {
  ack:         number;
}

interface fileWriteReply {
  ack:         number;
}

interface factoryStatusReply {
  ack:         number;
  status:      number;
  percent:     number;
}

interface fileGetDirReply {
  ack:         number;
  count:       number;
}

interface fileGetDirEntryReply {
  ack:         number;
  index:       number;
  size:        number;    
  loadAddr:    number;    
  crc32:       number;    
  type:        number;    
  timestamp:   number;    
  version:     number;    
  name:        string;    
}

interface programInfo {
  name:           string;
  binfile:        string;
  size:           number; 
  slot:           number;
  requestedSlot:  number;
  time:           string;
}

interface userProgramStatusReply {
  ack:         number;
  slot:        number;
  requestedSlot:  number;
}
//#endregion interfaces for V5/EXP/IQ2 files

interface listReply {
  files:  fileGetDirEntryReply[];
  programs: programInfo[];
}

interface IPythonProgramLinkInfo {
  exttype: number;
  loadaddr: number;
  linkfile: string;
  linkfilevid: number;
};

interface IPythonVMDownloadInfo {
  address: number;
  target: number;
  vid: number;
  version?: number;
};

interface fileGetMetadataReply {
  ack:         number;
  linkvid:     number;
  size:        number;    
  loadAddr:    number;    
  crc32:       number;    
  type:        number;    
  timestamp:   number;    
  version:     number;    
  linkname:    string;    
}

interface IResult {
  data: any,
  err: number,
  msg: string
}

type ProgressCallback = (p: number, c: number, t: number) => void;

// if this gets changed, the sendProgress function in controllerUpdate must be updated
enum VEXControllerUpdateStates {
  LoadingFirmwareFile = 1,
  EnteringDFU = 2,
  UpdatingAtmel = 3,
  WaitingForReboot = 4,
  UpdatingRadio = 5,
  WaitingForRadio = 6,
  done = 7,
}

enum VEXBrainUpdateStates {
  LoadingFirmwareFile = 1,
  GoldenUpdate = 2,
  GoldenReboot = 3, // TODO: is this step actually needed?
  FirmwareUpdate = 4,
  AssetUpdate = 5,
  Reboot = 6,
  done = 10,
}


type BrainUpdateProgressCallback = (state: VEXBrainUpdateStates, progress: number, message: string) => void;
type ControllerUpdateProgressCallback = (state: VEXControllerUpdateStates, progress: number, message: string) => void;
type ConnectPromptCallback = (isFirstConnect: boolean, isDfu: boolean) => Promise<boolean>;

enum VEXDeviceBootSource {
  PRIMARY                   = 0,
  GOLDEN                    = 1,
  SDCARD                    = 2,
  UNKNOWN                   = 3,
  RAM_BL                    = 4,
  ROM_BL                    = 5
}
//#endregion type definitions

abstract class VexDeviceWebSerial {

  public logger = logger;

  //#region static defs
  static STATUS_GOOD:        number = 0x76;
  static STATUS_WACK:        number = 0xFE;
  static STATUS_FAIL:        number = 0xFF;
  static STATUS_TIMEOUT:     number = 0x1FF;
  static STATUS_DISCONNECT:  number = 0x2FF;
  static STATUS_CONNECT_ERR: number = 0x3FF;

  static FILE_FUNC_SAVE:     number = 1;
  static FILE_FUNC_READ:     number = 2;
  static FILE_TARGET_DDR:    number = 0;
  static FILE_TARGET_QSPI:   number = 1;
  static FILE_TARGET_CBUF:   number = 2;
  static FILE_TARGET_VBUF:   number = 3;
  static FILE_TARGET_DDRC:   number = 4;
  static FILE_TARGET_DDRE:   number = 5;
  static FILE_TARGET_FLASH:  number = 6; // for IQ2
  static FILE_TARGET_RADIO:  number = 7; // for IQ2
  static FILE_TARGET_A1:     number = 13;
  static FILE_TARGET_B1:     number = 14;
  static FILE_TARGET_B2:     number = 15;

  static SYS_ADDRESS:        number = USER_FLASH_SYS_CODE_START;
  static USR_ADDRESS:        number = USER_FLASH_USR_CODE_START;

  static VID = {
    USER:            1,
    SYS:             0x0F,
    DEV1:            0x10,
    DEV2:            0x18,
    DEV3:            0x20,
    DEV4:            0x28,
    DEV5:            0x30,
    DEV6:            0x38,
    VEXVM:           0x40,
    VEX:             0xF0,
    UNDEFINED:       0xF1
  }

  static OPTIONS = {
    EXIT_RUN:        1,
    EXIT_HALT:       3,
    FILE_OVERWRITE:  1,
    LOAD_STOP:       0x80,
    ERASE_AL_BNAME:  0x80       // erase all files matching basename
  }
  //#endregion static defs

  downloadAddress:  number     = USER_FLASH_USR_CODE_START;
  downloadTarget:   number     = VexDeviceWebSerial.FILE_TARGET_QSPI;
  downloadAutorun:  number     = VexDeviceWebSerial.OPTIONS.EXIT_RUN;
  lastStatus:       number     = VexDeviceWebSerial.STATUS_GOOD;

  linkfile:         string  = undefined;
  linkfilevid:      number  = undefined;
  
  connected:        boolean = true;

  deviceName:       string     = '';
  deviceTeamNumber: string     = '';

  // system info
  uniqueId:         number     = 0;
  versionSystem:    VEXOSVersionArray = [0, 0, 0, 0];
  versionUser:      number[]   = [0, 0, 0, 0];
  sysflags:         number[]   = [0, 0, 0, 0, 0, 0, 0, 0];
  versionGolden:    number     = 0;
  versionNxp:       number     = 0;
  versionSystemStr: string     = '';

  _inDFUMode: boolean = false;

  // system flags
  battery:          number;
  batteryController:number;
  batteryPartner:   number;
  radioQuality:     number;
  radioSearching:   boolean;
  currentProgram:   number;
  eventBrain:       boolean;
  romBootloaderActive:  boolean = false;
  ramBootloaderActive:  boolean = false;

  protected cdc = new VexCDC();

  /** serial port interface for the admin port */
  protected serialConnection: VexWebSerial;
  protected isConnecting: boolean = false;
  protected isConnected: boolean = false;

  protected isUpdatingFirmware: boolean = false;

  /** serial port interface for the user port */
  protected serialConnectionUserPort: VexWebSerial;
  protected isConnectingUserPort: boolean = false;
  protected isConnectedUserPort_: boolean = false;

  brainVersionSystem: VexFirmwareVersion = null;
  controllerVersionAtmel: VexFirmwareVersion = null;
  controllerVersionRadio: VexFirmwareVersion = null;

  constructor() {
    // setup admin port connection details
    this.serialConnection = new VexWebSerial();

    this.onConnect = this.onConnect.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);

    this.serialConnection.on("connected", this.onConnect);
    this.serialConnection.on("disconnected", this.onDisconnect);

    // setup user port connection details
    this.serialConnectionUserPort = new VexWebSerial();

    this.onConnectUserPort = this.onConnectUserPort.bind(this);
    this.onDisconnectUserPort = this.onDisconnectUserPort.bind(this);

    this.serialConnectionUserPort.on("connected", this.onConnectUserPort);
    this.serialConnectionUserPort.on("disconnected", this.onDisconnectUserPort);

    this.onUserPortReceivedData = this.onUserPortReceivedData.bind(this);
    this.serialConnectionUserPort.onRXData = this.onUserPortReceivedData;
  }

  reset() {
    if (this.connected && this.lastStatus !== VexDeviceWebSerial.STATUS_DISCONNECT) {
      // reset system status
      this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;      
    }
  }

  //#region connection information
  /**
   * this indicates if the serial connection is seupported by the system
   */
  get isSupported(): boolean {
    return this.serialConnection.isSupported;
  }

  /**
   * the device type that this intgerface is for
   */
  abstract get deviceType(): VEXWebSerialDeviceType;

  /**
   * the connect device is a VEx Brain
   */
  get isDeviceBrain(): boolean {
    const dev = this.deviceType;
    return dev === "IQ" || dev === "IQ2" || dev === "EXP" || dev === "V5";
  }

  /**
   * the connected device is a VEX Controller
   */
  get isDeviceController(): boolean {
    const dev = this.deviceType;
    // TODO: add V5 conttroller support?
    return dev === "IQ2Controller" || dev === "EXPController";
  }

  /**
   * a brain is connected directly or connected through a controller
   */
  get isBrainConnected(): boolean {
    // TODO: handle when connected through a controller
    return this.isDeviceBrain;
  }

  /**
   * this should be called after a connection is established to make sure the device information is updated
   * @param portInfo the port info for the new connection
   */
  protected abstract processPortInformation(portInfo: SerialPortInfo): void;

  async isVexAdminPort(isBrain: boolean) {
    return isBrain ? this.isVexBrainAdminPort() : this.isVexControllerAdminPort();
  }

  async isVexBrainAdminPort() {
    log.debug("isVexBrainAdminPort called");
    this.checkSupported();
    this.checkRequiredConnection(true);

    this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;

    return new Promise((resolve, reject) => {
      this.writeDataAsync(this.cdc.systemVersion())
        .then((reply) => {
          resolve(reply !== undefined);
        })
        .catch(() => {
          resolve(false);
        })
    });
  }

  
  async isVexControllerAdminPort() {
    log.debug("isVexControllerAdminPort called");
    this.checkSupported();
    this.checkRequiredConnection(true);

    this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;

    return new Promise((resolve, reject) => {
      this.controllerAtmelStatus(true, true)
        .then((res) => {
          log.warn("res:", res);
          resolve(!!res);
        })
        .catch((err) => {
          log.error(err);
          resolve(false);
        })
    });
  }

  /**
   * True if the user port connection can be requested
   */
  get canConnectUserPort(): boolean {
    return this.connectionState === VexWebSerialConnectionStates.Connected;
  }

  /**
   * True if the user port is activly connected. 
   */
  get isConnectedUserPort(): boolean {
    return this.isConnectedUserPort_;
  }
  //#endregion connection information

  //#region connection check helpers
  /**
   * call this to check if WebSerial is supported in the browser
   * @throws WebSerialUnsupportedError if WebSerial is not supported on your browser
   */
  protected checkSupported() {
    if (!this.isSupported) {
      throw new WebSerialUnsupportedError();
    }
  }

  /**
   * checkes if connected
   * @param canBeConnecting true if this operation can be done while connecting
   */
  protected checkRequiredConnection(canBeConnecting: boolean = false) {
    if (this.isConnected || (canBeConnecting && this.isConnecting)) {
      return;
    }
    throw new NoBrainConnectedError();
  }

  /**
   * checkes if brain connected
   * @throws NoBrainConnectedError if there is no VEX Brain connected
   */
  protected checkRequiredBrainConnection() {
    if (this.isConnected && this.isDeviceBrain) {
      return;
    }
    throw new NoBrainConnectedError();
  }

  /**
   * checkes if connected
   * @param canBeConnecting true if this operation can be done while connecting
   * @throws NoAdminPortConnectedError if no admin port is connected
   * @throws NoUserPortConnectedError if no admin port is connected
   */
  protected checkRequiredConnectionUserPort(canBeConnecting: boolean = false) {
    if (this.connectionState !== VexWebSerialConnectionStates.Connected) {
      throw new NoAdminPortConnectedError();
    }

    if (this.isConnectedUserPort_ || (canBeConnecting && this.isConnectingUserPort)) {
      return;
    }
    throw new NoUserPortConnectedError();
  }
  //#endregion connection check helpers

  //#region connection control
  /**
   * call this to open a serial connection. This will prompt the user to select a port
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   */
  async openConnection(): Promise<void> {
    this.checkSupported();
    if (this.isConnected) {
      log.warn("already connected...");
      return;
    }
    this.isUpdatingFirmware = false;
    await this.serialConnection.openConnection();
  }

  protected async reconnect() {
    await this.serialConnection.reconnect();
  }

  /**
   * call this to close an open connection.
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   */
  async closeConnection(): Promise<void> {
    this.checkSupported();
    if (!(this.isConnected || this.isConnecting)) {
      log.info("not connected...");
      return;
    }
    await this.serialConnection.closeConnection();
  }

  /**
   * indicates if there is an open connection to a brain.
   */
  get connectionState(): VexWebSerialConnectionStates {
    return this.isUpdatingFirmware || this.isConnected ? VexWebSerialConnectionStates.Connected : 
      this.isConnecting ? VexWebSerialConnectionStates.Connecting : VexWebSerialConnectionStates.Disconnected;
  }

  /**
   * call this to open a serial connection. This will prompt the user to select a port
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoAdminPortConnectedError if admin port is not connected
   */
  async openConnectionUserPort(): Promise<void> {
    this.checkSupported();
    if (this.connectionState !== VexWebSerialConnectionStates.Connected) {
      throw new NoAdminPortConnectedError();
    }
    if (this.isConnectedUserPort_) {
      log.warn("already connected to user port...");
      return;
    }
    this.isConnectingUserPort = true;
    this.fireEvent("connectionStateChangeUserPort", VexWebSerialConnectionStates.Connecting);
    await this.serialConnectionUserPort.openConnection();
  }

  protected async reconnectUserPort() {
    await this.serialConnectionUserPort.reconnect();
  }

  /**
   * call this to close an open connection.
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   */
  async closeConnectionUserPort(): Promise<void> {
    this.checkSupported();
    this.checkRequiredConnectionUserPort();
    if (!(this.isConnectedUserPort_ || this.isConnectingUserPort)) {
      log.info("not connected to user port...");
      return;
    }
    await this.serialConnectionUserPort.closeConnection();
  }

  /**
   * indicates if there is an open connection to a brain.
   */
  get connectionStateUserPort(): VexWebSerialConnectionStates {
    return this.isConnectedUserPort_ ? VexWebSerialConnectionStates.Connected : 
      this.isConnectingUserPort ? VexWebSerialConnectionStates.Connecting : VexWebSerialConnectionStates.Disconnected;
  }
  //#endregion connection control

  //#region brain information
  getBrainInfo(): IVEXWebSerialBrainInfo {
    return {
      serial: this.uniqueId,
      deviceType: this.deviceType,

      isBrainConnected: this.isBrainConnected,
      // connectionType: this.connectionType,

      // TODO: actually check this
      updateNeededBrain: this._needsUpdateStateBrain === UpdateNeededOptions.NeedsUpdate,
      updateNeededController: this._needsUpdateStateController === UpdateNeededOptions.NeedsUpdate,

      brainVersion: this.brainVersionSystem,
      atmelVersion: this.controllerVersionAtmel,
      radioVersion: this.controllerVersionRadio,

      // TODO: actually check this
      isDFUMode: this._inDFUMode,

      name: this.deviceName,
      team: this.deviceTeamNumber,
      battery: this.battery,

      // TODO: add this info?
      hardwareRevision: "0",
    };
  }

  async fetchBrainInfo() {
    log.debug("fetching brain information");
    const res = await this.brainGetSystemFlags(true);
    if (res) {
      await this.getBrainName(true);
      await this.getBrainTeamNumber(true);
      await this.brainGetSystemStatus(true);

      return this.getBrainInfo();
    } else {
      return null;
    }
  }

  get primaryBootSource(): boolean {
    return this.isBrainConnected && this.checkBootSource() === VEXDeviceBootSource.PRIMARY;
  }

  get _ramBootloader(): boolean {
    return this.isBrainConnected && this.checkBootSource() === VEXDeviceBootSource.RAM_BL;
  }
  //#region brain name
  /**
   * This will get the current brain name from the connected brain.
   * @returns the current name of the brain
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  getBrainName(canBeConnecting: boolean = false): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    return new Promise((resolve, reject) => {
      this.writeDataAsync(this.cdc.V5_Cdc2SysKVRead("robotname"))
        .then((reply: ArrayBuffer) => {
          // log.debug("about to decode reply");
          this.deviceName = this.decodeSysKVRead(reply);
          log.debug("this.deviceName:", this.deviceName);
          if (!this.deviceName) {
            this.deviceName = "";
          }
          resolve(this.deviceName);
        })
        .catch(() => {
          // perhaps we don't support this yet
          resolve(undefined);
        })
    });
  }

  /**
   * This will set the brain name then return the current name from the brain.
   * @param name the new name for the brain
   * @returns the current name of the brain
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  setBrainName(name: string): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection();
    return this.writeDataAsync(this.cdc.V5_Cdc2SysKVSave("robotname", name), {timeout:1000})
      .then(() => {
        return this.getBrainName();
      })
      .catch(() => {
        return undefined;
      })
  }
  //#endregion brain name

  //#region brain team
  /**
   * This will get the current brain team number from the connected brain.
   * @returns the current team number of the brain
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  getBrainTeamNumber(canBeConnecting: boolean = false): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    return new Promise((resolve, reject) => {
      this.writeDataAsync(this.cdc.V5_Cdc2SysKVRead("teamnumber"))
        .then((reply: ArrayBuffer) => {
          // log.debug("about to decode reply");
          this.deviceTeamNumber = this.decodeSysKVRead(reply);
          log.debug("this.deviceTeamNumber:", this.deviceTeamNumber);
          if (!this.deviceTeamNumber) {
            this.deviceTeamNumber = "";
          }
          resolve(this.deviceTeamNumber);
        })
        .catch(() => {
          // perhaps we don't support this yet
          resolve(undefined);
        })
      });
  }

  /**
   * This will set the team number then return the current team number from the brain.
   * @param team the new team number for the brain
   * @returns the current team number of the brain
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  setBrainTeamNumber(team: string): Promise<string> {
    this.checkSupported();
    this.checkRequiredConnection();
    return this.writeDataAsync(this.cdc.V5_Cdc2SysKVSave("teamnumber", team), {timeout:1000})
      .then(() => {
        return this.getBrainTeamNumber();
      })
      .catch(() => {
        return undefined ;
      })
  }
  //#endregion brain team

  /**
   * This will get the current firmware version from the connected brain
   * @returns the VEXos version on the connected brain
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  getBrainFirmwareVersion(): Promise<VexFirmwareVersion> {
    this.checkSupported();
    this.checkRequiredConnection();
    throw new Error("Method not implemented.");
  }
  //#endregion brain information

  //#region controller information
  async getControllerFirwareVersions(): Promise<{atmel: VexFirmwareVersion, radio: VexFirmwareVersion}> {
    if (!this.controllerVersionAtmel || !this.controllerVersionRadio) {
      await this.fetchControllerInfo();
    }
    // const [atmelDeviceVersion, radioDeviceVersion] = await this.controllerVersionsGet();
    // this.controllerVersionAtmel = atmelDeviceVersion;
    // this.controllerVersionRadio = radioDeviceVersion;

    return {
      atmel: this.controllerVersionAtmel,
      radio: this.controllerVersionRadio,
    }
  }

  /**
   * will fetch the version info from the controller.
   * 
   * Note this may get extended in the future to support pulling more data...
   */
  async fetchControllerInfo() {
    if (!this.isDeviceController) {
      throw new NoControllerConnectedError();
    }

    const controllerVersions = await this.controllerVersionsGet()
    log.warn("controllerVersions:", controllerVersions);
    if (!controllerVersions || controllerVersions.length === 0) {
      log.warn("unable to get both versions from the controller. trying atmel only");
      const status = await this.controllerAtmelStatus(undefined, true);
      if (!status) {
        log.error("unable to get any version info from the controller");
      }
      if (status.status === 0x20) {
        log.info("controller radio stuck in bootload");
      }
      this.controllerVersionAtmel = status.version;
      this.controllerVersionRadio = null;
    } else {
      this.controllerVersionAtmel = controllerVersions[0];
      this.controllerVersionRadio = controllerVersions[1];
    }

    const atmelStr = this.controllerVersionAtmel ? this.controllerVersionAtmel.toInternalString() : "null";
    const radioStr = this.controllerVersionRadio ? this.controllerVersionRadio.toInternalString() : "null";
    log.debug("controller versions - atmel:", atmelStr, "  radio:", radioStr);
  }
  //#endregion controller information

  //#region User Port Data
  /**
   * sends the provided string directly to the brain over the user port if connected
   */
  sendDataUserPort(data: string) {
    this.checkSupported();
    this.checkRequiredConnectionUserPort();
    this.serialConnectionUserPort.writeToSerial(utf8encoder.encode(data));
  }
  //#endregion User Port Data

  //#region firmware
  /**
   * This will get the current firmware version on the servers
   * @returns the VEXos version hosted on the server
   */
  abstract getCurrentFirmwareVersion(): Promise<VexFirmwareVersion>;

  /**
   * This will attempt to update the firmware on the brain
   * 
   * **Note**: For security reasons, we can only trigger a connect prompt after a set time from the user
   * triggering the update. As a result we need the connect prompt callback param. If too much time has
   * passsed and we are unable to call the prompt, we call this to get the user to trigger another action
   * so that we can actually continue the update.
   * @param progress a callback to prvide updates on the current progress
   * @param connectPrompt called to get the user to trigger another event so we can show the connect prompt
   * @returns True if the update was a success
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   * @throws OperationNotSupportedError if the target does not support updating the firmware
   * @throws ErrorUpdatingBrainGolden if there was an error while updating the golden firmware
   * @throws ErrorUpdatingBrainBoot if there was an error while updating the main firmware
   * @throws ErrorUpdatingBrainAssets if there was an error while updating the assets
   */
  updateFirmware(progress: BrainUpdateProgressCallback, connectPrompt: ConnectPromptCallback): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    this.checkRequiredBrainConnection();

    throw new Error("Method not implemented.");
  }

  protected _needsUpdateStateBrain: UpdateNeededOptions = UpdateNeededOptions.Unsure;
  protected _needsUpdateStateController: UpdateNeededOptions = UpdateNeededOptions.Unsure;

  protected get updateNeededBrain(): boolean {
    return this._needsUpdateStateBrain === UpdateNeededOptions.NeedsUpdate;
  }
  protected get updateNeededController(): boolean {
    return this._needsUpdateStateController === UpdateNeededOptions.NeedsUpdate;
  }

  protected async checkUpdateNeeded(): Promise<void> {
    await this.checkUpdateNeededBrain();
    await this.checkUpdateNeededController();
  }

  protected async checkUpdateNeededBrain(): Promise<void> {
    if (this.isDeviceBrain || this.isBrainConnected) {
      if (this._needsUpdateStateBrain !== UpdateNeededOptions.Unsure) {
        return;
      }

      if (this._inDFUMode) {
        log.debug("brain in DFU - needs update");
        this._needsUpdateStateBrain = UpdateNeededOptions.NeedsUpdate;
        return;
      }

      const fw = await this.loadFirmware();
      const manifest = JSON.parse(new TextDecoder().decode(fw.getFile("manifest.json").data));
      log.debug("manifest:", manifest);


      if (!this.brainVersionSystem) {
        await this.fetchBrainInfo()
      }
      if (!this.brainVersionSystem) {
        throw new Error("unable to get brain version");
      }

      log.debug("brain version:", this.brainVersionSystem.toInternalString());
      const serverVersion = await this.getServerVersion();
      const needsUpdate = serverVersion.compare(this.brainVersionSystem) > 0;
      this._needsUpdateStateBrain = needsUpdate ? UpdateNeededOptions.NeedsUpdate : UpdateNeededOptions.UpToDate;

      log.debug("brain update needed state:", UpdateNeededOptions[this._needsUpdateStateBrain]);
    }
  }

  /**
   * checks to see if the controller needs an update and sets the class flag based on the info
   * @returns promes that resolves when the check is complete
   */
  protected async checkUpdateNeededController(): Promise<void> {
    if (this.isDeviceController) {
      if (this._needsUpdateStateController !== UpdateNeededOptions.Unsure) {
        return;
      }

      const fwData = await this.getControllerFirmwareData();
      const atmelLatestVersion = fwData.atmel.version;
      const radioLatestVersion = fwData.radio.version;  

      if (!this.controllerVersionAtmel || !this.controllerVersionRadio) {
        await this.fetchControllerInfo();
      }
      if (!this.controllerVersionAtmel) {
        throw new Error("unable to get controller version");
      }

      const needsUpdateAtmel = atmelLatestVersion.compare(this.controllerVersionAtmel) > 0;
      const needsUpdateRadio = !this.controllerVersionRadio || radioLatestVersion.compare(this.controllerVersionRadio) > 0;

      const needsUpdate = needsUpdateAtmel || needsUpdateRadio;
      log.debug("needsUpdate:", needsUpdate, "needsUpdateAtmel:", needsUpdateAtmel, "needsUpdateRadio:", needsUpdateRadio);
      this._needsUpdateStateController = needsUpdate ? UpdateNeededOptions.NeedsUpdate : UpdateNeededOptions.UpToDate;
      log.debug("controller update needed state:", UpdateNeededOptions[this._needsUpdateStateController]);
    }
  }

  /**
   * Checks what boot source is used. This should only be used with IQ2/EXP/V5
   * @returns where the brain was booted from
   */
  protected checkBootSource(): VEXDeviceBootSource {
    if (!this.isSupported || !this.isConnected || this.isDeviceBrain) {
      return undefined;
    }

    if (this.romBootloaderActive) {
      return VEXDeviceBootSource.ROM_BL;
    } else if(this.ramBootloaderActive) {
      return VEXDeviceBootSource.RAM_BL;
    }
    
    if (this.sysflags[0] == 0 ) {
      return VEXDeviceBootSource.PRIMARY;
    } else if (this.sysflags[0] == 0x80 ) {
      return VEXDeviceBootSource.GOLDEN; 
    } else if (this.sysflags[0] == 0xFF ) {
      return VEXDeviceBootSource.SDCARD;
    }
    return VEXDeviceBootSource.UNKNOWN;
  }

  /**
   * Checks if the golden image needs to be updated. This should only be
   * used with IQ2/EXP/V5
   * @returns false if the golden image needs to be updated
   */
  protected checkGoldenImage() : boolean {
    if (!this.isSupported || !this.isConnected || this.isDeviceBrain) {
      return undefined;
    }

    // sysflags[1] has qspi boot status
    return (this.sysflags[1] & 0x02) ? true : false;
  }

  // TODO: add code to update IQ1?

  /**
   * this is used to update part of a VEX Brain. This should only be used with
   * a IQ2/EXP/V5 Brain
   * @param bin the binary data to load
   * @param updateTarget the target of the update
   * @param progressCB progress update callback
   * @returns true if the update was a success
   * @throws NoBrainConnectedError if there is no VEX Brain connected
   */
  protected async updateBrain(
    bin?: Uint8Array,
    updateTarget?: 0 | 1 | 0xB2,
    progressCB?: (percentage: number, totalBytes: number) => void
  ) {
    this.checkRequiredBrainConnection();

    return new Promise<boolean>((resolve, reject) => {
      if (updateTarget === 0) {
        this.downloadTargetSet(VexDeviceWebSerial.FILE_TARGET_A1);
      } else if (updateTarget === 1) {
        this.downloadTargetSet(VexDeviceWebSerial.FILE_TARGET_B1); 
      } else if (updateTarget === 0xB2) {
        this.downloadTargetSet(VexDeviceWebSerial.FILE_TARGET_B2);
      } else {
        log.debug("unexpected update target:", updateTarget);
      }
  
      this.downloadProgramData("null", bin, undefined, undefined, progressCB, (result) => {
        resolve(result);
        this.downloadTargetSet(VexDeviceWebSerial.FILE_TARGET_QSPI);
        this.downloadAddressSet(VexDeviceWebSerial.USR_ADDRESS);
      });
    });
  }

  // TODO: finish this function. same name in iqserialchrome file vexv5.ts
  /**
   * force update a device connected to the specified port
   * @param deviceId 
   * @param deviceUpdateDelay 
   */
  protected forceDeviceUpdate( deviceId: number, deviceUpdateDelay?: number ) {

  }
  //#endregion firmware

  //#region firmware file data
  protected _fw: VexFW = null;
  protected _fwChannel: VEXosType = VEXosType.PUBLIC;
  
  protected clearLoadedFirmware() {
    this._fw = null;
  }
  /**
   * configure which firmware channel should be used when checking the version online
   * @param channel the channel to use
   */
  setFirmwareChannel(channel: VEXosType) {
    if (this._fwChannel !== channel) {
      this.clearLoadedFirmware();
      this._fwChannel = channel;
    }
  }
  /**
   * Get instance of VexFW for the configured channel and platfrom
   * @param progressCB callback that reports download progress of the vexos file
   * @returns instance of VexFW to use to get firmware data
   */
  async loadFirmware(progressCB?: (progress: number) => void) {
    const sendProgress = (progress: number) => {
      log.debug("loadFirmwareFile progress:", progress);
      if (progressCB) {
        progressCB(progress);
      }
    }

    if (this._fw && this._fw.isLoaded()) {
      log.debug("found existing firmware data loaded");
      return this._fw;
    }

    // TODO: make sure the FW class is correct
    if (!this._fw) {
      log.debug("creating new FW object")
      this._fw = this.createVexFWInstance();
    }

    // TODO: preload/cache the firmware?
    log.debug("fetching version info from server")
    await this._fw.getVexosVersions();
    const latest = this._fw.getLatest(this._fwChannel);
    log.debug("loading the vexos file");
    await this._fw.downloadFirmware(latest.name, this._fwChannel, sendProgress);

    return this._fw;
  }

  async getServerVersion(): Promise<VexFirmwareVersion> {
    const fw = await this.loadFirmware();
    const latest = fw.getLatest(this._fwChannel);
    return new VexFirmwareVersion(latest.major, latest.minor, latest.build, latest.beta);
  }

  /**
   * creates a clean new instance of the VexFW class for the current platfrom
   */
  protected abstract createVexFWInstance(): VexFW;
  //#endregion firmware file data

  //#region user data
  /**
   * This will send the provided data to the user data channel on the brain
   * @param data the string to send to the connected brain
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  sendUserData(data: string): Promise<void> {
    this.checkSupported();
    this.checkRequiredConnection();
    throw new Error("Method not implemented.");
  }
  //#endregion user data

  //#region project controls
  /**
   * this will tell the brain to play the program loaded in the specified slot.
   * @param slot the slot to play. 0 indexed
   * @returns true if the process was a success
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  play(slot: number): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    return new Promise<boolean>((resolve, reject) => {
      // 0 index based on brain
      slot = slot < 0  ? 0 : slot;
      slot = slot > 7  ? 7 : slot;

      // We can play a slot using special file name "___s_00.bin" etc.
      let name = '___s_' + ('00' + slot.toString(10)).substr(-2,2) + '.bin';

      this.writeDataAsync( this.cdc.V5_Cdc2FileLoadAndRun(VexDeviceWebSerial.VID.USER, 0, name))
        .then(() => {
          resolve(true);
        })
        .catch(()=> {
          reject();
        });
    });
  }

  /**
   * this will tell the brain to stop a running program
   * @returns true if the process was a success
   * @throws WebSerialUnsupportedError if Web Serial is not supported on your browser
   * @throws NoBrainConnectedError if there is not open connection
   */
  stop(): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();
    return new Promise((resolve, reject) => {
      this.writeDataAsync( this.cdc.V5_Cdc2FileLoadAndRun(VexDeviceWebSerial.VID.USER, VexDeviceWebSerial.OPTIONS.LOAD_STOP, 'null'))
        .then(() => {
          resolve(true);
        })
        .catch(()=> {
          reject();
        });
    });
  }
  //#endregion project controls

  //#region downloads
  async downloadProgram(data: ArrayBuffer, info: IProjectInformation, progressCallback: ProgressCallbackDownload): Promise<boolean> {
    this.checkSupported();
    this.checkRequiredConnection();

    if (info.slot < 0 || info.slot > 7) {
      log.warn("slot is out of range");
      return false;
    }

    return new Promise(async (resolve, reject) => {
      const buffer = new Uint8Array(data);
      const path = `slot_${info.slot+1}`;

      // create inifile
      const ini: VexINI = new VexINI();
      
      ini.programSlotSet(info.slot);
      ini.programNameSet(info.name);
      ini.programDescriptionSet(info.description);
      ini.programIconSet(info.icon);
      ini.projectIdeSet(info.ide);

      for (const port of info.ports) {
        if (port && port.port >= 1 && port.port <= 21 && port.label) {
          ini.addPortConfig(port.port, port.label);
        }
      }

      for (const port of info.triports) {
        if (port &&
          port.port >= 1 && port.port <= 21 && 
          port.subport >= 0 && port.subport <= 7 &&
          port.label
        ) {
          ini.addAdiPortConfig(port.port, port.subport, port.label);
        }
      }

      let controllerKey: ControllerButtonName;
      if (info.controller1) {
        for (controllerKey in info.controller1) {
          ini.addControllerConfig(0, controllerKey, info.controller1[controllerKey]);
        }
      }
      if (info.controller2) {
        for (controllerKey in info.controller2) {
          ini.addControllerConfig(1, controllerKey, info.controller2[controllerKey]);
        }
      }


      let vmUpdateNeeded = false;
      let downloadState: DownloadState = DownloadState.None;
      let vmProgress = 0;
      let progProgress =0;
      const callBackAggregator = (progress: number) => {
        let finalProgress = 0;
        //error check
        if (progress == -1) {
          finalProgress = -1;
        } else if (vmUpdateNeeded) {
          finalProgress = (progProgress + vmProgress) / 2;
        } else {
          finalProgress = progProgress;
        }
        if (progressCallback) {
          progressCallback({ "progress": finalProgress, "state": downloadState });
        }
      }
      const callBackProgramDownload =  (progress: number)=> {
        downloadState = DownloadState.DownloadingProgram;
        progProgress = progress;
        callBackAggregator(progress);
      }
      const callBackVMDownload = (data: DownloadProgress) => {
        downloadState = data.state;
        if (data.state == DownloadState.DownloadingVM) {
          vmUpdateNeeded = true;
          vmProgress = data.progress;
          callBackAggregator(data.progress);
        }
      }

      let exttype = 0;

      const inifile: Uint8Array = new TextEncoder().encode(ini.createIni());

      log.debug("program options/ini parameters : ", info);

      if (info.language === "python") {
        log.debug("checking python");
        // check and install/update the Python VM if needed
        const meta = this.getVmMeta();
        const vmResult = await this.checkAndInstallPythonVm(meta.crc, meta.version, callBackVMDownload);
        if (vmResult.err !== 0) {
          throw new NoPythonVMError();
        }
        await this.delay(1000);

        // configure the download for the 
        const linkInfo = this.getVMLinkInfo();
        this.downloadAddressSet(linkInfo.loadaddr);
        this.linkfileSet(linkInfo.linkfilevid, linkInfo.linkfile);
        exttype = linkInfo.exttype;
      } else {
        // this should not be needed, but it should not hurt
        this.downloadAddressSet( VexDeviceWebSerial.USR_ADDRESS );
      }

      // download the user program
      log.info("downloading user project", this.downloadAddress);
      const autorun = info && info.autorun ? info.autorun : false;
      this.downloadAutorunSet(autorun);
      this.downloadProgramData( path, buffer, inifile, undefined, callBackProgramDownload, (status: any) => {
        // put internal autorun back to default
        this.downloadAutorunSet( true );
        this.downloadAddressSet( VexDeviceWebSerial.USR_ADDRESS );
        if( status === true )
          resolve(true); // SUCCESS
        else
          resolve(false); // ERROR_DOWNLOAD
      }, exttype )
      
    });
  }
  //#endregion downloads

  //#region brain files
  /**
   * get catalog (ie. user programs) from V5
   * This version a little different from the old rmserial
   * as it returns raw files as well as programs
   */
  list() : Promise<listReply> {
    return new Promise((resolve, reject) => {
      this.getDirectory()
      .then( (entries)  => {
        const programInfo = [];

        const filterIni = (entry: fileGetDirEntryReply) => {
          let re = /.ini$/;
          return entry.name.search( re ) > 0;
        }

        // Find all the ini files
        const iniFiles = entries.filter(filterIni);

        const date = new Date();

        // Noe make sure we have a corresponding bin file
        for (let i=0; i<iniFiles.length; i++) {
          // get the base name
          const re = /(.+?)(\.[^.]*$|$)/;
          const name = re.exec( iniFiles[i].name )[1];
          const binfile = entries.filter(entry => entry.name === name + '.bin');

          // did we find it ?
          if (binfile && binfile.length === 1) {
            // Date needs milliseconds, we have seconds
            date.setTime(binfile[0].timestamp * 1000);

            const p: programInfo = {
              name:    name,
              binfile: binfile[0].name,
              size:    iniFiles[i].size + binfile[0].size,
              slot:    -1,
              time:    date.toLocaleString(),
              requestedSlot: -1,
            }
            programInfo.push(p);    
          }
        }

        this.getuserProgramStatus(programInfo)
          .then((programs) => {
            resolve({files: entries, programs: programs});
          });
      })
    })
  }

  /**
   * Delete all files with matching basename
   * @param basename 
   */
  delete(basename: string) : Promise<number> {
    return new Promise((resolve, reject) => {
      this.writeDataAsync(this.cdc.query1())
        .then((reply:ArrayBuffer) => {
          return this.writeDataAsync(this.cdc.V5_Cdc2FileErase(VexDeviceWebSerial.VID.USER, VexDeviceWebSerial.OPTIONS.ERASE_AL_BNAME, basename));
        })
        .then((reply:ArrayBuffer) => {
          return this.writeDataAsync(this.cdc.V5_Cdc2FileExit(0), {timeout: 1000});
        })
        .then((reply:ArrayBuffer) => {
          resolve( SUCCESS );
        })
        .catch((reply:ArrayBuffer) => {
          resolve( ERROR_DELETE );
        });
    });
  }

  /**
   * Send new screen/dashboard on the V5
   * @param id 
   */
  brainActivateScreen(id: number, port?: number) {
    return new Promise((resolve, reject) => {
      this.writeDataAsync(this.cdc.V5_Cdc2DashSelect(id, port))
        .then((reply:ArrayBuffer) => {
          resolve(id);
        })
        .catch(() => {
          // perhaps we don't support this yet
          resolve(undefined);
        });
    });
  }

  /**
   * Get directory from user folder on V5
   */
  getDirectory(vid?: number): Promise<fileGetDirEntryReply[]>{
    return new Promise((resolve, reject) => {
      const entries: fileGetDirEntryReply[] = [];

      vid = vid === undefined ?  VexDeviceWebSerial.VID.USER : vid;

      this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;
      
      // get whole directory
      this.writeDataAsync(this.cdc.query1())
        .then((reply:ArrayBuffer) => {
          return this.writeDataAsync(this.cdc.V5_Cdc2FileDir(vid, 0));
        })
        .then((reply:ArrayBuffer) => {
          let rep = this.decodeFileGetDirectoryReply( reply );
          if( rep.ack === VexCDC.CDC2_ACK_TYPES.CDC2_ACK ) {
            const dirCount: number      = rep.count;
            let dirEntryIndex: number = 0;

            // Now itterate to get all entries
            return new Promise((resolve, reject) => {
              // Get one dir entry
              var getDirEntry = () => {
                // send command
                this.writeDataAsync(this.cdc.V5_Cdc2FileDirEntry(dirEntryIndex))
                  .then((reply:ArrayBuffer) => {
                    const rep = this.decodeFileGetDirectoryEntryReply( reply );
                    if (rep.ack === VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
                      dirEntryIndex++;

                      //this.log( rep.name );
                      
                      entries.push( rep );

                      if (dirEntryIndex >= dirCount) {
                        resolve(reply);
                      } else {
                        getDirEntry();
                      }
                    } else {
                      reject(reply);
                    }
                  })
                  .catch((reply:ArrayBuffer) => {
                    reject(reply);
                  });
              }

            // Get first entry
            if (dirCount > 0) {
              getDirEntry();
            } else {
              resolve(undefined);
            }
            });
          } else {
            this.decodeFileNack(rep.ack);
          }
        })
        .then((reply: ArrayBuffer) => {
          resolve(entries);
        })
        .catch((reply: ArrayBuffer) => {
          resolve([]);
        });
    }); 
  }

  /*----------------------------------------------------------------------------*/

  /**
   * Get user program slots
   */
  getuserProgramStatus(programs: programInfo[]): Promise<programInfo[]> {
    return new Promise((resolve, reject) => {
      if (programs === undefined || programs.length === 0) {
        resolve(undefined);
        return;
      }

      this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;

      // get whole directory
      this.writeDataAsync(this.cdc.query1())
        .then((reply: ArrayBuffer) => {
          // Now itterate to get all entries
          return new Promise((resolve, reject) => {
            let programEntryIndex = 0;

            // Get one dir entry
            var getUserProgramEntry = () => {
              // send command
              this.writeDataAsync( this.cdc.V5_Cdc2FileUserStatus( VexDeviceWebSerial.VID.USER, 0, programs[programEntryIndex].binfile )  )
                .then((reply:ArrayBuffer) => {
                  const rep = this.decodeFileGetUserStatusReply( reply );
                  if (rep.ack === VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
                    programs[programEntryIndex].slot =  rep.slot;
                    programs[programEntryIndex].requestedSlot =  rep.requestedSlot;

                    programEntryIndex++;
                    
                    if (programEntryIndex >= programs.length) {
                      resolve(undefined);
                    } else {
                      getUserProgramEntry();
                    }
                  } else {
                    reject(undefined);
                  }
                })
                .catch((reply:ArrayBuffer) => {
                  reject(undefined);
                });
            }

            // Get first entry
            if (programs.length > 0) {
              getUserProgramEntry();
            }
          });          
        })
        .then(() => {
          resolve(programs);
        })
        .catch(() => {
          resolve( [] );
        });
    });
  }
  //#endregion brain files

  //#region file metadata helpers
  /**
   * Decode a get metadata reply, same format as dir entry
   * @param msg the CDC2 reply to decode
   */
   protected decodeFileGetMetadataReply(msg: Uint8Array | ArrayBuffer): fileGetMetadataReply {
    let rep: fileGetMetadataReply = undefined;
    const tmp = this.decodeFileGetDirectoryEntryReply(msg);
    if (tmp !== undefined) {
      rep = {
        ack       : tmp.ack,
        linkvid   : tmp.index,
        size      : tmp.size,
        loadAddr  : tmp.loadAddr,
        crc32     : tmp.crc32,
        type      : tmp.type,
        timestamp : tmp.timestamp,
        version   : tmp.version,
        linkname  : tmp.name
      }
    }
    return rep;
  }

  /**
   * Get metadata for named file
   * @param name the name of the file
   * @param vid the vid for the file
   * @returns the metadata for the specified file
   */
  protected async getProgramMetadata(name: string, vid: number): Promise<fileGetMetadataReply | undefined> {
    // was there any folder at all ?
    if (name.match('^....\/') !== null) {
      name = name.slice(5);
    }
    this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;
    try {
      await this.writeDataAsync(this.cdc.query1());
      const metadataResp = await this.writeDataAsync(this.cdc.V5_Cdc2FileMetadataGet(vid, 0, name));
      log.debug("metadataResp:", metadataResp);
      const rep: fileGetMetadataReply = this.decodeFileGetMetadataReply(metadataResp);
      if (rep.ack === VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
        return rep;
      } else {
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  }
  //#endregion file metadata helpers

  //#region brain info meta data helpers
  /**
   * Get the robot system flag status
   */
  brainGetSystemFlags(canBeConnecting: boolean = false):Promise<number> {
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);

    log.debug("brainGetSystemFlags");

    this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;

    return new Promise((resolve, reject) => {
      this.writeDataAsync( this.cdc.V5_Cdc2FlagsStatus() )
      .then((reply:ArrayBuffer) => {
        if( reply !== undefined )
          resolve( this.decodeSysFlagsRead( reply ) );
        else
          resolve(undefined);
      })
      .catch(() => {
        // perhaps we don't support this yet
        resolve(undefined);
      })
    });
  }

  /**
   * Get the robot system status
   */
  brainGetSystemStatus(canBeConnecting: boolean = false) : Promise<ArrayBuffer> {
    this.checkSupported();
    this.checkRequiredConnection(canBeConnecting);
    
    log.debug("brainGetSystemStatus");

    this.uniqueId = 0;
    return new Promise((resolve, reject) => {
      this.writeDataAsync( this.cdc.V5_Cdc2SystemStatus() )
      .then((reply:ArrayBuffer) => {
        this.decodeSysStatusRead( reply );
        resolve(reply);        
      })
      .catch(() => {
        // perhaps we don't support this yet
        resolve(undefined);
      })
    });
  }
  //#endregion brain info meta data helpers
  
  //#region Python vm helpers
  /**
   * get information about how to link to the Python VM
   * @returns 
   */
  protected abstract getVMLinkInfo(): IPythonProgramLinkInfo;

  /**
   * 
   * @returns the meta data for the Python VM
   */
  protected abstract getVmMeta(): { crc: number, version: number };

  protected abstract getPythonVMResourcePath(): string;

  protected abstract getPythonVmDownloadInfo(): IPythonVMDownloadInfo;

  protected abstract postVMDownloadCleanup(): void;

  private async checkPythonVm(name: string, crc: number, version: number): Promise<{exists: boolean, valid: boolean}> {
    return new Promise<{exists: boolean, valid: boolean}>(async (resolve, reject) => {
      try {
        // unofficial way to get access to serial device
        const metadata = await this.getProgramMetadata(name, VexDeviceWebSerial.VID.VEXVM);
        log.debug("metadata:", metadata);
        if (metadata === undefined) {
          // no VM
          // serial.reset();
          log.debug("found no VM");
          resolve({ exists: false, valid: false });
        }
        else {
          log.debug("Python VM metadata: ", JSON.stringify(metadata, null, 2));
          if (metadata.crc32 === crc && metadata.version === version) {
            // Valid VM
            log.debug("found valid VM");
            resolve({ exists: true, valid: true });
          } else {
            // invalid VM
            log.debug("found invalid VM");
            resolve({ exists: true, valid: false });
          }
        }

      } catch (e) {
        log.error("error on checking VM ", e);
        reject({err: -1, data: e, msg: "Error on checking VM"} as IResult);
      }
    });
  }

  private async getPythonVMFile(): Promise<IResult> {
    return new Promise<IResult>(async (resolve, reject) => {
      try {
        const vm_bin_path = this.getPythonVMResourcePath();
        const response = await fetch(vm_bin_path);
        const vm_binary = await response.blob();
        if (vm_binary !== undefined) {
          var fileReader = new FileReader();
          fileReader.readAsArrayBuffer(vm_binary);
          // Load the file data
          fileReader.onload = (event) => {
            if (fileReader.readyState === fileReader.DONE) {
              let binfile: Uint8Array = new Uint8Array(<ArrayBuffer>fileReader.result);
              let result: IResult = { err: 0, msg: "VM file read successfully!", data: binfile };
              resolve(result);
            }
          }
          fileReader.onerror = () => {
            let result: IResult = { err: -1, msg: "could not read Python VM from Application's /resources", data: "" };
            reject(result);
          }
        }
        else {
          log.error("could not find Python VM in Application's /resources");
          let result: IResult = { err: -1, msg: "could not find Python VM from Application's /resources", data: "" };
          reject(result);
        }
      } catch (e) {
        log.error("Error when reading Python VM from  Application's /resources");
        let result: IResult = { err: -1, msg: "Error when reading Python VM from  Application's /resources", data: "" };
        reject(result);
      }
    });

  }

  async forceInstallPythonVM(progressCallback: ProgressCallbackDownload) {
    const meta = this.getVmMeta();
    await this.checkAndInstallPythonVm(meta.crc, meta.version, progressCallback);

  }

  private async checkAndInstallPythonVm(crc: number, version: number, progressCallback?: ProgressCallbackDownload, force = false): Promise<IResult> {
    if (progressCallback) {
      progressCallback({"progress": 0, "state": DownloadState.CheckingVM});
    }

    const vmCheckResult = await this.checkPythonVm("python_vm.bin", crc, version);
    log.info("VM available check : ", vmCheckResult);
    if (vmCheckResult) {
      if (!vmCheckResult.exists || (vmCheckResult.exists && !vmCheckResult.valid) || force) {
        log.info("downloading Python VM to brain...");
        // download progress
        const onProgress = (value: number, total: number) => {
          if (progressCallback) {
            progressCallback({"progress": value, "state": DownloadState.DownloadingVM});
          }
        }
        const vm_content = await this.getPythonVMFile();
        log.debug(vm_content);
        if (vm_content && vm_content.err == 0 && vm_content.data) {
          // save autorun setting
          const current_ar_setting = this.downloadAutorun;
          this.downloadAutorun = 0;
          // V5 configuration as default
          let vid = VexDeviceWebSerial.VID.VEXVM;
          const exttype = 0x61;
          const downloadInfo = this.getPythonVmDownloadInfo();
          log.debug("downloadInfo:", downloadInfo);
          if (downloadInfo.address) {
            this.downloadAddressSet(downloadInfo.address);
          }
          if (downloadInfo.target) {
            this.downloadTargetSet(downloadInfo.target);
          }
          if (downloadInfo.vid) {
            vid = downloadInfo.vid;
          } 
          if (downloadInfo.version) {
            // we set this here, it will be reset back to 1 after download
            this.cdc.V5_Cdc2SetFileVersion(downloadInfo.version);
          } else {
            this.cdc.V5_Cdc2SetFileVersion(1);
          }
          log.debug("vid:", vid);
          
          // TODO: do not try to download the VM over the controller

          // send data to brain
          try {
            const downloadStatus = await this.downloadDataAsync('python_vm.bin', vm_content.data, onProgress, vid, exttype);

            log.debug("VM downloadStatus:", downloadStatus);
            if (!downloadStatus) {
              throw false;
            }

            // restore autorun setting
            this.downloadAutorun = current_ar_setting;
            let result: IResult = { err: 0, msg: "VM download successful", data: downloadStatus };
            log.info("VM download successful");
            this.postVMDownloadCleanup();
            return result;
          } catch (err) {
            // restore autorun setting
            this.downloadAutorun = current_ar_setting;
            let result: IResult = { err: -1, msg: "VM download error", data: err };
            log.error("VM download error");
            this.postVMDownloadCleanup();
            return result;
          }
        }
      }
      else {
          let result: IResult = { err: 0, msg: "valid VM already exists on the brain", data: "" };
          log.info("valid VM already exists on the brain");
          return result;
      }
    } else {
      log.error("VM available check failed");
      return { err: -2, msg: "VM check failed", data: null }
    }
  }
  //#endregion Python vm helpers

  //#region controller firmware
  // TODO: add options to provide local vexos file
  /**
   * updates a connected IQ2/EXP controller
   * 
   * **Note**: For security reasons, we can only trigger a connect prompt after a set time from the user
   * triggering the update. As a result we need the connect prompt callback param. If too much time has
   * passsed and we are unable to call the prompt, we call this to get the user to trigger another action
   * so that we can actually continue the update.
   * @param progressCB callback with update progress information
   * @param connectPrompt called to get the user to trigger another event so we can show the connect prompt
   * @param inDFU true if you want to connect to a controller that is already in DFU mode and needs to be recovered
   * @param force if the firmware should be updated even if it does not need an update
   * @returns a promies that will resolve when the update is complete
   */
  async controllerUpdate(
    progressCB: ControllerUpdateProgressCallback,
    connectPrompt: ConnectPromptCallback,
    inDFU: boolean = false,
    force: boolean = false,
  ) {

    if (!inDFU) {
      this.checkForconnectedController();
    } else {
      log.debug("skip connection check since we are updating a DFU controller");
    }

    // set to true for now for the correct progress reports
    let needsAtmelUpdate = true;
    let needsRadioUpdate = true;
    let needsUpdate = true

    let radioInUpdateMode = false;
    let updateRadioFirst = false;

    let lastProgress = {
      state: VEXControllerUpdateStates.done,
      prog: -1, 
      msg: "",
    };
    const sendProgress: ControllerUpdateProgressCallback = (state, prog, msg) => {
      const roundedProgress = Math.round(prog * 100);
      if (lastProgress.state === state && lastProgress.msg === msg && lastProgress.prog === roundedProgress) {
        // no point sending this update as it is the same as the previous update
        return;
      }
      lastProgress = {state, prog: roundedProgress, msg};

      let totalProgress = state === VEXControllerUpdateStates.done ? 1 : 0;
      if (needsAtmelUpdate && needsRadioUpdate) {
        const totalStages = 6;
        if (updateRadioFirst) {
          switch (state) {
            case VEXControllerUpdateStates.UpdatingRadio:
              totalProgress = (1 + prog) / totalStages
              break;
            case VEXControllerUpdateStates.WaitingForRadio:
              totalProgress = (2 + prog) / totalStages
              break;
            case VEXControllerUpdateStates.EnteringDFU:
              totalProgress = (3 + prog) / totalStages
              break;
            case VEXControllerUpdateStates.UpdatingAtmel:
              totalProgress = (4 + prog) / totalStages
              break;
            case VEXControllerUpdateStates.WaitingForReboot:
              totalProgress = (5 + prog) / totalStages
              break;
            default:
              totalProgress = (state - 1 + prog) / totalStages
              break;
          }
        } else {
          totalProgress = (state - 1 + prog) / totalStages
        }
      } else if (needsAtmelUpdate) {
        const totalStages = 4;
        totalProgress = (state - 1 + prog) / totalStages
      } else if (needsRadioUpdate) {
        const totalStages = 3;
        const offset = (state > 1 ? state - 3 : state) - 1;
        totalProgress = (offset + prog) / totalStages
      }

      log.debug("sendProgress", VEXControllerUpdateStates[state], totalProgress, msg);
      if (progressCB) {
        progressCB(state, totalProgress, msg);
      }
    }

    const fwData = await this.getControllerFirmwareData(sendProgress);
    log.debug("fwData:", fwData);
    const atmelLatestVersion = fwData.atmel.version;
    const radioLatestVersion = fwData.radio.version;

    const checkControllerUpdates = async () => {
      // process the device versions now that we have the fw
      await this.fetchControllerInfo();
      // const controllerDeviceVersionRequestPromise = this.controllerVersionsGet();
      const atmelDeviceVersion = this.controllerVersionAtmel;
      const radioDeviceVersion = this.controllerVersionRadio;

      try {
        log.debug("atmel latest:", atmelLatestVersion?.toInternalString(), "device:", atmelDeviceVersion?.toInternalString());
      } catch (err) {}
      try {
        log.debug("radio latest:", radioLatestVersion?.toInternalString(), "device:", radioDeviceVersion?.toInternalString());
      } catch (err) {}

      const hasAtmelVersion = atmelDeviceVersion !== undefined && atmelDeviceVersion !== null;
      const hasRadioVersion = radioDeviceVersion !== undefined && radioDeviceVersion !== null;
      radioInUpdateMode = !inDFU && !hasRadioVersion;
      needsAtmelUpdate = force || !hasAtmelVersion || atmelLatestVersion.compare(atmelDeviceVersion) > 0;
      needsRadioUpdate = force || !hasRadioVersion || radioLatestVersion.compare(radioDeviceVersion) > 0;
      needsUpdate = needsAtmelUpdate || needsRadioUpdate;
      
      log.debug("needsUpdate:", needsUpdate, "needsAtmelUpdate:", needsAtmelUpdate, "needsRadioUpdate:", needsRadioUpdate);
    }

    // if we are not in DFU, we should check the versions now
    if (!inDFU) {
      await checkControllerUpdates()
    } else {
      log.debug("skipping version checks since we are in DFU mode");
    }

    const atmelFWBin = fwData.atmel.bin;
    const radioFWBin = fwData.radio.bin;

    if (!needsUpdate) {
      log.info("there is nothing to update...");
      sendProgress(VEXControllerUpdateStates.done, 0, "Update Complete");
      return;
    }

    try {
      if (!inDFU && radioInUpdateMode) {
        updateRadioFirst = true
        log.info("radio stuck in update mode. update radio first");
        await this.controllerUpdateRadio(sendProgress, radioFWBin);
        await checkControllerUpdates();
      }

      if (needsAtmelUpdate) {
        this.isUpdatingFirmware = true;
        if (inDFU) {
          (this as any).deviceType = "EXPController";
          this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Connecting);
        }
        await this.controllerUpdateAtmel(sendProgress, connectPrompt, atmelFWBin, inDFU);
        if (inDFU) {
          this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Connected);
        }
        this.isUpdatingFirmware = false;
      }

      if (inDFU) {
        log.warn("check controller versions after DFU");
        // now that we are out of DFU mode (?) we should check to see if the radio needs to be updated
        await checkControllerUpdates();
      }

      if (needsRadioUpdate) {
        await this.controllerUpdateRadio(sendProgress, radioFWBin);
      }

      this._needsUpdateStateController = UpdateNeededOptions.Unsure;

      sendProgress(VEXControllerUpdateStates.done, 0, "Update Complete");

      this.isUpdatingFirmware = false;

      setTimeout(async () => {
        await this.fetchControllerInfo();
        await this.checkUpdateNeeded();
        this.fireEvent("deviceInfoUpdated", this.getBrainInfo());
      }, 200);

    } catch (err) {
      log.error(err);
      this.isUpdatingFirmware = false;
      if (inDFU) {
        this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Disconnected);
      }
      throw err;
    }
  }

  async getControllerFirmwareData(
    progressCB?: ControllerUpdateProgressCallback,
  ) {
    const sendProgress: ControllerUpdateProgressCallback = (state, prog, msg) => {
      log.debug("firmware data fetch progress:", VEXControllerUpdateStates[state], state, prog, msg);
      if (progressCB) {
        progressCB(state, prog, msg);
      }
    }

    sendProgress(VEXControllerUpdateStates.LoadingFirmwareFile, 0, "Loading Firmware File");
    const fw = await this.loadFirmware((prog) => {
      sendProgress(VEXControllerUpdateStates.LoadingFirmwareFile, prog, "Loading Firmware File");
    });

    sendProgress(VEXControllerUpdateStates.LoadingFirmwareFile, 1, "Parsing Firmware File");

    // TODO: preload/cache the firmware?
    const manifest = JSON.parse(new TextDecoder().decode(fw.getFile("manifest.json").data));
    log.debug("manifest:", manifest);
    const atmelLatestVersion = VexFirmwareVersion.fromString(manifest.ctrl.cdc.version);
    const radioLatestVersion = VexFirmwareVersion.fromString(manifest.ctrl.radio.version);
    const atmelFWBin = fw.getFile(manifest.ctrl.cdc.file).data;
    const radioFWBin = fw.getFile(manifest.ctrl.radio.file).data;

    return {
      atmel: {
        version: atmelLatestVersion,
        bin: atmelFWBin
      },
      radio: {
        version: radioLatestVersion,
        bin: radioFWBin
      }
    }

  }

  /**
   * This will update the atmel chip on a IQ2 or EXP controller.
   * 
   * **Note**: For security reasons, we can only trigger a connect prompt after a set time from the user
   * triggering the update. As a result we need the connect prompt callback param. If too much time has
   * passsed and we are unable to call the prompt, we call this to get the user to trigger another action
   * so that we can actually continue the update.
   * @param progressCB callback for when there is an progress update
   * @param connectPrompt called to get the user to trigger another event so we can show the connect prompt
   * @param atmelFWBin the bin to load on the atmel chip
   * @param inDFU is the controller already in DFU mode
   * @param requireReconnect do we need to reconnect at the end?
   */
  async controllerUpdateAtmel(
    progressCB: ControllerUpdateProgressCallback,
    connectPrompt: ConnectPromptCallback,
    atmelFWBin: Uint8Array,
    inDFU: boolean = false,
    requireReconnect: boolean = true,
  ) {
    const sendProgress: ControllerUpdateProgressCallback = (state, prog, msg) => {
      // log.debug("atmle update progress:", VEXControllerUpdateStates[state], prog, msg);
      if (progressCB) {
        progressCB(state, prog, msg);
      }
    }

    if (!inDFU) {
      sendProgress(VEXControllerUpdateStates.EnteringDFU, 0, "Entering DFU");
      await this.controllerEnterDFU();
      this.delay(250);
      sendProgress(VEXControllerUpdateStates.EnteringDFU, 0.5, "Waiting For Reconnect");
    } else {
      sendProgress(VEXControllerUpdateStates.EnteringDFU, 0, "Waiting For Connection");
    }
    const vexDFU = new VexDFU(this.dfuTarget);
    // TODO: make sure we actually connected. controller will be stuck in DFU mode if we don't complete the update
    await connectPrompt(true, true);
    await vexDFU.openConnection();
    while (!vexDFU.device) {
      await this.delay(250);
      if (await connectPrompt(false, true)) {
        await vexDFU.openConnection();
      } else {
        throw new UpdateCanceled();
      }
    }
    
    sendProgress(VEXControllerUpdateStates.UpdatingAtmel, 0, "Updating Firmware");
    await vexDFU.updateDevice(atmelFWBin, (prog) => {
      sendProgress(VEXControllerUpdateStates.UpdatingAtmel, prog, "Updating Firmware");
    })
    sendProgress(VEXControllerUpdateStates.WaitingForReboot, 0, "Rebooting");
    await this.delay(100);
    sendProgress(VEXControllerUpdateStates.WaitingForReboot, 0.5, "Waiting serial For Reconnect");

    let reconnected = false;
    let isFirstConnect = true;
    while (!reconnected) {
      try {
        if (!await connectPrompt(isFirstConnect, false)) {
          throw new UpdateCanceled();
        }
        isFirstConnect = false;
        await this.reconnect();
        reconnected = true;
      } catch(err) {
        if (err instanceof DOMException) {
          if (err.name === "SecurityError") {
            log.warn("failed to open connect prompt due to security permissions");
            // await connectPrompt();
          } else if (err.name === "NotFoundError") {
            log.warn("user hit cancel")
            if (requireReconnect) {
              // await connectPrompt();
            } else {
              reconnected = true;
            }
          }
        } else {
          log.error("error name:", err.name, err);
          throw err;
        }
      }
    }

    // TODO: use event to know when the connection is ready
    await this.delay(500);

  }

  async controllerUpdateRadio(
    progressCB: ControllerUpdateProgressCallback,
    radioFWBin: Uint8Array,
  ) {
    const sendProgress: ControllerUpdateProgressCallback = (state, prog, msg) => {
      // log.debug("radio update progress:", VEXControllerUpdateStates[state], prog, msg);
      if (progressCB) {
        progressCB(state, prog, msg);
      }
    }

    sendProgress(VEXControllerUpdateStates.UpdatingRadio, 0, "Entering Radio Update Mode");
    await this.controllerRadioBootloaderEntry(true);
    await this.delay(1300);

    log.debug("create radio updater");
    const radioDFU = new cc264xdfu(this.writeDataRaw.bind(this), undefined, this.radioChipId);
    log.debug("starting radio update");
    try {
      await radioDFU.radioUpdate(radioFWBin, (prog) => {
        sendProgress(VEXControllerUpdateStates.UpdatingRadio, prog, "Updating Radio");
      });
    } catch(err) {
      // log.error("Radio update failed. error code:", err);
      throw new Error("Radio update failed. error code: " + err);
    }

    sendProgress(VEXControllerUpdateStates.WaitingForRadio, 0, "Waiting For Radio Reboot");
    await this.delay(2000);
    sendProgress(VEXControllerUpdateStates.WaitingForRadio, 0.3, "Waiting For Radio Reboot");
    await this.controllerRadioBootloaderExit(true);
    sendProgress(VEXControllerUpdateStates.WaitingForRadio, 0.6, "Waiting For Radio Reboot");
    await this.delay(1000);
    sendProgress(VEXControllerUpdateStates.WaitingForRadio, 1, "Waiting For Radio Reboot");
  }

  // private async controllerCheckFwUpdateNeeded() {
  //   this.checkForconnectedController();

  //   const output: {
  //     atmel?: VexFirmwareVersion,
  //     radio?: VexFirmwareVersion,
  //   } = {};

  //   //  we should pull this on connection and only use this if the connect data is not there
  //   const atmelStatus = await this.controllerAtmelStatus();
  //   if (atmelStatus) {
  //     if (atmelStatus.version) {
  //       output.atmel = atmelStatus.version;
  //     }
  //     if(atmelStatus.status == 0x20){
  //       log.info("radio stuck in bootload");
  //       output.radio = VexFirmwareVersion.allZero();
  //     } else {
  //       const versions = await this.controllerVersionsGet();
  //       output.radio = versions[1];
  //     }
  //   }

  // }

  protected abstract get radioChipId(): number;
  protected abstract get dfuTarget(): DFUTargetDevice;
  //#endregion controller firmware
  
  //#region controller comms
  protected checkForconnectedController(force?: boolean, canBeConnecting: boolean = false) {
    if (!canBeConnecting && !this.isConnected) {
      throw new NoControllerConnectedError();
    }

    if (force !== undefined) {
      if (!force && !this.isDeviceController) {
        throw new NoControllerConnectedError();
      }    
    } else {
      if (!this.isDeviceController) {
        throw new NoControllerConnectedError();
      }      
    }
  }

  protected controllerVersionsGet(): Promise<VexFirmwareVersion[]> {
    return new Promise((resolve, reject) => {
      try {
        this.checkForconnectedController(undefined, true);
      } catch (err) {
        reject(err);
      }
      
      this.writeDataAsync(this.cdc.IQ2_Cdc2ControllerVersions())
        .then((reply: ArrayBuffer) => {
          const versions = this.decodeControllerVersionsReply(reply);
          resolve(versions);        
        })
        .catch(() => {
          // perhaps we don't support this yet
          resolve(undefined);
        });
    });
  }
  
  controllerRadioBootloaderEntry(force: boolean = false):Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.checkForconnectedController(force);
      } catch (err) {
        reject(err);
      }

      const payload = new Uint8Array([0xAA, 0xCC, 0xC9, 0x17, 0x01]);
      const cmd = new VexCDCMessage(payload, 5);

      this.writeDataAsync(cmd, {timeout: 200})
        .then((reply:ArrayBuffer) => {
          this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;
          if (reply) {
            const msgReply = new Uint8Array(reply);
            resolve(msgReply[4] === 0x76)
          }
          resolve(reply);        
        })
        .catch(() => {
          // perhaps we don't support this yet
          resolve(undefined);
        });
    });
  }

  controllerRadioBootloaderExit(force: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.checkForconnectedController(force);
      } catch (err) {
        reject(err);
      }

      const payload = new Uint8Array([0xAA, 0xCC, 0xC9, 0x17, 0x02]);
      const cmd = new VexCDCMessage(payload, 5);

      this.writeDataAsync(cmd, {timeout: 200})
        .then((reply:ArrayBuffer) => {
          if (reply) {
            this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;
            const msgReply = new Uint8Array(reply);
            resolve(msgReply[4] === 0x76);
          }
          resolve(reply);        
        })
        .catch(() => {
          // perhaps we don't support this yet
          resolve(undefined);
        });
    });
  }
  
  /**
   * put the connected controller into DFU mode
   * @param force 
   * @returns 
   */
  protected async controllerEnterDFU(force: boolean = false) {
    this.checkForconnectedController(force);

    const payload = new Uint8Array([0xAA, 0xCC, 0xC9, 0x17, 0x03]);
    const cmd = new VexCDCMessage(payload, 0); // no reply expected

    try {
      await this.writeDataAsync(cmd, {timeout: 0})
      return true;
    } catch (err) {
      return undefined;
    }
  }

  protected async controllerAtmelStatus(force: boolean = false, canBeConnecting: boolean = false) {
    this.checkForconnectedController(force, canBeConnecting);

    const payload = new Uint8Array([0xAA, 0xCC, 0xC9, 0x17, 0x04]);
    const cmd = new VexCDCMessage(payload, 10);

    try {
      const reply = await this.writeDataAsync(cmd, {timeout: 200})
      if (reply) {
        this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;
        const msgReply = new Uint8Array(reply);
            
        if (msgReply[9] === 0x76){
          const v1: number[] = [0, 0, 0, 0];
          v1[0] = msgReply[4];
          v1[1] = msgReply[5];
          v1[2] = msgReply[6];
          v1[3] = msgReply[7];
          const ver = new VexFirmwareVersion(v1[0], v1[1], v1[2], v1[3]);
          return { version: ver, status: msgReply[8] };
        } else {
          log.debug("did not get expected response back for atmel status");
          return undefined;
        }
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Decode an IQ2/EXP controller versions reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  decodeControllerVersionsReply(msg: Uint8Array | ArrayBuffer): VexFirmwareVersion[] {
    // Decode file init
    const reply: VexFirmwareVersion[] = []
    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    
    if (this.cdc.cdc2ValidateMessageCtrl(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);
      
      if (buf[4] == VexCDC.ECMDS_CTRL.CNTR_GET_VERSIONS.cmd) {
        const v1: number[] = [0,0,0,0];
        const v2: number[] = [0,0,0,0];
        v1[0] = dvb.getUint8( 5 );
        v1[1] = dvb.getUint8( 6 );
        v1[2] = dvb.getUint8( 7 );
        v1[3] = dvb.getUint8( 8 );
        v2[0] = dvb.getUint8( 9 );
        v2[1] = dvb.getUint8( 10 );
        v2[2] = dvb.getUint8( 11 );
        v2[3] = dvb.getUint8( 12 );
        
        reply.push(new VexFirmwareVersion(v1[0], v1[1], v1[2], v1[3]));
        reply.push(new VexFirmwareVersion(v2[0], v2[1], v2[2], v2[3]));
      }
    }

    return reply; 
  }

  //#endregion controller comms

  //#region low level comms
  /**
   * Set the download address
   */
  protected downloadAddressSet(addr: number) {
    log.debug("downloadAddressSet", addr.toString(16));
    if (addr >= USER_FLASH_START && addr <= USER_FLASH_END) {
      this.downloadAddress = addr;
    }

    // temporary for IQ2
    if (addr >= USER_FLASH_START_B && addr <= USER_FLASH_END_B) {
      this.downloadAddress = addr;
    }

    // temporary for EXP
    if (addr >= USER_FLASH_START_C && addr <= USER_FLASH_END_C) {
      this.downloadAddress = addr;
    }
  
  }

  private linkfileSet(vid: number, name: string) {
    this.linkfilevid = vid;
    this.linkfile    = name;
  }

  /**
   * Set the download target
   */
  protected downloadTargetSet(target: number) {
    if (target === VexDeviceWebSerial.FILE_TARGET_DDR || target === VexDeviceWebSerial.FILE_TARGET_QSPI) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_A1 || target === VexDeviceWebSerial.FILE_TARGET_B1 ||  target === VexDeviceWebSerial.FILE_TARGET_B2) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_CBUF) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_VBUF) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_DDRC) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_DDRE) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_FLASH) {
      this.downloadTarget = target;
    } else if (target === VexDeviceWebSerial.FILE_TARGET_RADIO) {
      this.downloadTarget = target;
    }
  }

  /**
   * set options to send with program exit command after download
   * Not really working on the V5 yet (Mar 5 2018)
   */
  private downloadAutorunSet(state: boolean) {
    if (state !== undefined && state === false) {
      this.downloadAutorun = VexDeviceWebSerial.OPTIONS.EXIT_HALT;
    } else {
      this.downloadAutorun = VexDeviceWebSerial.OPTIONS.EXIT_RUN;
    }
  }

  /**
   * Download binary file to vexv5
   */
  private downloadFile(file: File, doneCallback: Function, progressCallback: Function) {
    const r = new FileReader();
  
    r.readAsArrayBuffer( file );

    // Load the file data
    r.onload = (event) => {
      log.info('file read complete');

      // check file read succesful
      if (r.readyState === r.DONE) {
        const buffer = new Uint8Array(<ArrayBuffer>r.result);
        
        if (this.connected) {
          const binfile:Uint8Array = buffer;
          this.downloadData(file.name, binfile, <ProgressCallback>progressCallback, (status: any) => {
            if (doneCallback != undefined) {
              doneCallback(status);
            }
          });
        }
      }
    }
  }

  protected async writeData(data: Uint8Array | VexCDCMessage, options?: IVexCDCWriteOptions): Promise<ArrayBuffer> {
    let expectedBytes;
    let timeout = 1000;
    if (options !== null && options !== undefined) {
      if (options.timeout !== undefined) {
        timeout = options.timeout;
      }
      if (options.replyBytes !== undefined) {
        expectedBytes = options.replyBytes;
      }
    }

    const timeoutRetry = options?.retryOnTimeout || false;

    const isCDCMessage = data instanceof VexCDCMessage;
    const sendData: Uint8Array = isCDCMessage ?
      (data as VexCDCMessage).data as Uint8Array :
      data as Uint8Array;
    let rxExpectedBytes: number = isCDCMessage ? (data as VexCDCMessage).replyLength : 0xFFFF;
      
    let serialRxTimeout: ReturnType<typeof setTimeout> = null;

    const writeLowLevel = () => {
      return new Promise<ArrayBuffer>((resolve, reject) => {
        let rxBuffer = new ArrayBuffer(0);
  
        const onReceiveData = (data: ArrayBuffer) => {
          rxBuffer = appendArrayBuffer(rxBuffer, data);
  
          // if expected bytes is 0xFFFF then this is now CDC2 with unknown
          // reply length and we should set from the receive packet
          if( rxExpectedBytes == 0xFFFF ) {
            if( rxBuffer && rxBuffer.byteLength > 4 ) {
              // validate header
              let buf = new Uint8Array( rxBuffer );
              if( this.cdc.validateHeaderAndLength( buf ) ) {
                rxExpectedBytes = this.cdc.cdc2MessageGetReplyPacketLength( buf );
              }
            }
          }
  
          if( rxBuffer.byteLength >= rxExpectedBytes ) {
            // TODO: print the value
            
            clearTimeout(serialRxTimeout);
            resolve(rxBuffer);
            this.serialConnection.onRXData = null;
          } else {
            log.debug("waiting for more response data", rxBuffer.byteLength, rxExpectedBytes);
          }
        }
  
        this.serialConnection.onRXData = onReceiveData;
        // log.debug("writeData TX:", buf2hex(sendData));
        this.serialConnection.writeToSerial(sendData);
  
        serialRxTimeout = setTimeout( ()=>{
          this.serialConnection.onRXData = null;
          reject(new ErrorWriteResponseTimeout());
          // resolve(undefined);
          log.info("write response timeout after", timeout, "ms");
        }, timeout);
      });
    }

    if (timeoutRetry) {
      try {
        return await writeLowLevel();
      } catch (err) {
        if (err instanceof ErrorWriteResponseTimeout) {
          log.warn("will retry write after response timeout...");
        } else {
          throw err;
        }
      }
    }
    try {
      return await writeLowLevel()
    } catch (err) {
      if (err instanceof ErrorWriteResponseTimeout) {
        return undefined;
      } else {
        throw err;
      }
    }
  }

  /**
   * Write data to a serial port using Promise
   * @param {ArrayBuffer} data the bytes to send
   * @param {vexcdc_writeOptions} opt_param2 the write data options
   * @return {Promise}
   */
  protected writeDataAsync(data: Uint8Array | VexCDCMessage, options?: IVexCDCWriteOptions): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.writeData(data, options)
        .then((reply: ArrayBuffer) => {
          // log.debug("writeData RX:", buf2hex(reply));
          // decode simple messages
          if (reply !== undefined && reply !== null) {
            var str = this.cdc.decode( reply );
          }

          // check for timeouts and disconnect
          if (this.lastStatus !== VexDeviceWebSerial.STATUS_GOOD) {
            reject(reply);
          } else {
            resolve(reply);
          }
        })
    });
  }

  protected writeDataRaw(
    data: Uint8Array | VexCDCMessage,
    onReceiveData: (data: ArrayBuffer) => void
  ) {
    const sendData: Uint8Array = data as Uint8Array;
    this.serialConnection.onRXData = (data) => {
      // log.debug("writeDataRaw RX:", data.byteLength, buf2hex(data));
      onReceiveData(data);
    };
    // log.debug("writeDataRaw TX:", sendData.byteLength, buf2hex(sendData));
    this.serialConnection.writeToSerial(sendData);
  }

  /**
   * Download program data to v5
   */
  private downloadProgramData(
    basename: string,
    binfile: Uint8Array,
    inifile: Uint8Array,
    prjfile: Uint8Array,
    progressCallback: (percentage: number, totalBytes: number) => void,
    doneCallback: (result: boolean) => void,
    exttype?: number,
  ) {
    // binfile is mandatory
    if (!binfile) {
      doneCallback(false);
    }

    // Create file names.
    // Find basename of file
    const re = /(.+?)(\.[^.]*$|$)/;
    const name = re.exec(basename)[1];

    // create names for the program files, all have same basename
    const binfilename = name + '.bin';
    const inifilename = name + '.ini';
    const prjfilename = name + '.prj';

    // get total download size
    let totalBytes =  binfile.byteLength;
    totalBytes += (inifile !== undefined ? inifile.byteLength : 0);
    totalBytes += (prjfile !== undefined ? prjfile.byteLength : 0);
    let totalDone = 0;

    // intermeadiate progress function, we need to account for multiple files now
    const onProgress: ProgressCallback = (progress: number, current: number, total: number) => {
      if (progressCallback) {
        progressCallback((totalDone + current) / totalBytes, totalBytes);
      }

      if (progress === 1.0) {
        totalDone += total;
        current = 0;
        log.info("download complete", totalDone, totalBytes);
      }
    } 

    log.info("download ini file", inifilename);
    this.downloadDataAsync(inifilename, inifile, onProgress)
      .then((status) => {
        if (inifile === undefined || status === true) {
          log.info("download src file", prjfilename);
          return this.downloadDataAsync(prjfilename, prjfile, onProgress);
        } else {
          throw false;
        }
      })
      .then((status) => {
        if (prjfile === undefined || status === true) {
          log.info("download bin file", binfilename);
          return this.downloadDataAsync(binfilename, binfile, onProgress, undefined, exttype);
        } else {
          throw false;
        }
      })
      .then((status) => {
        this.linkfileSet(undefined, undefined);
        if (doneCallback) {
          doneCallback(status);
        }
      })
      .catch((err: any) => {
        this.linkfileSet(undefined, undefined);
        if (doneCallback) {
          doneCallback(false);
        }
      });
  }

  /**
   * Download ArrayBuffer (Uint8Array) to the vexv5
   * serial link should be open before calling this function.
   * async using promise
   */
  private downloadDataAsync(
    name: string,
    buf: Uint8Array,
    callback: ProgressCallback,
    vid?: number,
    exttype?: number,
  ) {
    return new Promise<boolean>((resolve, reject) => {
      this.downloadData(name, buf, callback, (status)=> {
        // leave resolving status to caller
        resolve(status);
      }, vid, exttype);
    });      
  }

  /**
   * Download ArrayBuffer (Uint8Array) to the vexv5
   * serial link should be open before calling this function.
   */
  private downloadData(
    name: string,
    buf:Uint8Array,
    progressCallback: ProgressCallback,
    doneCallback: (status: boolean) => void,
    vid?: number,
    exttype?: number,
  ) {
    // We need some data
    // and must be connected
    if (buf === undefined || !this.connected) {
      // error !
      if (doneCallback != undefined) {
        doneCallback(false);
      }

      return;
    }

    // no download to special capture or vision buffers
    if (this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_CBUF ||  this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_VBUF) {
      // error !
      if (doneCallback != undefined) {
        doneCallback(false);
      }

      return;
    }

          
    // vid can now be passed in
    vid = (vid === undefined) ? VexDeviceWebSerial.VID.USER : vid;

    // an extended type for files can now be passed in
    exttype = (exttype === undefined) ? 0 : exttype;

    // address to download to
    let nextAddress = this.downloadAddress;

    // clear status
    this.lastStatus = VexDeviceWebSerial.STATUS_GOOD;

    // and do the download......
    this.writeDataAsync(this.cdc.query1())
      .then((reply:ArrayBuffer) => {
        if( this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_A1 || 
            this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_B1 ||  
            this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_B2 ) {
          return( this.writeDataAsync( this.cdc.V5_Cdc2FactoryEnable() ) );
        }
      })
      .then((reply: ArrayBuffer) => {
        let target = this.downloadTarget;
        log.debug('download to', this.cdc.hex8( nextAddress ), 'with', buf.length, 'bytes');

        // large buffers always go to DDR
        if (buf.length > USER_FLASH_MAX_FILE_SIZE && this.downloadTarget == VexDeviceWebSerial.FILE_TARGET_QSPI) {
          target = VexDeviceWebSerial.FILE_TARGET_DDR;
        }
        log.debug('download target is ' + (target === VexDeviceWebSerial.FILE_TARGET_DDR ? 'DDR'  : 
                                           target === VexDeviceWebSerial.FILE_TARGET_DDRC ? 'DDRC' :
                                           target === VexDeviceWebSerial.FILE_TARGET_DDRE ? 'DDRE' :
                                           target === VexDeviceWebSerial.FILE_TARGET_FLASH ? 'FLASH' :
                                           target === VexDeviceWebSerial.FILE_TARGET_RADIO ? 'RADIO' :
                                           target === VexDeviceWebSerial.FILE_TARGET_QSPI ? 'QSPI' : 'SYS'));
        
        // normal timeout on V5
        let timeout = 1000

        // Is this an IQ2 or EXP, if so we need long timeout for flash erase
        if (
          this.deviceType === "IQ2" || this.deviceType === "IQ2Controller" ||
          this.deviceType === "EXP" || this.deviceType === "EXPController"
        ) {
          // special app flash timeout, erase can be long
          if( target === VexDeviceWebSerial.FILE_TARGET_FLASH || target === VexDeviceWebSerial.FILE_TARGET_RADIO )
            timeout = 15000;
          // vexos update needs long timeout
          if( target === VexDeviceWebSerial.FILE_TARGET_A1 || target === VexDeviceWebSerial.FILE_TARGET_B1 || target === VexDeviceWebSerial.FILE_TARGET_B2 ) {
            timeout = 30000;
          }            
        }

        return this.writeDataAsync(
          this.cdc.V5_Cdc2FileInitialize(
            VexDeviceWebSerial.FILE_FUNC_SAVE,
            target,
            vid,
            VexDeviceWebSerial.OPTIONS.FILE_OVERWRITE,
            buf,
            nextAddress,
            name,
            exttype,
          ),
          {timeout},
        );  
      })
      .then((reply: ArrayBuffer) => {
        let rep = this.decodeFileInitReply(reply);
        log.debug("file write resp:", JSON.stringify(rep));
        if (rep.ack !== VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
          this.decodeFileNack( rep.ack );
          throw reply;
        }

        let bufferChunkSize = (rep.packetSize > 0 && rep.packetSize <= USER_PROG_CHUNK_SIZE) ? rep.packetSize : USER_PROG_CHUNK_SIZE;
        var bufferOffset    = 0;
        log.debug('download chunk size is', bufferChunkSize);

        return new Promise((resolve, reject) => {
          var lastBlock = false;

          // TODO: change to a loop...
          var sendBlock = () => {
            var tmpbuf: Uint8Array;
            if (buf.byteLength - bufferOffset > bufferChunkSize) {
              tmpbuf = buf.subarray(bufferOffset, bufferOffset + bufferChunkSize);
            } else {
              // last chunk
              // word align length
              let length = (((buf.byteLength-bufferOffset) + 3) / 4) >>> 0;
              tmpbuf = new Uint8Array(length * 4 );
              tmpbuf.set(buf.subarray(bufferOffset, buf.byteLength));
              //tmpbuf = buf.subarray( bufferOffset, buf.byteLength );
              lastBlock = true;
            }

            log.debug("Write addr", this.cdc.hex8(nextAddress), 'Size', tmpbuf.length);
          
            // Write the chunk
            this.writeDataAsync(this.cdc.V5_Cdc2FileDataWrite(nextAddress, tmpbuf), {retryOnTimeout: true})
            .then((reply:ArrayBuffer) => {
              // check reply
              let rep = this.decodeFileWriteReply( reply );
              if (rep.ack !== VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
                this.decodeFileNack( rep.ack );
                reject( reply );
              }
              
              // last block sent ?
              if (lastBlock) {
                resolve( reply );
              } else {
                sendBlock();
              }
            })
            .catch((reply:ArrayBuffer) => {
              reject(reply);
            });

            // update progress
            if (progressCallback) {
              progressCallback(  bufferOffset / buf.byteLength, bufferOffset,  buf.byteLength );
            }

            // next chunk
            bufferOffset += bufferChunkSize;
            nextAddress  += bufferChunkSize;
          }

          // Send first block
          sendBlock();
        });
      })
      .then((reply: ArrayBuffer) => {
        // see if we need to set link file and vid before sending exit
        // only do this for .bin files
        if (this.linkfile !== undefined && name.match(/.bin/)) {
          let savedExitReply = reply;
          log.debug("download send link file for", name, "as", this.linkfilevid, ":", this.linkfile);
          return this.writeDataAsync(this.cdc.V5_Cdc2FileLinkFile(this.linkfilevid, 0, this.linkfile))
            .then(() => {
              return savedExitReply;
            })
        }
        else
          return reply;
      })
      .then((reply: ArrayBuffer) => {
        log.debug('download exit');
        return this.writeDataAsync(this.cdc.V5_Cdc2FileExit(this.downloadAutorun), {timeout: 10000});
      })
      .then((reply:ArrayBuffer) => {
        // when sending firmware we have to wait for QSPI to be programmed
        //
        if( this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_A1 || 
            this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_B1 ||  
            this.downloadTarget === VexDeviceWebSerial.FILE_TARGET_B2 ) {
  
          let savedExitReply = reply;
          
          log.debug('download - programming flash');
  
          return new Promise((resolve, reject) => {
            var pollStatus = () => {
              this.writeDataAsync( this.cdc.V5_Cdc2FactoryStatus(), {timeout: 5000}  )
              .then((reply:ArrayBuffer) => {
                // check reply
                let rep = this.decodeFactoryStatusReply( reply );
                if( rep.ack !== VexCDC.CDC2_ACK_TYPES.CDC2_ACK ) {
                  this.decodeFileNack( rep.ack );
                  reject( reply );
                }
                // percent of 255 indicates error
                if( rep.percent === 255  ) {
                  reject( reply );
                }
  
                // Done ?
                if( rep.status == 0  ) {
                  resolve( savedExitReply );
                }
                else {
                  progressCallback( 0.0,  buf.byteLength / 100 * rep.percent, buf.byteLength  );
                  setTimeout( () => { pollStatus(); }, 500 );
                }
              })
              .catch(() => {
                reject( undefined );                    
              })
            }
           
            pollStatus();
            
            // clear any firmware download target
            this.downloadTargetSet( VexDeviceWebSerial.FILE_TARGET_QSPI );
          });
        }
        else
          return( reply );
      })
      .then((reply: ArrayBuffer) => {
        log.debug('download done', convertBufferToHexString(reply));

        // check final reply from exit command 
        let status = true;
        let rep = this.decodeFileExitReply(reply);
        if (rep.ack !== VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
          this.decodeFileNack(rep.ack);
          status = false;
        }
        // update progress
        if (progressCallback) {
          progressCallback(1.0, buf.byteLength , buf.byteLength);
        }
        if (doneCallback) {
          doneCallback(status);
        }
      })
      .catch((reply:ArrayBuffer) => {
        log.warn('download error');
        // update progress
        if (progressCallback) {
          progressCallback(1.0, buf.byteLength, buf.byteLength);
        }
        if (doneCallback) {
          doneCallback(false);
        }
      });
  }


  /**
   * Decode a received File initialization reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  private decodeFileInitReply(msg: Uint8Array | ArrayBuffer): fileHeaderReply {
    // Decode file init
    let reply: fileHeaderReply = {
      ack: 0xFF,
      packetSize:0,
      fileSize: 0,
      crc32: 0,
    };
    let buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    
    if (this.cdc.cdc2ValidateMessage(buf)) {
      let dvb = new DataView(buf.buffer, buf.byteOffset);
      
      if (buf[4] == VexCDC.ECMDS.FILE_INIT.cmd) {
        reply.ack        = buf[5];
        reply.packetSize = dvb.getUint16( 6, true);
        reply.fileSize   = dvb.getUint32( 8, true);
        reply.crc32      = dvb.getUint32(12, true);
      }
    }
    return reply; 
  }

  /**
   * Decode a received File write reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  private decodeFileWriteReply(msg: Uint8Array | ArrayBuffer): fileWriteReply {
    // Decode file init
    let reply: fileWriteReply = {ack: 0xFF};
    let buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    
    if (this.cdc.cdc2ValidateMessage(buf)) {
      if (buf[4] == VexCDC.ECMDS.FILE_WRITE.cmd) {
        reply.ack = buf[5];
      }
    }
    return reply; 
  }

  /**
   * Decode a received File Exit reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  private decodeFileExitReply(msg: Uint8Array | ArrayBuffer): fileExitReply {
    // Decode file init
    let reply: fileExitReply = {ack: 0xFF};
    let buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    
    if (this.cdc.cdc2ValidateMessage(buf)) {
      if (buf[4] == VexCDC.ECMDS.FILE_EXIT.cmd) {
        reply.ack = buf[5];
      }
    }
    return reply; 
  }


  /**
   * Decode a get directory reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  decodeFileGetDirectoryReply( msg: Uint8Array | ArrayBuffer ): fileGetDirReply {
    // Decode file init
    const reply: fileGetDirReply = {ack: 0xFF, count:0};
    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array( msg ) : msg;
    
    if (this.cdc.cdc2ValidateMessage(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);
      
      if (buf[4] == VexCDC.ECMDS.FILE_DIR.cmd) {
        reply.ack   = buf[5];
        reply.count = dvb.getUint16(6, true);
      }
    }
    return reply; 
  }

  /**
   * Decode a get directory entry reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  decodeFileGetDirectoryEntryReply( msg: Uint8Array | ArrayBuffer ): fileGetDirEntryReply {
    // Decode file init
    const reply: fileGetDirEntryReply = {
      ack:       0xFF,
      index:     0,
      size:      0,
      loadAddr:  0,
      crc32:     0,
      type:      0,
      timestamp: 0,
      version:   0,
      name:      '',
    }
    const buf = msg instanceof ArrayBuffer ? new Uint8Array(msg) : msg;
    
    if (this.cdc.cdc2ValidateMessage(buf)) {
      const dvb = new DataView(buf.buffer, buf.byteOffset);

      // get dir entry and get file matadata have same format
      if (buf[4] === VexCDC.ECMDS.FILE_DIR_ENTRY.cmd || buf[4] === VexCDC.ECMDS.FILE_GET_INFO.cmd) {
        reply.ack   = buf[5];
        reply.index = buf[6];
        // new, check for ACK, could be no file error
        if (this.cdc.cdc2MessageGetLength(buf) > 4 && reply.ack === VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
          reply.size      = dvb.getUint32(  7, true );    
          reply.loadAddr  = dvb.getUint32( 11, true );
          reply.crc32     = dvb.getUint32( 15, true );
          reply.type      = dvb.getUint32( 19, true );
          reply.timestamp = dvb.getUint32( 23, true ) + VexCDC.J2000_EPOCH;   
          reply.version   = dvb.getUint32( 27, true ); 

          // for development we allow full 32 byte filename to be returned now
          let nameLen = dvb.byteLength - buf.byteOffset - 31 - 2;
          if (nameLen > 32) {
            nameLen = 32;
          }

          reply.name = '';
          for (let offset = 31; offset < 31 + nameLen; offset++) {
            const c = dvb.getUint8(offset);
            if (c === 0) {
              break;
            }
            reply.name += String.fromCharCode(c);
          }
        }
      }
    }
    return reply; 
  }

  /**
   * Decode a request for user file system status for a file
   * @param msg 
   */
  decodeFileGetUserStatusReply( msg: Uint8Array | ArrayBuffer ): userProgramStatusReply {
    const reply: userProgramStatusReply = {
      ack: 0xFF,
      slot: -1,
      requestedSlot: -1,
    }

    const buf = (msg instanceof ArrayBuffer) ? new Uint8Array( msg ) : msg;

    if (this.cdc.cdc2ValidateMessage(buf)) {
      //let dvb = new DataView( buf.buffer, buf.byteOffset );

      if (buf[4] == VexCDC.ECMDS.FILE_USER_STAT.cmd) {
        reply.ack  = buf[5];
        reply.slot = buf[6];
        if (buf[7] !== undefined) {
          reply.requestedSlot = buf[7];
        }
      }
    }

    return reply;
  }

  /**
   * Decode a factory firmware upgrade status reply
   * @param  (Uint8Array | ArrayBuffer} msg the CDC2 reply to decode
   */
  decodeFactoryStatusReply(msg: Uint8Array | ArrayBuffer): factoryStatusReply {
    const reply:factoryStatusReply = { ack:     0xFF,
                                      status:  -1,
                                      percent:  0 }
    const buf = msg instanceof ArrayBuffer ? new Uint8Array(msg) : msg;

    reply.ack        = buf[5];
    reply.status     = buf[6];
    reply.percent    = buf[7];

    return reply; 
  }

  /**
   * Decode a received ack/mack
   * @param  (number} ack the CDC2 ack to decode
   */
  private decodeFileNack(ack: number) {
    this.lastStatus = ack;
    
    switch( ack ) {
      case   VexCDC.CDC2_ACK_TYPES.CDC2_ACK:
        log.debug('ack received');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK:               
        log.debug('nak received');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_PACKET_CRC:    
        log.debug('bad packet crc');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_CMD_LENGTH:    
        log.debug('payload length error');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_SIZE:          
        log.debug('requested transfer size too large');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_CRC:           
        log.debug('program crc error');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_FILE:          
        log.debug('program file not found error');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_INIT:          
        log.debug('file transfer is not initialized');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_FUNC:          
        log.debug('initialization invalid for this function');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_ALIGN:         
        log.debug('data alignment error (not multiple of 4 bytes)');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_ADDR:          
        log.debug('invalid packet address');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_INCOMPLETE:    
        log.debug('download incomplete');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_DIR_INDEX:     
        log.debug('directory entry at index does not exist');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_MAX_USER_FILES:
        log.debug('max user files on file system');  
        break;              
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_FILE_EXISTS:
        log.debug('program exists and overwrite flag not set');  
        break;
      case   VexCDC.CDC2_ACK_TYPES.CDC2_NACK_FILE_SYS_FULL:
        log.debug('file could not be writtem, file system may be full');  
        break;
      default:
        log.debug('unknown nak received');  
        break;                      
    }
  }

  /**
   * Decode a cdc2 KV read command
   * @param msg 
   */
  private decodeSysKVRead(msg: Uint8Array | ArrayBuffer): string {
    let buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;

    if (this.cdc.cdc2ValidateMessage(buf)) {
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength( buf );
      if (length > 128) {
        extcmd = 5;
      }

      if (buf[extcmd] == VexCDC.ECMDS.SYS_KV_LOAD.cmd) {
        if (buf[extcmd+1] == VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
          let value = new TextDecoder("UTF-8").decode(buf.slice(extcmd+2, extcmd+length-2));
          // remove trailing null if present
          var c = value.indexOf('\0');
          if (c>-1) {
            value = value.substr(0, c);
          }
          return value;
        }
      }
    }
  
    return undefined;
  }

  /**
   * Decode a cdc2 system flags command
   * @param msg 
  */
  private decodeSysFlagsRead(msg: Uint8Array | ArrayBuffer): number {
    let buf = (msg instanceof ArrayBuffer) ? new Uint8Array(msg) : msg;
    let flags = undefined;

    if (this.cdc.cdc2ValidateMessage(buf)) {
      let dvb = new DataView(buf.buffer, buf.byteOffset);
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength(buf);
      if (length > 128) {
        extcmd = 5;
      }
      
      if (buf[extcmd] == VexCDC.ECMDS.SYS_FLAGS.cmd) {
        if (buf[extcmd+1] == VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
          flags = dvb.getUint32(extcmd + 2, true);
          if (length === 11) {
            // new extended reply from beta 23
            this.battery =  (dvb.getUint8(extcmd + 6) & 0x0F) * 8;
            if ((flags & 0x0100) != 0 || (flags & 0x0600) === 0x0600) {
              this.batteryController = ((dvb.getUint8(extcmd + 6) >> 4) & 0x0F) * 8;
            } else {
              this.batteryController = undefined;
            }

            if ((flags & 0x0600) === 0x0600) {
              this.radioQuality  = (dvb.getUint8(extcmd + 7) & 0x0F) * 8;
              this.radioSearching = false;
            } else {
              this.radioQuality  = undefined;
            }

            if ((flags & 0x0600) === 0x0200) {
              this.radioSearching = true;
            } else {
              this.radioSearching = false;
            }

            if ((flags & 0x2000) != 0) {
              this.batteryPartner = ((dvb.getUint8(extcmd + 7) >> 4) & 0x0F) * 8;
            } else {
              this.batteryPartner = undefined;
            }

            // final byte is now running program, 0 for none. vexos 1.0.1 and on.
            this.currentProgram =   dvb.getUint8(extcmd + 8);

            if (this.battery  && this.battery  > 100) {
              this.battery = 100;
            }
            if (this.batteryController && this.batteryController  > 100) {
              this.batteryController = 100;
            }
            if (this.radioQuality && this.radioQuality  > 100) {
              this.radioQuality = 100;
            }
            if (this.batteryPartner && this.batteryPartner  > 100) {
              this.batteryPartner = 100;
            }
          }
        }
      }
    }        

    return flags;
  }

  protected updateSystemVersionString() {
    this.versionSystemStr = this.versionSystem[0] + '.'
    + this.versionSystem[1] + '.'
    + this.versionSystem[2];

    if( this.versionSystem[3] !== 0 )
      this.versionSystemStr += '.b' + this.versionSystem[3];      
  }

  /**
   * Decode a cdc2 system status command
   * @param msg 
   */
  private decodeSysStatusRead(msg: Uint8Array | ArrayBuffer): string {
    let buf = (msg instanceof ArrayBuffer) ? new Uint8Array( msg ) : msg;

    if (this.cdc.cdc2ValidateMessage(buf)) {
      let dvb = new DataView(buf.buffer, buf.byteOffset);
      let extcmd = 4;
      
      var length = this.cdc.cdc2MessageGetLength(buf);
      if (length > 128) {
        extcmd = 5;
      }
      
      if (buf[extcmd] == VexCDC.ECMDS.SYS_STATUS.cmd) {
        if (buf[extcmd+1] == VexCDC.CDC2_ACK_TYPES.CDC2_ACK) {
          this.versionUser[0] = dvb.getUint32(extcmd + 3 + 0, false);
          this.versionUser[1] = dvb.getUint32(extcmd + 3 + 4, false);
          this.versionUser[2] = dvb.getUint32(extcmd + 3 + 8, false);
          this.versionUser[3] = dvb.getUint32(extcmd + 3 + 12, true);

          // controller does not report system version in the
          // A4 message so update here
          let sysOk: boolean = false;
          for (let i=0;i<this.versionSystem.length;i++) {
            if (this.versionSystem[i] !== 0) {
              sysOk = true;
            }
          }
          if (sysOk === false) {
            this.versionSystem[0] = dvb.getUint8(extcmd + 3 + 0);
            this.versionSystem[1] = dvb.getUint8(extcmd + 3 + 1);
            this.versionSystem[2] = dvb.getUint8(extcmd + 3 + 2);
            this.versionSystem[3] = dvb.getUint8(extcmd + 3 + 3);
            this.brainVersionSystem = new VexFirmwareVersion(this.versionSystem[0], this.versionSystem[1], this.versionSystem[2], this.versionSystem[3]);
            this.updateSystemVersionString();
          }

          if (length > 25) {
            this.uniqueId   = dvb.getUint32(extcmd + 3 + 16, true);
            this.sysflags[0] = dvb.getUint8(extcmd + 3 + 20);
            this.sysflags[1] = dvb.getUint8(extcmd + 3 + 21);
            this.sysflags[2] = dvb.getUint8(extcmd + 3 + 22);
            this.sysflags[3] = dvb.getUint8(extcmd + 3 + 23);

            this.sysflags[4] = dvb.getUint8(extcmd + 3 + 24);

            this.sysflags[6] = dvb.getUint8(extcmd + 3 + 26);

            this.eventBrain = false;
            if (this.sysflags[6] & 0x01) {
              this.eventBrain = true;
            }
            this.romBootloaderActive = false;
            if (this.sysflags[6] & 0x02) {
              this.romBootloaderActive = true;
            }
            this.ramBootloaderActive = false;
            if (this.sysflags[6] & 0x04) {
              this.ramBootloaderActive = true;
            }

            // final 4 bytes are now golden image version as read from QSPI
            // so in slightly different format
            // major.minor.beta.(build:6 cpu:2) (big endian)
            // build is top 6 bits of LSB, cpu is lower 2 bits
            this.versionGolden = dvb.getUint32(extcmd + 3 + 28, false);

            let dbgLen = 34;
            if (length > 37) {
              this.versionNxp =dvb.getUint32(extcmd + 3 + 32, false);
              dbgLen += 4;
            } else {
              this.versionNxp = 0;
            }
          } else {
            // dummy data
            this.uniqueId    = 1234;
            this.sysflags[0] = 0;
            this.sysflags[1] = 0;
            this.sysflags[2] = 0;
            this.sysflags[3] = 0;

            this.sysflags[4] = 0;
            this.sysflags[6] = 0;

            this.versionGolden = 0;
            this.versionNxp    = 0;
            this.eventBrain    = false;
            this.romBootloaderActive = false;
            this.ramBootloaderActive = false;
          }
        }
      }
    }        
  
    return undefined;
  }
  //#endregion

  //#region event handlers
  //#region event system
  private eventCallbacks: {
    "connectionStateChange": Array<(state: VexWebSerialConnectionStates) => void>;
    "connectionStateChangeUserPort": Array<(state: VexWebSerialConnectionStates) => void>;
    "receivedUserData": Array<(data: string) => void>;
    "connectedToInvalidPort": Array<() => void>;
    "deviceInfoUpdated": Array<(data: IVEXWebSerialBrainInfo) => void>;
  } = {
    "connectionStateChange": [],
    "connectionStateChangeUserPort": [],
    "receivedUserData": [],
    "connectedToInvalidPort": [],
    "deviceInfoUpdated": [],
  };

  on(eventname: "connectionStateChange", callback: (state: VexWebSerialConnectionStates) => void): void;
  on(eventname: "connectionStateChangeUserPort", callback: (state: VexWebSerialConnectionStates) => void): void;
  on(eventname: "receivedUserData", callback: (data: string) => void): void;
  on(eventname: "connectedToInvalidPort", callback: () => void): void;
  on(eventname: "deviceInfoUpdated", callback: (data: IVEXWebSerialBrainInfo) => void): void;

  on(eventName: EventNames, callback: any) {
    if (this.eventCallbacks[eventName].indexOf(callback) >= 0) {
      return;
    }
    this.eventCallbacks[eventName].push(callback);
  }

  off(eventname: "connectionStateChange", callback: (state: VexWebSerialConnectionStates) => void): void;
  off(eventname: "connectionStateChangeUserPort", callback: (state: VexWebSerialConnectionStates) => void): void;
  off(eventname: "receivedUserData", callback: (data: string) => void): void;
  off(eventname: "connectedToInvalidPort", callback: () => void): void;
  off(eventname: "deviceInfoUpdated", callback: (data: IVEXWebSerialBrainInfo) => void): void;

  off(eventName: EventNames, callback: any) {
    const i = this.eventCallbacks[eventName].indexOf(callback);
    if (i < 0) {
      log.warn("Unknown callback.");
      return;
    }
    this.eventCallbacks[eventName].splice(i, 1);
  }

  protected fireEvent(eventname: "connectionStateChange", state: VexWebSerialConnectionStates): void;
  protected fireEvent(eventname: "connectionStateChangeUserPort", state: VexWebSerialConnectionStates): void;
  protected fireEvent(eventname: "receivedUserData", data: string): void;
  protected fireEvent(eventname: "connectedToInvalidPort"): void;
  protected fireEvent(eventname: "deviceInfoUpdated", data: IVEXWebSerialBrainInfo): void;

  protected fireEvent(eventName: EventNames, ...args: any[]) {
    log.debug("fire event", eventName, ...args);
    if (this.eventCallbacks[eventName]) {
      this.eventCallbacks[eventName].forEach((callback: any) => {
        callback(...args);
      });
    }
  }
  //#endregion event system

  protected onConnect() {
    log.debug("serial port connected", this);
    this.isConnecting = true;
    if (!this.isUpdatingFirmware) {
      this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Connecting);
    }

    const portInfo = this.serialConnection.getPortInfo();
    log.debug("portInfo:", portInfo);
    this.processPortInformation(portInfo);

    this.isVexAdminPort(this.isDeviceBrain)
      .then((isAdmin) => {
        log.debug("found admin port", isAdmin);
        if (!isAdmin) {
          this.fireEvent("connectedToInvalidPort");
          this.closeConnection();
          this.isConnecting = false;
          return;
        }

        if (this.isDeviceBrain) {
          this.fetchBrainInfo()
            .then((res) => {
              // if we don't get the data assume dfu
              this._inDFUMode = !res;
              // TODO: there may be times when we don't actually get the info...
              this.isConnected = true;

              if (this.isUpdatingFirmware) {
                return;
              }

              this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Connected);

              // check if update is needed
              // only need to check the brain as we can't get the controller info if connected directly to a brain...
              if (this.deviceType !== "IQ") {
                this.checkUpdateNeededBrain()
                  .then(() => {
                    log.debug("brain update needed state:", UpdateNeededOptions[this._needsUpdateStateBrain]);
                    this.fireEvent("deviceInfoUpdated", this.getBrainInfo());
                  })}
            });
        } else {
          this.fetchControllerInfo()
            .then(() => {
              // TODO: fetch controller information here
              this.isConnected = true;

              if (this.isUpdatingFirmware) {
                return;
              }

              this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Connected);

              // check if update is needed
              // use full check as we may get the brain info later...
              this.checkUpdateNeeded()
                .then(() => {
                  log.debug("brain update needed state:", UpdateNeededOptions[this._needsUpdateStateBrain]);
                  log.debug("controller update needed state:", UpdateNeededOptions[this._needsUpdateStateController]);

                  this.fireEvent("deviceInfoUpdated", this.getBrainInfo());
                })
            });
        }
      });
  }

  protected onDisconnect() {
    log.debug("serial port disconnected", this);
    if (this.isConnected) {
      this.closeConnectionUserPort();
    }
    this.isConnecting = false;
    this.isConnected = false;
    this._inDFUMode = false;
    this.versionSystem = [0, 0, 0, 0];
    this.brainVersionSystem = null;
    this.controllerVersionAtmel = null;
    this.controllerVersionRadio = null;
    this._needsUpdateStateBrain = UpdateNeededOptions.Unsure;
    this._needsUpdateStateController = UpdateNeededOptions.Unsure;
    if (!this.isUpdatingFirmware) {
      this.fireEvent("connectionStateChange", VexWebSerialConnectionStates.Disconnected);
    }
  }

  /**
   * called when the user port connection is completed
   */
  protected onConnectUserPort() {
    log.debug("serial user port connected", this);

    const portInfo = this.serialConnection.getPortInfo();
    log.debug("portInfo:", portInfo);

    this.isConnectingUserPort = false;
    this.isConnectedUserPort_ = true;

    this.fireEvent("connectionStateChangeUserPort", VexWebSerialConnectionStates.Connected);
  }

  /**
   * called when the user port is disconnected
   */
  protected onDisconnectUserPort() {
    log.debug("serial user port disconnected", this);
    this.isConnectingUserPort = false;
    this.isConnectedUserPort_ = false;

    this.fireEvent("connectionStateChangeUserPort", VexWebSerialConnectionStates.Disconnected);
  }

  protected onUserPortReceivedData(data: ArrayBuffer) {
    const dataStr = utf8decoder.decode(data);
    log.warn("user received:", dataStr);
    this.fireEvent("receivedUserData", dataStr);
  }
  //#endregion event handlers

  protected delay(ms: number, payload?: any): Promise<any> {
    return new Promise(function (resolve) {
      setTimeout(() => { resolve(payload); }, ms);
    });
  }
}

export {
  VexFirmwareVersion,
  VexDeviceWebSerial,

  DownloadState,
  VexWebSerialConnectionStates,
  VEXControllerUpdateStates,
  VEXBrainUpdateStates,
}

export type {
  DownloadProgress,
  ProgressCallbackDownload,

  IPortInfo,
  ITriportInfo,
  IControllerConfigInfo,
  ControllerButtonName,

  IProjectInformation,

  IResult,

  ProgressCallback,

  IPythonProgramLinkInfo,
  IPythonVMDownloadInfo,

  BrainUpdateProgressCallback,

  ConnectPromptCallback,
}
