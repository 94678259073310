import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexFirmwareVersion");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

/**
 * This is a class to make it easier to handle passing the VEXos version
 * information around in the application. This provides ways to get any
 * form of the string represnetation and quickly compare versions.
 */
class VexFirmwareVersion {
  /**
   * Take a a string of MAJOR.MINOR.BUILD.bBETA and converts it to a
   * VexFirmwareVersion instance
   * @param version the string to process
   * @returns a VexFirmwareVersion representing the provided string
   */
  static fromString(version: string): VexFirmwareVersion {
    const parts = version
      .toLowerCase()
      .replace(/b/g, "")
      .split(".")
      .map((x) => parseInt(x, 10));
    while (parts.length < 4) {
      parts.push(0);
    }
    return new VexFirmwareVersion(parts[0], parts[1], parts[2], parts[3]);
  }

  static allZero(): VexFirmwareVersion {
    return new VexFirmwareVersion(0, 0, 0, 0);
  }

  /**
   * Take a a string of MAJOR_MINOR_BUILD_BETA and converts it to a
   * VexFirmwareVersion instance
   * @param version the string to process
   * @returns a VexFirmwareVersion representing the provided string
   */
  static fromCatalogString(version: string): VexFirmwareVersion {
    return VexFirmwareVersion.fromString(version.replace(/_/g, "."));
  }

  readonly major: number;
  readonly minor: number;
  readonly build: number;
  readonly beta: number;

  constructor(major: number, minor: number, build: number, beta: number) {
    this.major = major;
    this.minor = minor;
    this.build = build;
    this.beta = beta;
  }

  isBeta(): boolean {
    return this.beta !== 0;
  }

  /**
   * returns version as major.minor.build
   */
  toUserString(): string {
    return `${this.major}.${this.minor}.${this.build}`;
  }

  /**
   * returns version as ${major}.${minor}.4{build}.b${beta}
   */
  toInternalString(): string {
    return `${this.toUserString()}.b${this.beta}`;
  }

  /**
   * compares this version to the provided version.
   * * if this < b: negative
   * * if this = b: 0
   * * if this > b: positive
   * @param b the version to compare agains
   */
  compare(b: VexFirmwareVersion): number {
    const majorComp = this.major - b.major;
    const minorComp = this.minor - b.minor;
    const buildComp = this.build - b.build;
    const betaComp = this.beta - b.beta;

    log.debug("compare result - major:", majorComp, "minor:", minorComp, "build:", buildComp, "beta:", betaComp);

    if (majorComp !== 0) {
      return majorComp;
    } else if (minorComp !== 0) {
      return minorComp;
    } else if (buildComp !== 0) {
      return buildComp;
    } else if (betaComp !== 0) {
      return betaComp;
    }
    return 0;
  }
}

export {
  VexFirmwareVersion,
};
