import * as logger from "@rm-vca/logger";
const log = logger.getLogger("VexCDC");
log.setLevel(log.levels.WARN);
// for dev only
// log.enableAll();

log.setHistoryLogger("WebSerial");

import * as crcgen from "./VexCRC";

export interface IVexCDCType {
  cmd: number;
  replyLength: number;
}

export interface IVexCDCWriteOptions {
  timeout?: number;
  replyBytes?: number;
  retryOnTimeout?: boolean;
}

export class VexCDCMessage {
  data: Uint8Array;
  replyLength: number;

  constructor(data: Uint8Array, replyLength: number) {
    this.data = data;
    this.replyLength = replyLength;
  }
}

export class VexCDC {

  static HEADERS_LENGTH: number = 4;
  static HEADERS = [0xC9, 0x36, 0xB8, 0x47];
  static HEADERR = [0xAA, 0x55];

  //#region static constants
  /**
   * All CDC messages are defined here
   */
  static TYPES = {
    // reply only
    ACK:            { cmd: 0x33, replyLength: 5 },

    // commands and replies
    QUERY1:         { cmd: 0x21, replyLength: 14 },
    SYSTEM_VERSION: { cmd: 0xA4, replyLength: 12 },
    USER_CDC:       { cmd: 0x56, replyLength: 0 },
    CTRL_CDC:       { cmd: 0x58, replyLength: 0 },

    // for IQ
    EEPROM_ERASE:   {cmd: 0x31, replyLength: 5},

    USER_ENTER:     {cmd: 0x60, replyLength: 5},
    USER_CATALOG:   {cmd: 0x61, replyLength: 33},
    FLASH_ERASE:    {cmd: 0x63, replyLength: 5},
    FLASH_WRITE:    {cmd: 0x64, replyLength: 5},
    FLASH_READ:     {cmd: 0x65, replyLength: 0},
    USER_EXIT:      {cmd: 0x66, replyLength: 5},
    USER_PLAY:      {cmd: 0x67, replyLength: 5},
    USER_STOP:      {cmd: 0x68, replyLength: 5},
    COMPONENT_GET:  {cmd: 0x69, replyLength: 7},

    USER_SLOT_GET:  {cmd: 0x78, replyLength: 44},
    USER_SLOT_SET:  {cmd: 0x79, replyLength: 5},

    BRAIN_NAME_GET: {cmd: 0x44, replyLength: 0},
  };

  static ECMDS = {
    FILE_CTRL:      { cmd: 0x10, replyLength: 8 },
    FILE_INIT:      { cmd: 0x11, replyLength: 17 },
    FILE_EXIT:      { cmd: 0x12, replyLength: 8 },
    FILE_WRITE:     { cmd: 0x13, replyLength: 8 },
    FILE_READ:      { cmd: 0x14, replyLength: 0 },
    FILE_LINK:      { cmd: 0x15, replyLength: 8 },
    FILE_DIR:       { cmd: 0x16, replyLength: 0xFFFF },
    FILE_DIR_ENTRY: { cmd: 0x17, replyLength: 57 },
    FILE_LOAD:      { cmd: 0x18, replyLength: 8 },
    FILE_GET_INFO:  { cmd: 0x19, replyLength: 57 },
    FILE_SET_INFO:  { cmd: 0x1A, replyLength: 8 },
    FILE_ERASE:     { cmd: 0x1B, replyLength: 8 },
    FILE_USER_STAT: { cmd: 0x1C, replyLength: 9 },

    SYS_FLAGS:      { cmd: 0x20, replyLength: 0xFFFF },
    DEV_STATUS:     { cmd: 0x21, replyLength: 0xFFFF },
    SYS_STATUS:     { cmd: 0x22, replyLength: 0xFFFF },
    SYS_DASH_SEL:   { cmd: 0x2B, replyLength: 8 },
    SYS_KV_LOAD:    { cmd: 0x2E, replyLength: 0xFFFF },
    SYS_KV_SAVE:    { cmd: 0x2F, replyLength: 8 },

    FACTORY_STATUS: { cmd: 0xF1, replyLength: 10 },
    FACTORY_RESET:  { cmd: 0xF2, replyLength: 0 },
    FACTORY_PING:   { cmd: 0xF4, replyLength: 8 },
    FACTORY_PONG:   { cmd: 0xF5, replyLength: 0xFFFF },
    FACTORY_SPECIAL:{ cmd: 0xFE, replyLength: 8 },
    FACTORY_EBL:    { cmd: 0xFF, replyLength: 8 },
  };

  static CDC2_ACK_TYPES = {
    CDC2_ACK:                 0x76,
    CDC2_NACK:                0xFF,
    CDC2_NACK_PACKET_CRC:     0xCE,
    CDC2_NACK_CMD_LENGTH:     0xD0,
    CDC2_NACK_SIZE:           0xD1,
    CDC2_NACK_CRC:            0xD2,
    CDC2_NACK_FILE:           0xD3,
    CDC2_NACK_INIT:           0xD4,
    CDC2_NACK_FUNC:           0xD5,
    CDC2_NACK_ALIGN:          0xD6,
    CDC2_NACK_ADDR:           0xD7,
    CDC2_NACK_INCOMPLETE:     0xD8,
    CDC2_NACK_DIR_INDEX:      0xD9,
    CDC2_NACK_MAX_USER_FILES: 0xDA,
    CDC2_NACK_FILE_EXISTS:    0xDB,
    CDC2_NACK_FILE_SYS_FULL:  0xDC,
  }

  static ECMDS_CTRL = {
    CON_COMP_CTRL: { cmd: 0xC1, replyLength: 8 },

    CON_VER_FLASH:  {cmd: 0x39, replyLength: 0xFFFF},
    CON_RADIO_MODE: {cmd: 0x41, replyLength: 0xFFFF},
    CON_RADIO_FORCE:{cmd: 0x3F, replyLength: 0xFFFF},

    // IQ2/EXP controller specific
    CNTR_GET_STATE:    {cmd: 0x60, replyLength: 0xFFFF},
    CNTR_SET_PAIR_ID:  {cmd: 0x61, replyLength: 0xFFFF},
    CNTR_GET_PAIR_ID:  {cmd: 0x62, replyLength: 0xFFFF},
    CNTR_GET_TEST_DATA:{cmd: 0x63, replyLength: 0xFFFF},
    CNTR_TEST_CMD:     {cmd: 0x64, replyLength: 0xFFFF},
    CNTR_ABORT_JS_CAL: {cmd: 0x65, replyLength: 0xFFFF},
    CNTR_START_JS_CAL: {cmd: 0x66, replyLength: 0xFFFF},
    CNTR_GET_VERSIONS: {cmd: 0x67, replyLength: 0xFFFF},
    CNTR_DEV_STATE:    {cmd: 0x68, replyLength: 0xFFFF},
}

  // Offset from unix time for timestamp fields
  // timestamp = (unix time) - J2000_EPOCH
  static J2000_EPOCH = 946684800;
  //#endregion static constants

  vex_version: number = 0;
  file_version: number = 1;

  /**
   * Convert an ArrayBuffer to a string for display
   * @param buf the ArrayBuffer or UInt8Array
   * @return the converted string
   */
  convertBufferToHexString(buf: ArrayBuffer) {
    if (!buf) {
      return 'error';
    }

    let str = '';
    const uint8Array = new Uint8Array(buf);
    for (let i = 0; i < uint8Array.length; i++) {
      str = str + ('00' + uint8Array[i].toString(16)).substr(-2, 2) + ' ';
    }

    return str.toUpperCase();
  }

  /**
   * Create the vex CDC header
   * @param buf the bytes to send
   */
  header(buf: ArrayBuffer): Uint8Array {
    // create a buffer if is is not defined
    if (buf === undefined || buf.byteLength < VexCDC.HEADERS_LENGTH) {
      buf = new ArrayBuffer(VexCDC.HEADERS_LENGTH);
    }

    const h = new Uint8Array(buf);
    h.set(VexCDC.HEADERS);

    return (h);
  }

  /**
   * Create the vex CDC simple message
   * @param cmd the CDC command byte
   */
  cdcCommand(cmd: number): Uint8Array {
    const buf = new ArrayBuffer(VexCDC.HEADERS_LENGTH + 1);
    const h = this.header(buf);
    h.set([cmd], VexCDC.HEADERS_LENGTH);
    return h;
  }

  /**
   * Create the vex CDC simple message
   * @param cmd the CDC command byte
   * @param data the data to send
   */
  cdcCommandWithData(cmd: number, data: Uint8Array): Uint8Array {
    const buf = new ArrayBuffer(VexCDC.HEADERS_LENGTH + 2 + data.length);
    const h = this.header(buf);
    // add command and length bytes
    h.set([cmd, data.length], VexCDC.HEADERS_LENGTH);
    // add the message data
    h.set(data, VexCDC.HEADERS_LENGTH + 2);
    return h;
  }

  /**
   * Create the vex CDC extended message
   * @param cmd the CDC command byte
   * @param ext the CDC extended command byte
   * @return a message
   */
  cdc2Command(cmd: number, ext: number): Uint8Array {
    const buf = new ArrayBuffer(VexCDC.HEADERS_LENGTH + 5);
    const h = this.header(buf);
    h.set([cmd, ext, 0], VexCDC.HEADERS_LENGTH);

    // Add CRC
    const crc = crcgen.crc16(h.subarray(0, buf.byteLength - 2), 0) >>> 0;
    h.set([crc >>> 8, crc & 0xFF], buf.byteLength - 2);

    return h;
  }

  /**
   * Calculate buffer length for new CDC extended command
   * @param data the CDC extended command payload
   * @returns the requried buffer length of the command message
   */
  cdc2CommandBufferLength(data: Uint8Array): number {
    // New command use header + 1 byte command
    //                        + 1 byte function
    //                        + 1 byte data length
    let length = VexCDC.HEADERS_LENGTH + data.length + 3 + 2;
    // If data length is > 127 bytes then an additional data length byte is added
    if (data.length > 127)
      length += 1;
    return (length);
  }

  /**
   * Create the vex CDC extended message with some data
   * @param cmd the CDC command byte
   * @param ext the CDC extended command byte
   * @param data the CDC extended command payload
   * @return a message
   */
  cdc2CommandWithData(cmd: number, ext: number, data: Uint8Array): Uint8Array {
    const buf = new ArrayBuffer(this.cdc2CommandBufferLength(data));
    const h = this.header(buf);

    // add command and length bytes
    if (data.length < 128) {
      h.set([cmd, ext, data.length], VexCDC.HEADERS_LENGTH);
      // add the message data
      h.set(data, VexCDC.HEADERS_LENGTH + 3);
    } else {
      const length_msb = ((data.length >>> 8) | 0x80) >>> 0;
      const length_lsb = (data.length & 0xFF) >>> 0;

      h.set([cmd, ext, length_msb, length_lsb], VexCDC.HEADERS_LENGTH);
      // add the message data
      h.set(data, VexCDC.HEADERS_LENGTH + 4);
    }

    // Add CRC (little endian)
    const crc = crcgen.crc16(h.subarray(0, buf.byteLength - 2), 0);
    h.set([crc >>> 8, crc & 0xFF], buf.byteLength - 2);

    return h;
  }
  /**
   * Validate CDC2 message
   * @param msg the message data to validata
   * @returns true if the message is valid
   */
  cdc2ValidateMessage(msg: Uint8Array): boolean {
    if (this.validateHeaderAndLength(msg)) {
      // check for extended message
      if (msg[2] !== VexCDC.TYPES.USER_CDC.cmd) {
        return false;
      }

      // check packet crc
      const crc1 = crcgen.crc16(msg.subarray(0, msg.byteLength - 2), 0);
      const crc2 = (msg[msg.byteLength - 2] << 8) + msg[msg.byteLength - 1];

      return crc1 === crc2;
    }
    return false;
  }

  /**
   * Get CDC2 message length
   * @param msg the message to check
   * @returns length of the message data
   */
  cdc2MessageGetLength(msg: Uint8Array): number {
    // get message length
    let length_msb = 0;
    let length_lsb = msg[3];

    // see if first byte has msb set
    if (length_lsb & 0x80) {
      // 16 bit length
      length_msb = length_lsb & 0x7F;
      length_lsb = msg[4];
    }

    const length = (length_msb << 8) + length_lsb;

    return length;
  }

  /**
   * Get CDC2 reply total packet length
   */
  cdc2MessageGetReplyPacketLength(msg: Uint8Array): number {
    let length = this.cdc2MessageGetLength(msg) + 4;
    if (length > 131) {
      length += 1;
    }
    return length;
  }

  /**
   * Create a new query1 command
   * @return {Object} a message
   */
  query1(): VexCDCMessage {
    const msg = VexCDC.TYPES.QUERY1;
    return (new VexCDCMessage(this.cdcCommand(msg.cmd), msg.replyLength));
  }

  /**
   * Create a new system version command
   * @return {Object} a message
   */
  systemVersion(): VexCDCMessage {
    const msg = VexCDC.TYPES.SYSTEM_VERSION;
    return (new VexCDCMessage(this.cdcCommand(msg.cmd), msg.replyLength));
  }

  validateHeaderAndLength(msg: Uint8Array): boolean {
    if (msg === undefined) {
      return false;
    }

    // check header
    if (msg[0] !== VexCDC.HEADERR[0] || msg[1] !== VexCDC.HEADERR[1]) {
      return false;
    }

    return true;
  }

  getReplyLengthFromCommand(cmd: number): number {
    for (let prop in VexCDC.TYPES) {
      if (cmd === (VexCDC.TYPES as any)[prop].cmd) {
        return (VexCDC.TYPES as any)[prop].replyLength;
      }
    }
    return 0;
  }

  /*
   * ----------------------------------------------------------------------
   * IQ  commands
   */

  //#region IQ Commands
  /**
   * read the brain name
   * @return {Object} a message
   */
  brinName(): VexCDCMessage {
    var msg = VexCDC.TYPES.BRAIN_NAME_GET;
    var h = this.cdcCommand(msg.cmd);

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Create a new play slot command
   * @return {Object} a message
   */
  playSlot(slot: number): VexCDCMessage {
    var msg = VexCDC.TYPES.USER_PLAY;
    var buf = new Uint8Array([slot]);
    var h = this.cdcCommandWithData(msg.cmd, buf);

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Create a new styop program
   * @return {Object} a message
   */
  stopProgram(): VexCDCMessage {
    var msg = VexCDC.TYPES.USER_STOP;
    return (new VexCDCMessage(this.cdcCommand(msg.cmd), msg.replyLength));
  }

  /**
   * Create a flash erase command
   * @return {Object} a message
   */
  flashErase(address: number, blocks: number): VexCDCMessage {
    var msg = VexCDC.TYPES.FLASH_ERASE;

    // address is 32 bit start address
    // blocks is 16 bit number of 1k blocks
    var buf = new Uint8Array(6);
    var tmp = new DataView(buf.buffer);
    // set with littleEndian true
    tmp.setUint32(0, address, true);
    tmp.setUint16(4, blocks, true);

    var h = this.cdcCommandWithData(msg.cmd, buf);

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Create a flash write command
   * @return {Object} a message
   */
  flashWrite(address: number, data: Uint8Array): VexCDCMessage {
    var msg = VexCDC.TYPES.FLASH_WRITE

    var buf = new ArrayBuffer(VexCDC.HEADERS_LENGTH + 7 + data.length);
    var h = this.header(buf);
    var tmp = new DataView(buf);

    // add command byte
    tmp.setUint8(VexCDC.HEADERS_LENGTH, msg.cmd);

    // set length with little endian
    tmp.setUint16(VexCDC.HEADERS_LENGTH + 1, data.length + 4, true);

    // address is 32 bit start address
    // set address ith littleEndian true
    tmp.setUint32(VexCDC.HEADERS_LENGTH + 3, address, true);

    // add the message data
    h.set(data, VexCDC.HEADERS_LENGTH + 7);

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Create a new download exit command
   * @return {Object} a message
   */
  downloadExit(): VexCDCMessage {
    var msg = VexCDC.TYPES.USER_EXIT;
    return (new VexCDCMessage(this.cdcCommand(msg.cmd), msg.replyLength));
  }

  /**
   * Create a new styop program
   * @return {Object} a message
   */
  userProgramSlotsGet(): VexCDCMessage {
    var msg = VexCDC.TYPES.USER_SLOT_GET;
    return (new VexCDCMessage(this.cdcCommand(msg.cmd), msg.replyLength));
  }

  /**
   * Create a program slot set command
   * @return {Object} a message
   */
  userProgramSlotsSet(slot: number, address: number): VexCDCMessage {
    var msg = VexCDC.TYPES.USER_SLOT_SET;

    // address is 32 bit start address
    var buf = new Uint8Array(5);
    var tmp = new DataView(buf.buffer);

    // set slot
    buf.set([slot], 0);
    // set address with littleEndian true
    tmp.setUint32(1, address, true);

    var h = this.cdcCommandWithData(msg.cmd, buf);

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Create a new dfu enable command
   * @return {Object} a message
   */
  eraseCatalog(): VexCDCMessage {
    var msg = VexCDC.TYPES.EEPROM_ERASE;
    var buf = new Uint8Array([1]);
    var h = this.cdcCommandWithData(msg.cmd, buf);

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Validate IQ CDC message
   * @param msg the message data to validata
   * @returns true if the message is valid
   */
  cdcValidateIQMessage(msg: Uint8Array): boolean {
    const dvb = new DataView(msg.buffer, msg.byteOffset);
    return dvb.getUint16(0) === 0xAA55;
  }
  
  //#endregion IQ Commands

  /*
   * ----------------------------------------------------------------------
   * V5 new CDC2 user program commands
   */

  //#region V5 Commands
  /**
   * Create a new controller version request command
   * Only send to controller, not V5 brain
   * for debug and test purposes
   * @return {Object} a message
   */
  V5_Cdc2ControllerCompControl(ctrl: number, matchtime: number): VexCDCMessage {
    const msg = VexCDC.TYPES.CTRL_CDC;
    const cmd = VexCDC.ECMDS_CTRL.CON_COMP_CTRL;
    const buf = new Uint8Array(5);
    const dvb = new DataView(buf.buffer);

    buf[0] = (ctrl & 0x0F) >>> 0;
    dvb.setUint32(1, matchtime, true);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new download exit command
   * @return {Object} a message
   */
  V5_Cdc2FileControl(action: number, data: number): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_CTRL;
    const buf = new Uint8Array(2);
    action = (action === undefined) ? 0 : action;
    data = (data === undefined) ? 0 : data;

    buf[0] = action;
    buf[1] = data;

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new file initizlize command
   * @return {Object} a message
   */
  V5_Cdc2FileInitialize(operation: number, target: number, vid: number, options: number, src: Uint8Array, addr: number, name: string, exttype: number = 0): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_INIT;
    const str = new TextEncoder().encode(name);

    const buf = new Uint8Array(52);
    const dvb = new DataView(buf.buffer);

    // Updated for proposed new V5 protocol
    dvb.setUint8(0, operation);
    dvb.setUint8(1, target);
    dvb.setUint8(2, vid);
    dvb.setUint8(3, options);

    // set length, 0 if we have no buffer
    if (src !== undefined) {
      dvb.setUint32(4, src.length, true);
    } else {
      dvb.setUint32(4, 0, true);
    }

    // address
    dvb.setUint32(8, addr, true);

    // CRC
    let crc: number;
    if (src !== undefined) {
      crc = crcgen.crc32(src, 0);
    } else {
      crc = 0;
    }
    dvb.setUint32(12, crc, true);

    // type
    // use file extension
    const re = /(?:\.([^.]+))?$/;
    let ext = re.exec(name)[1];
    ext = (ext === undefined ? '' : ext);
    // files with gz extension are also type bin
    ext = (ext === 'gz' ? 'bin' : ext);

    dvb.setUint8(16, ext.charCodeAt(0));
    dvb.setUint8(17, ext.charCodeAt(1));
    dvb.setUint8(18, ext.charCodeAt(2));
    dvb.setUint8(19, exttype);

    // timestamp, updated for J2000_EPOCH, 9/17/2018
    const timestamp = ((Date.now() / 1000) >>> 0) - VexCDC.J2000_EPOCH;
    dvb.setUint32(20, timestamp, true);

    // version (endianess fixed, Jan 12 2019)
    dvb.setUint32(24, this.file_version, true);
    // reset version
    this.file_version = 1;

    // filename
    // now use final 23 rather than first 23
    let offset = str.length - 23;
    if (offset < 0) {
      offset = 0;
    }
    buf.set(str.subarray(offset, offset + 23), 28);
    dvb.setUint8(51, 0);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * one time setting of file version
   * @param v 
   */
  V5_Cdc2SetFileVersion(v: number) {
    this.file_version = v;
  }

  /**
   * Create a new download exit command
   * @return {Object} a message
   */
  V5_Cdc2FileExit(action: number): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_EXIT;
    const buf = new Uint8Array(1);
    if (action !== undefined)
      buf[0] = action;

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a program write command
   * @return {Object} a message
   */
  V5_Cdc2FileDataWrite(address: number, data: Uint8Array): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC
    const cmd = VexCDC.ECMDS.FILE_WRITE;
    const buf = new Uint8Array(4 + data.length);
    const dvb = new DataView(buf.buffer);

    // address is 32 bit start address
    // set address ith littleEndian true
    dvb.setUint32(0, address, true);

    // add the message data
    buf.set(data, 4);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a flash read command
   * @return {Object} a message
   */
  V5_Cdc2FileDataRead(address: number, bytes: number): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_READ;

    // address is 32 bit start address
    // bytes is number of bytes to read
    const buf = new Uint8Array(6);
    const dvb = new DataView(buf.buffer);
    // set with littleEndian true
    dvb.setUint32(0, address, true);
    dvb.setUint16(4, bytes, true);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    // what do we do with error.
    msg.replyLength = 11 + bytes;
    if (bytes > 123) {
      msg.replyLength += 1;
    }

    return (new VexCDCMessage(h, msg.replyLength));
  }

  /**
   * Create a new user program link command
   * @return {Object} a message
   */
  V5_Cdc2FileLinkFile(vid: number, options: number, name: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_LINK;
    const str = new TextEncoder().encode(name);
    const buf = new Uint8Array(26);

    buf.set([vid, options], 0);
    buf.set(str.subarray(0, 23), 2);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Directory count
   * @return {Object} a message
   */
  V5_Cdc2FileDir(vid: number, options: number): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_DIR;

    const buf = new Uint8Array(2);

    buf.set([vid, options], 0);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Directory entry
   * @return {Object} a message
   */
  V5_Cdc2FileDirEntry(index: any): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_DIR_ENTRY;

    const buf = new Uint8Array(2);

    buf.set([index, 0], 0);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new user program load command
   * @return {Object} a message
   */
  V5_Cdc2FileLoadAndRun(vid: number, options: number, name: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_LOAD;
    const str = new TextEncoder().encode(name);
    const buf = new Uint8Array(26);

    buf.set([vid, options], 0);
    buf.set(str.subarray(0, 23), 2);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new metadata get command
   * @return {Object} a message
   */
  V5_Cdc2FileMetadataGet(vid: number, options: number, name: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_GET_INFO;
    const str = new TextEncoder().encode(name);
    const buf = new Uint8Array(26);

    buf.set([vid, options], 0);
    buf.set(str.subarray(0, 23), 2);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new metadata set command
   * @return {Object} a message
   */
  V5_Cdc2FileMetadataSet(vid: number, options: number, addr: number, type: number, version: number, name: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_SET_INFO;
    const str = new TextEncoder().encode(name);
    const buf = new Uint8Array(42);
    const dvb = new DataView(buf.buffer);

    // Updated for proposed new V5 protocol
    dvb.setUint8(0, vid);
    dvb.setUint8(1, options);

    // address
    dvb.setUint32(2, addr, true);
    // type
    dvb.setUint32(6, addr, true);

    // timestamp
    let timestamp = ((Date.now() / 1000) >>> 0) - VexCDC.J2000_EPOCH;
    dvb.setUint32(10, timestamp, true);

    // version
    dvb.setUint32(14, version);

    // filename
    buf.set(str.subarray(0, 23), 18);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new file erase command
   * @return {Object} a message
   */
  V5_Cdc2FileErase(vid: number, options: number, name: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_ERASE;
    const str = new TextEncoder().encode(name);
    const buf = new Uint8Array(26);

    buf.set([vid, options], 0);
    buf.set(str.subarray(0, 23), 2);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new file user status (slot) command
   * @return {Object} a message
   */
  V5_Cdc2FileUserStatus(vid: number, options: number, name: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FILE_USER_STAT;
    const str = new TextEncoder().encode(name);
    const buf = new Uint8Array(26);

    buf.set([vid, options], 0);
    buf.set(str.subarray(0, 23), 2);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new v5 system flags status command
   * @return {Object} a message
   */
  V5_Cdc2FlagsStatus(): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.SYS_FLAGS;

    const h = this.cdc2Command(msg.cmd, cmd.cmd);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new v5 device status command
   * @return {Object} a message
   */
  V5_Cdc2DeviceStatus(): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.DEV_STATUS;

    const h = this.cdc2Command(msg.cmd, cmd.cmd);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new v5 system status command
   * @return {Object} a message
   */
  V5_Cdc2SystemStatus(): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.SYS_STATUS;

    const h = this.cdc2Command(msg.cmd, cmd.cmd);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new v5 key-value load command
   * @return {Object} a message
   */
  V5_Cdc2SysKVRead(key: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.SYS_KV_LOAD;
    let str = new TextEncoder().encode(key);
    // limit key length
    if (str.byteLength > 31) {
      str = str.subarray(0, 30);
    }
    const buf = new Uint8Array(str.byteLength + 1);

    buf.set(str, 0);
    buf.set([0], str.byteLength);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new v5 key-value save command
   * @return {Object} a message
   */
  V5_Cdc2SysKVSave(key: string, value: string): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.SYS_KV_SAVE;
    let strk = new TextEncoder().encode(key);
    const strv = new TextEncoder().encode(value);
    // limit key length
    if (strk.byteLength > 31) {
      strk = strk.subarray(0, 30);
    }

    const buf = new Uint8Array(strk.byteLength + strv.byteLength + 2);

    buf.set(strk, 0);
    buf.set([0], strk.byteLength);
    buf.set(strv, strk.byteLength + 1);
    buf.set([0], buf.byteLength - 1);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new v5 dashboard select command
   * @return {Object} a message
   */
  V5_Cdc2DashSelect(screen: number, port?: number): VexCDCMessage  {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.SYS_DASH_SEL;

    const buf = new Uint8Array(2);
    buf[0] = screen;
    buf[1] = port !== undefined ? port : 0;
    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
  
    return new VexCDCMessage(h, cmd.replyLength);
  }

  /**
   * Create a new factory ping command
   * @return {Object} a message
   */
  V5_Cdc2FactoryPing(data?: Uint8Array): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FACTORY_PING;

    let h;
    if (data !== undefined && data.byteLength > 0) {
      h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, data);
    } else {
      h = this.cdc2Command(msg.cmd, cmd.cmd);
    }

    return (new VexCDCMessage(h, cmd.replyLength));
  }
  /**
   * Create a new factory pong command
   * @return {Object} a message
   */
  V5_Cdc2FactoryPong(len: number): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FACTORY_PONG;

    const buf = new Uint8Array(2);
    const dvb = new DataView(buf.buffer);

    dvb.setUint16(0, len, true);

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);

    return (new VexCDCMessage(h, cmd.replyLength));
  }

  /**
   * Create a new factory enable command
   * @return {Object} a message
   */
   V5_Cdc2FactoryEnable(): VexCDCMessage  {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FACTORY_EBL;
    const buf = new Uint8Array( 4 );
    buf[0] = 0x4D;
    buf[1] = 0x4C;
    buf[2] = 0x4B;
    buf[3] = 0x4A;

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
  
    return new VexCDCMessage(h, cmd.replyLength);
  }

  /**
   * Create a new factory firmware update status command
   * @return {Object} a message
   */
  V5_Cdc2FactoryStatus(): VexCDCMessage  {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FACTORY_STATUS;

    const h = this.cdc2Command(msg.cmd, cmd.cmd);
  
    return new VexCDCMessage(h, cmd.replyLength);
  }

  /**
   * Create a new factory system reset command
   * @return {Object} a message
   */
  V5_Cdc2FactoryReset( powerOff?: boolean ): VexCDCMessage  {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FACTORY_RESET;

    powerOff = (powerOff === undefined) ? false : powerOff;
    
    const len = (powerOff === true) ? 6 : 4;

    const buf = new Uint8Array(len);
    // 'V5V5'
    buf[0] = 0x56;
    buf[1] = 0x35;
    buf[2] = 0x56;
    buf[3] = 0x35;

    if (powerOff) {
      buf[4] = 0x4B;
      buf[5] = 0x39;        
    }

    const h = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
  
    return new VexCDCMessage(h, cmd.replyLength);
  }
  
  /**
   * Create factory "special message".  This is a wrapper for Mark's special message
   * that returns an ACK
   * Development only, seriously, don;t use this it can brick the V5 !
   * @param cmd 
   * @param data 
   */
  V5_Cdc2FactorySpecial( scmd: number, data: Uint8Array ): VexCDCMessage {
    const msg = VexCDC.TYPES.USER_CDC;
    const cmd = VexCDC.ECMDS.FACTORY_SPECIAL;

    const buf = new Uint8Array(1 + data.length);
    // create special command
    buf[0] = scmd;
    for (let i=0; i<data.length; i++) {
      buf[i+1] = data[i];
    }

    const h  = this.cdc2CommandWithData(msg.cmd, cmd.cmd, buf);
  
    return new VexCDCMessage(h, cmd.replyLength);
  }
  //#endregion V5 Commands

  //#region IQ2/EXP controller commands
  IQ2_Cdc2ControllerVersions(): VexCDCMessage  {
    const msg = VexCDC.TYPES.CTRL_CDC;
    const cmd = VexCDC.ECMDS_CTRL.CNTR_GET_VERSIONS;

    const h  = this.cdc2Command( msg.cmd, cmd.cmd );
  
    return( new VexCDCMessage( h, cmd.replyLength ) );
  }
  EXP_Cdc2ControllerVersions = this.IQ2_Cdc2ControllerVersions;

  cdc2ValidateMessageCtrl(msg: Uint8Array): boolean {
    if (this.validateHeaderAndLength(msg)) {
      // check for extended message
      if (msg[2] !== VexCDC.TYPES.CTRL_CDC.cmd) {
        return false;
      }

      // check packet crc
      const crc1 = crcgen.crc16(msg.subarray(0, msg.byteLength-2), 0);
      const crc2 = (msg[msg.byteLength-2] << 8) + msg[msg.byteLength-1];   

      return crc1 === crc2;
    }
    return false;
  }
  //#region IQ2/EXP controller commands

  /*
  * ----------------------------------------------------------------------
  * Most of the below is debug related for decoding replies into human readable
  * information.
  */

  /**
   * Utility function to create a hex string from the given number
   * @param  (number} value the number to be formatted into a string with %02X format
   * @return {string}
   */
  hex2(value: number): string {
    const str = ('00' + value.toString(16)).substr(-2, 2);
    return (str.toUpperCase());
  }

  /**
   * Utility function to create a hex string from the given number
   * @param  (number} value the number to be formatted into a string with %08X format
   * @return {string}
   */
  hex8(value: number): string {
    const str = ('00000000' + value.toString(16)).substr(-8, 8);
    return (str.toUpperCase());
  }

  /**
   * Utility function to create a decimal string from the given number
   * @param  (number} value the number to be formatted into a string with %02d format
   * @return {string}
   */
  dec2(value: number): string {
    const str = ('00' + value.toString(10)).substr(-2, 2);
    return (str.toUpperCase());
  }

  /**
   * Decode a received CDC reply
   * @param  (ArrayBuffer} buf the CDC reply to decode
   * @return {string}
   */
  decode(buf: any): string {
    if (!buf) {
      return 'vexcdc: decode error';
    }

    const msg = new Uint8Array(buf);
    let str = '';

    // check header
    if (this.validateHeaderAndLength(msg)) {
      // this is inefficient and used for debug
      // ignore if len is 0
      const replyLen = this.getReplyLengthFromCommand(msg[2]);
      if ((replyLen > 0) && (replyLen - VexCDC.HEADERS_LENGTH) !== msg[3])
        console.log("vexcdc: bad reply length");

      switch (msg[2]) {
        case VexCDC.TYPES.ACK.cmd:
          str = 'vexcdc: General ACK with status ' + this.hex2(msg[4]);
          break;
        case VexCDC.TYPES.QUERY1.cmd:
          str = this.decodeQuery1String(msg);
          break;
        case VexCDC.TYPES.SYSTEM_VERSION.cmd:
          str = this.decodeSystemVersionString(msg);
          break;
        case VexCDC.TYPES.USER_CDC.cmd:
          str = 'vexcdc: cdc2 reply';
          break;

        default:
          str = 'vexcdc: unknown reply';
          break;
      }
    }
    else
      str = "bad reply";

    return (str);
  }

  /**
   * Decode a received Query 1 reply
   * @param  (Uint8Array} meg the CDC reply to decode
   * @return {string}
   */
  decodeQuery1String(msg: any): string {
    let str = 'query1:\n';
    let tmp;

    tmp = '  Joystick ' + this.dec2(msg[4]) + '.' + this.dec2(msg[5]);
    str = str + tmp + '\n';
    tmp = '  Brain    ' + this.dec2(msg[6]) + '.' + this.dec2(msg[7]);
    str = str + tmp + '\n';
    tmp = '  Bootload ' + this.dec2(msg[10]) + '.' + this.dec2(msg[11]);
    str = str + tmp + '\n';

    this.vex_version = msg[6];

    return (str);
  }

  /**
   * Decode a received System verison reply
   * @param  (Uint8Array} meg the CDC reply to decode
   * @return {string}
   */
  decodeSystemVersionString(msg: any): string {
    let str = 'system version:\n';
    let tmp;

    tmp = '  Version  ' + this.dec2(msg[4]) + '.' +
      this.dec2(msg[5]) + '.' +
      this.dec2(msg[6]) + 'b' +
      this.dec2(msg[8]);
    str = str + tmp + '\n';
    tmp = '  Hardware ' + (msg[7] == 1 ? 'V1' : 'V2');
    str = str + tmp + '\n';

    return (str);
  }
}
